import axios from "axios";

const API_URL =  "api/auth/";

const login = async (username, password) => {
  const url = API_URL + "signin";
  console.log(url)
  const response = await axios
    .post(url, {
      username,
      password,
    });
  if (response.data.username) {
    localStorage.setItem("user", (response.data.username));
  }
  return response.data;
};

const logout = async () => {
  console.log("TEST")
  //localStorage.removeItem("user");
  localStorage.clear();
  const response = await axios.post(API_URL + "signout");
  return response.data;
};

const getCurrentUser = () => {
  return localStorage.getItem("user");
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
}

export default AuthService;