import {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import IntroStep from "../components/steps/introStep";
import LocationStep from "../components/steps/locationStep";
import ProjectStep from "../components/steps/projectStep"
import ResultsStep from "../components/steps/resultsStep"
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//import "../css/project.css";
import InformationModal from "../components/basicModal/informationModal";
import AuthService from "../services/auth.service";

function scrollToStepper() {
  var element = document.getElementById("stepper");
  element.scrollIntoView({ behavior: 'smooth' });
}

export default function HorizontalNonLinearStepper() {
  const [valueSecondTab, setValueSecondTab] = useState("1");
  const [activeButtonResults, setActiveButtonResults]=useState("Live Project");
  const [activeStep, setActiveStep] = useState(0);  
  const [isBackDisabled, setIsBackDisabled] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [openDialog, setOpenDialog] = useState({state: false, message: ""});
  const [noActionOpenModal, setNoActionOpenModal] = useState(false)
  const [listOfActions, setListOfActions] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency]=useState({});
  const [newAdditionalInputs, setNewAdditionalInputs] = useState({});
  const [yldTable, setYLDTable] = useState([{}]);
  const [includePreProject, setIncludePreProject] = useState(false);
  const [mortalityTable, setMortalityTable] = useState([{}]);
  const [populationTable, setPopulationTable] = useState([{}]);
  const [singleNationalCompositionTable, setSingleNationalCompositionTable] = useState([{}]);
  const [citySpecificCompositionTable, setCitySpecificCompositionTable] = useState([{}]);
  const [actionList, setActionList] = useState([]);
  const [hasResult, setHasResult] = useState(false)
  const [simulationResults, setSimulationResults] = useState({});
  const [cityDetails, setCityDetails] = useState({})
  const [projects, setListOfProjects] = useState([]);
  const [savedProject, setSavedProject] = useState({})
  const [isSimulationOption, setIsSumulationOption] = useState("");
  const [userInputs, setUserInputs] = useState({
    userCenterPointGeom:[],
    projectName:"",
    projectYear: 2023,
    assessmentTime: 0,
    typeOfSimulation: "",
    action1Id: "",
    action1ImpactValue: "",
    action2Id: "",
    action2ImpactValue: "",
    action3Id: "",
    action3ImpactValue: "",
    action4Id: "",
    action4ImpactValue: "",
    action5Id: "",
    action5ImpactValue: "",
    coronaryHeartDiseaseMinutesPWeek: "",
    coronaryHeartDisease: "",
    strokeMinutesPWeek: "",
    strokeRiskReduction: "",
    dementiaMinutesPWeek: "",
    dementia: "",
    typeIiDiabetesMinutesPWeek: "",
    typeIiDiabetes: "",
    depressionMinutesPWeek: "",
    depression: "",
    breastCancerMinutesPWeek: "",
    breastCancer: "",
    colonCancerMinutesPWeek: "",
    colonCancer: "",
    cyclingMinApplicableAge: "",
    cyclingMaxApplicableAge: "",
    cyclingRelativeRisk: "",
    cyclingReferenceVolume: "",
    cyclingCapsPercentImprove: "",
    cyclingCapsMinPWeek: "",
    walkingMinApplicableAge: "",
    walkingMaxApplicableAge: "",
    walkingRelativeRisk: "",
    walkingReferenceVolume: "",
    walkingCapsPercentImprove: "",
    walkingCapsMinPWeek: "",
    currency: "",
    valueStatisticalLife: "",
    valueLifeYear: "",
    discountRate: "",
    cityModeShareWalkPercentage: "",
    cityModeShareBicyclePercentage: "",
    cityModeShareCarMotorcycleSharePercentage: "",
    cityModeShareCarMotorcycleSoloPercentage: "",
    cityModeShareTaxiMinibuPercentage: "",
    cityModeSharePublicTransportPercentage: "",
    populationCompositionMethod: "National",
    malePercentageCyclistsWalkers: "",
    femalePercentageCyclistsWalkers: "",
    minimumAge: "",
    maximumAge: "",
    cyclistPopulationCompositionBAgeCohort: "",
    carMotorcycleRideSharePersonsPVehicle: "",
    carMotorcycleSoloDriverPersonsPVehicle: "",
    taxiOrMinibusPersonsPerVehicle: "",
    publicTransportPersonsPerVehicle: "",
    tripsPerDayPreproject : "",
    tripsPerDayPostproject: "",
    totalTripsPerYearPreproject: "",
    totalTripsPerYearPostproject: "",
    totalPeoplePerYearPreproject: "",
    totalPeoplePerYearPostproject: "",
    averageTripDurationPreproject: "",
    averageTripDurationPostproject: "",
    averageTripSpeedPreproject: "",
    averageTripSpeedPostproject: "",
    averageTripDistancePreproject: "",
    averageTripDistancePostproject: "",
    totalMinutesOfActivityPerYearPreproject: "",
    totalMinutesOfActivityPerYearPostproject: "",
    totalMinutesOfActivityPerPersonPreproject: "",
    totalMinutesOfActivityPerPersonPostproject: "",
    minimumTripDistance: "",
    tripsPerYearPerUser: "",
    newTrips: "",
    totalModeShift: "",
    projectBicyclePercentage: "",
    projectWalkPercentage: "",
    projectCarMotorcycleRideSharePercentage: "",
    projectCarMotorcycleSoloDriverPercentage: "",
    projectTaxiMinibusPercentage: "",
    projectPublicTransportPercentage: "",
    projectTotalPercentage: "",
    bicycleJourneyLength: "",
    walkJourneyLength: "",
    carMotorcycleRideShareJourneyLength: "",
    carMotorcycleSoloDriverJourneyLength: "",
    taxiOrMinibusJourneyLength: "",
    publicTransportJourneyLength: "",
    capex: "",
    opex: "",
    numberOfCrimes: 0,
  })

  // Close MuiAlert
  const handleClose = (event, reason) => {    
    setOpenDialog({state: false, message: ""});
  };

  const steps = [{Introduction: ["About" , "HowToUSe"] },
                {Location: []},
                {Project: ["Analysis Selection", 
                          {"General Parameters": ["Health", "Economic", "Urban Mobility", "Safety"]},
                          "Project Parameters",  /*"Aditional Inputs":*/
                          "Save", "Results"]},
                {Results: ["current",{"compare":["Projects To Compare","Results"]}]}]

  const CurrentStep = (step) => {
    const user = AuthService.getCurrentUser();
    if (step === undefined)
      step = 0;
      if (user) {

        switch(step) {
          case 0:
            return <IntroStep step={activeStep} setIsBackDisabled={setIsBackDisabled}/>;
          case 1: 
            return <LocationStep setCurrencyList={setCurrencyList} setSelectedCurrency={setSelectedCurrency} 
            setNewAdditionalInputs={setNewAdditionalInputs} setYLDTable={setYLDTable} setMortalityTable={setMortalityTable} setPopulationTable={setPopulationTable}
            setSingleNationalCompositionTable={setSingleNationalCompositionTable} setCitySpecificCompositionTable={setCitySpecificCompositionTable} 
            setActionList={setActionList} setListOfActions={setListOfActions} setHasResult={setHasResult} setSimulationResults={setSimulationResults}
            cityDetails={cityDetails} setCityDetails={setCityDetails} setListOfProjects={setListOfProjects} setIncludePreProject={setIncludePreProject}
            setIsSumulationOption={setIsSumulationOption}/>;
          case 2:
            return <ProjectStep setNewAdditionalInputs={setNewAdditionalInputs} newAdditionalInputs = {newAdditionalInputs} 
            listOfActions={listOfActions} setListOfActions={setListOfActions} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency}
            currencyList={currencyList} yldTable={yldTable} mortalityTable={mortalityTable} populationTable={populationTable} 
            singleNationalCompositionTable={singleNationalCompositionTable} 
            setSingleNationalCompositionTable={setSingleNationalCompositionTable} citySpecificCompositionTable={citySpecificCompositionTable}
            setCitySpecificCompositionTable={setCitySpecificCompositionTable} actionList={actionList} setHasResult={setHasResult} simulationResults={simulationResults} 
            setSimulationResults={setSimulationResults} includePreProject={includePreProject} setIncludePreProject={setIncludePreProject}
            hasResult={hasResult} userInputs={userInputs} setUserInputs={setUserInputs} setListOfProjects={setListOfProjects} 
            projects={projects} setSavedProject={setSavedProject} isSimulationOption={isSimulationOption} setIsSumulationOption={setIsSumulationOption}/>;
          case 3:
            return <ResultsStep simulationResults={simulationResults} listOfActions={listOfActions} userInputs={userInputs} 
            selectedCurrency={selectedCurrency} projects={projects} savedProject={savedProject} setActiveButtonResults={setActiveButtonResults} 
            activeButtonResults={activeButtonResults} valueSecondTab={valueSecondTab} setValueSecondTab={setValueSecondTab}/>;
          default: return <h1>No project match</h1>
        }
    } else {
      window.location.reload(true);
    }
  }

  const checkCurrentTabLevel = (tabLevel) => {
    let selectedIndex = null;
    const tabLevel1 = document.getElementById(tabLevel);
    if (tabLevel1 != null) {      
      const tabLevel1Btns = tabLevel1.getElementsByTagName("button");
      selectedIndex = 0;
      for (var x=0; x < tabLevel1Btns.length; x++) {
        if (tabLevel1Btns[x].classList.contains("Mui-selected")) {
          selectedIndex = x;
          break;
        }        
      }
    }
    return selectedIndex;
  }

  const handleStepClick = (step) => {   
    if (!passedValidations(step, "Please pick an area in the Map at the Location Step")) return;
    setActiveStep(step);
    CurrentStep(step);
  };

  useEffect(() => {
    //console.log("Active Step=", activeStep, " tabLevel1=", checkCurrentTabLevel("tabsLevel1"));
    if (checkCurrentTabLevel("tabsLevel1") === 0 && activeStep === 0)
      setIsBackDisabled(true);

    if (activeStep === 1 || activeStep === 2) {
      setIsBackDisabled(false);
      setIsNextDisabled(false);
    }
    // // console.log("STEPS: ", steps)
    // // console.log("ACTIVE STEP: ", activeStep)
    // console.log("ACTIVE BUTTON:", activeButtonResults)
    if (activeStep === steps.length-1 )
    setIsNextDisabled(true);
    }, [activeStep]);

  const passedValidations = (chosenStep, msg = "Please chose an area inside a City") => {
    //console.log("Location=", localStorage.getItem("locationIsValid"))
    if (localStorage.getItem("locationIsValid") !== "true" && chosenStep > 1) {
      setOpenDialog({state: true, message: msg});
      return false;
    }
    if(hasResult === false && chosenStep === 3){
      setOpenDialog({state: true, message: "Please complete the form and simulate a project to see the results"});
      return false;
    }
    return true;
  }

  const handleClickBtn = (direction) => {    
    const tabIndex = checkCurrentTabLevel("tabsLevel1");
    const tabIndex2 = checkCurrentTabLevel("tabsLevel2");
    const tabIndex3 = checkCurrentTabLevel("tabsLevel3");
    // console.log("tabIndex=", tabIndex + " tabIndex2=", tabIndex2 + " tabIndex3=", tabIndex3 + " direction=", direction);
    // console.log("VALUE OF SECOUND TAB:",valueSecondTab)
    // console.log("ACTIVE STEP ", activeStep)
    if (direction === "next") { 
      if (tabIndex3 !== null) {
        if (document.getElementById("tabsLevel3").getElementsByTagName("button")[tabIndex3+1]) {
            document.getElementById("tabsLevel3").getElementsByTagName("button")[tabIndex3+1].click();
            scrollToStepper();
            return;
          }
        }
        if (tabIndex2 !== null) {
          if (document.getElementById("tabsLevel2").getElementsByTagName("button")[tabIndex2+1]) {
              document.getElementById("tabsLevel2").getElementsByTagName("button")[tabIndex2+1].click();
              scrollToStepper();
              return;
            }
          }
          if (tabIndex !== null) {   
            if (document.getElementById("tabsLevel1").getElementsByTagName("button")[tabIndex+1]) {
              document.getElementById("tabsLevel1").getElementsByTagName("button")[tabIndex+1].click();
              scrollToStepper();
              setIsBackDisabled(false);
            } else if (steps[activeStep + 1]) {
              setActiveStep(activeStep+1);
              scrollToStepper();
            }
          } else if (tabIndex === null || steps[activeStep + 1]) {
            if (!passedValidations(2)) return;
            setActiveStep(activeStep+1);
            scrollToStepper();
          }}  

    if (direction === "back") { {

      if (tabIndex3 !== null) {
        if (document.getElementById("tabsLevel3").getElementsByTagName("button")[tabIndex3 - 1]) {
          document.getElementById("tabsLevel3").getElementsByTagName("button")[tabIndex3 - 1].click();
          scrollToStepper();
          return;
        }
      }

      if (tabIndex2 !== null) {
        if (document.getElementById("tabsLevel2").getElementsByTagName("button")[tabIndex2 - 1]) {
          document.getElementById("tabsLevel2").getElementsByTagName("button")[tabIndex2 - 1].click();
          scrollToStepper();
          return;
        }
      }

      if (tabIndex !== null && tabIndex === 0) {
        setActiveStep(activeStep-1);
        scrollToStepper();
        return;
      }
      if (tabIndex !== null && document.getElementById("tabsLevel1").getElementsByTagName("button")[tabIndex-1]) {
        document.getElementById("tabsLevel1").getElementsByTagName("button")[tabIndex-1].click();
        scrollToStepper();
        if (tabIndex === 1 && activeStep === 0){
          setIsBackDisabled(true);        
      } else if (tabIndex === null) {
        if (steps[activeStep - 1]) {
          setActiveStep(activeStep-1);        
          scrollToStepper();
        }
      }
      } 
    
      // } 
    }
  }
}

  return (
    <div className="container col-8 mt-4" id="project-page">
      <Box sx={{backgroundColor: "#F5F5F5", height: "90px", alignItems: "center", display: "flex", justifyContent: "center", 
        marginTop: "32px", }}>
        
        <Stepper id="stepper" activeStep={activeStep} sx={{width: "100%"}}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel onClick={() => handleStepClick(index)}>
                {Object.keys(label)[0]}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {CurrentStep(activeStep)}

      {document.getElementById("results") === null && <Box sx={{"& button": { m: 1 }, display: "flex", alignItems: "centery", justifyContent: "center", }} className="navigation-btns my-4">
        <Button id="btn-back" variant="contained" className={isBackDisabled? "btn-disabled": "btn-enabled"} disabled={isBackDisabled} 
          size="small" onClick={() => handleClickBtn("back")}>
          Back
        </Button>

        <Button id="btn-next" variant="contained" className={isNextDisabled? "btn-disabled": "btn-enabled"} disabled={isNextDisabled} 
          size="small" onClick={() => handleClickBtn("next")}>
          Next
        </Button>
      </Box>}

      <Snackbar open={openDialog.state} autoHideDuration={4000} onClose={handleClose} className="snackbar">
        <MuiAlert severity="warning">{openDialog.message}</MuiAlert>
      </Snackbar>
      
    <InformationModal modalOpen={noActionOpenModal} setModalOpen={setNoActionOpenModal} body = "Please complete your selection before proceeding to the next tab"
    positiveButtonModalLabel='OK' />
    </div>

  );
}
