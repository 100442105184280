import { FormControl, TextField, Grid, Select, InputLabel, MenuItem, Typography } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";

import { TabPanel } from "@mui/lab";

import { useEffect, useState } from "react";

import "../../css/databasePage.css";
import StyledTooltip from "../../utils/styledToolTip";
import { TRIPS_PER_DAY, TOTAL_TRIPS_PER_YEAR, AVERAGE_TRIP_DURATION, AVERAGE_TRIP_SPEED, MINIMUM_TRIP_DISTANCE, TRIPS_PER_YEAR_PER_USER, NEW_PEDESTRIAN_TRIPS, TOTAL_MODE_SHIFT,
  PROPORTION_NEW_PEDESTRIAN_TRIPS, JOURNEY_LENGHT, CAPEX, OPEX, AVERAGE_TRIP_DISTANCE, PROPORTION_PROJECT_INDUCED, TIME_FRAME, SOURCE_TITLE} from "../../utils/tooltipConstants";
import Utils from "../../utils/miscUtils";


export default function Cycling({newAdditionalInputs, setNewAdditionalInputs, includePreProject, setIncludePreProject, userInputs, advanceErrors, setAdvanceErrors, setUserInputs,listOfActions}) {

  const[minTripDistance, setMinTripDistance] = useState(newAdditionalInputs.pedestrian_travel_characteristics.minimum_trip_distance)
  const[tripsPerYearPerDistance, setTripsPerYearPerDistance] = useState(newAdditionalInputs.pedestrian_travel_characteristics.trips_per_year_per_user)
  const[newPedestrianTrips, setNewPedestrianTrips] = useState(newAdditionalInputs.proportion_new_pedestrian_trips.new_walking_trips)
  const [open, setOpen] = useState(false);

  const [trips,setTrips] = useState([
    { id: "1", label: "Total trips per Year", helperText: "Number", value:newAdditionalInputs.pre_project_conditions_walking.total_trips_per_day},
    { id: "2", label: "Total trips per Year", helperText: "Number", value:newAdditionalInputs.post_project_conditions_walking.total_trips_per_day},
    { id: "3", label: "Total cyclists per year", helperText: "Number",  value:(newAdditionalInputs.pre_project_conditions_walking.total_pedestrians_per_year).toFixed(0) },
    { id: "4", label: "Total cyclists per year", helperText: "Number", value:(newAdditionalInputs.post_project_conditions_walking.total_pedestrians_per_year).toFixed(0) },
  ]);
  const [averageTripDuration, setAverageTripDuration] = useState([
    { id: "1", label: "Average trip duration", helperText: "Minutes", value:(newAdditionalInputs.pre_project_conditions_walking.average_trip_duration).toFixed(1) },
    { id: "2", label: "Average trip duration", helperText: "Minutes", value:(newAdditionalInputs.post_project_conditions_walking.average_trip_duration).toFixed(1) },
  ]);

const [averageTripSpeed,setAverageTripSpeed] = useState([
  { id: "1", label: "Average trip speed", helperText: "Km/h", value:newAdditionalInputs.pre_project_conditions_walking.average_trip_speed },
  { id: "2", label: "Average trip speed", helperText: "Km/h", value:newAdditionalInputs.post_project_conditions_walking.average_trip_speed },
])
  const [averageTripTotals, setAverageTripTotals] = useState([
    { id: "1", label: "Average trip distance", helperText: "Km", value:(newAdditionalInputs.pre_project_conditions_walking.average_trip_distance).toFixed(1) },
    { id: "2", label: "Average trip distance", helperText: "Km", value:(newAdditionalInputs.post_project_conditions_walking.average_trip_distance).toFixed(1) },
    {
      id: "3",
      label: "Total minutes of activity per year",
      helperText: "Min/year",
      value:(newAdditionalInputs.pre_project_conditions_walking.total_minutes_of_activity_per_year).toFixed(1)
    },
    {
      id: "4",
      label: "Total minutes of activity per year",
      helperText: "Min/year",
      value:(newAdditionalInputs.post_project_conditions_walking.total_minutes_of_activity_per_year).toFixed(1)
    },
    {
      id: "5",
      label: "Total minutes of activity per cyclist",
      helperText: "Min/user/year",
      value:(newAdditionalInputs.pre_project_conditions_walking.total_minutes_of_activity_per_pedestrian).toFixed(1)
    },
    {
      id: "6",
      label: "Total minutes of activity per cyclist",
      helperText: "Min/user/year",
      value:(newAdditionalInputs.post_project_conditions_walking.total_minutes_of_activity_per_pedestrian).toFixed(1)
    },
  ]);

  const [inducedPedestrians,setInducedPedestrians] = useState([
    { id: "1", label: "Walk", helperText: "Percentage", value:(newAdditionalInputs.proportion_project_origin_modes.walk).toFixed(0) },
    { id: "2", label: "Bicycle", helperText: "Percentage",value:(newAdditionalInputs.proportion_project_origin_modes.bicycle).toFixed(0)},
    { id: "3", label: "Car & motorcycle (ride share)", helperText: "Percentage",value:newAdditionalInputs.proportion_project_origin_modes.car_and_motorcycle_share },
    { id: "4", label: "Car & motorcycle (solo drive)", helperText: "Percentage",value:newAdditionalInputs.proportion_project_origin_modes.car_and_motorcycle_solo  },
    { id: "5", label: "Taxi / minibus", helperText: "Percentage",value:newAdditionalInputs.proportion_project_origin_modes.taxi_and_minibus  },
    { id: "6", label: "Public transport (bus etc.)", helperText: "Percentage",value:newAdditionalInputs.proportion_project_origin_modes.public_transport },
  
    // adicione mais objetos aqui para gerar mais TextField
  ]);
  const [journeyLength,setJourneyLenght] = useState([
    { id: "1", label: "Walk", helperText: "Km/journey",value:(newAdditionalInputs.journey_length.walk).toFixed(1) },
    { id: "2", label: "Bicycle", helperText: "Km/journey",value:(newAdditionalInputs.journey_length.bicycle).toFixed(1) },
    { id: "3", label: "Car & motorcycle (ride share)", helperText: "Km/journey",value:newAdditionalInputs.journey_length.car_and_motorcycle_share},
    { id: "4", label: "Car & motorcycle (solo drive)", helperText: "Km/journey",value:newAdditionalInputs.journey_length.car_and_motorcycle_solo },
    { id: "5", label: "Taxi / minibus", helperText: "Km/journey",value:newAdditionalInputs.journey_length.taxi_and_minibus },
    { id: "6", label: "Public transport (bus etc.)", helperText: "Km/journey",value:newAdditionalInputs.journey_length.public_transport },
  
    // adicione mais objetos aqui para gerar mais TextField
  ]);

  const [cost, setCost] = useState([
    { id: "1", label: "CAPEX", helperText: "USD $", 
    value: newAdditionalInputs.project_costs_estimates.capex},
    { id: "2", label: "OPEX", helperText: "USD $/year", 
    value: newAdditionalInputs.project_costs_estimates.opex},
  ]);

  useEffect(()=>{
    if(userInputs.typeOfSimulation==2){
      //emptyFieldsWhenChangingSimulationType();
      handleOtherModeChangePercentage(newAdditionalInputs.proportion_project_other_modes.total_mode_shift);
    }
  },[userInputs.typeOfSimulation])

  useEffect(()=>{
    let tempAdvanceErrors = [...advanceErrors]
    let tempList = tempAdvanceErrors.filter(error=>error.errorType!="trips_per_day_error");
    setAdvanceErrors(tempList)
  },[includePreProject])


  const emptyFieldsWhenChangingSimulationType = () => {
    let temp = newAdditionalInputs;
    let tempPedestrians = [...inducedPedestrians];

    temp.proportion_project_origin_modes.total = 0;
    temp.proportion_project_origin_modes.walk = 0;
    temp.proportion_project_origin_modes.bicycle = 0;
    temp.proportion_project_origin_modes.car_and_motorcycle_share = 0;
    temp.proportion_project_origin_modes.car_and_motorcycle_solo = 0;
    temp.proportion_project_origin_modes.taxi_and_minibus = 0;
    temp.proportion_project_origin_modes.public_transport = 0

    for (let i = 0; i < 6; i++) {
      tempPedestrians[i].value = 0;
    }

    setInducedPedestrians(tempPedestrians);
    setNewAdditionalInputs(temp);
  }

  const handleCostChange = (dataLabel,textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempCost = [...cost]
    switch (dataLabel) {
      case "CAPEX":
        temp.project_costs_estimates.capex=textValue
        tempCost[0].value = textValue;
        break;
      case "OPEX":
        temp.project_costs_estimates.opex=textValue
        tempCost[1].value = textValue;
        break;
      default:
        break;
    }
    //validateCapexAndOpexFields(temp.project_costs_estimates.capex,temp.project_costs_estimates.opex,"capex_opex_missing")
    setCost(tempCost)
    setNewAdditionalInputs(temp)
  }

  const validateCapexAndOpexFields = (valueCapex, valueOpex, errorType) => {
    let tempAdvanceErrors = [...advanceErrors]
    if(valueCapex!="" && valueCapex!=0 && valueOpex!="" && valueOpex!=0){
      let tempList = tempAdvanceErrors.filter(error=>error.errorType!=errorType);
      setAdvanceErrors(tempList)
    }
    else if(!tempAdvanceErrors.find(error => error.errorType == "capex_opex_missing")){
      setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
        errorMessage:"Do not forget to fill both CAPEX and OPEX on the Project Parameters Tab",
        errorType: "capex_opex_missing"}]);
    }
  }

  const handleTravelCharacteristicsChange = (textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    temp.pedestrian_travel_characteristics.minimum_trip_distance=textValue;
    setMinTripDistance(textValue);
    setNewAdditionalInputs(temp)
  }

  const handleProportionOfNewPedestrianChange = (textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    temp.proportion_new_pedestrian_trips.new_walking_trips=textValue;
    setNewPedestrianTrips(textValue)
    setNewAdditionalInputs(temp)
  }

  const handlePreTripsPerDayChange = (textValue) => {
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempAvTotals = [...averageTripTotals];
    let tempTrips = [...trips];

    temp.pre_project_conditions_walking.trips_per_day=textValue;
    temp.pre_project_conditions_walking.total_trips_per_day = parseFloat(textValue)*365;
    temp.pre_project_conditions_walking.total_pedestrians_per_year = ((textValue)*365/temp.pedestrian_travel_characteristics.trips_per_year_per_user)

    tempTrips[0].value=textValue*365;
    tempTrips[2].value=((textValue*365)/temp.pedestrian_travel_characteristics.trips_per_year_per_user).toFixed(0)

    if(!(Utils.ValidateErrors(textValue,"trips_per_day_error", advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }

    //setTotalTrips(parseInt(textValue)*365);
    updateMinsActPerMin("PRE",temp,tempAvTotals);
    updateTotalMinutesOfActPerPedestrian("PRE",temp,tempAvTotals)
    setTrips(tempTrips)
    setNewAdditionalInputs(temp);
  }

  const handlePostTripsPerDayChange = (textValue) => {
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempAvTotals = [...averageTripTotals];
    let tempTrips = [...trips];

    temp.post_project_conditions_walking.trips_per_day=textValue;
    temp.post_project_conditions_walking.total_trips_per_day = parseFloat(textValue)*365;
    temp.post_project_conditions_walking.total_pedestrians_per_year = ((textValue)*365/temp.pedestrian_travel_characteristics.trips_per_year_per_user)

    tempTrips[1].value=textValue*365;
    tempTrips[3].value=((textValue*365)/temp.pedestrian_travel_characteristics.trips_per_year_per_user).toFixed(0)

    if(!(Utils.ValidateErrors(textValue,"trips_per_day_post_error", advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }
    //setTotalTrips(parseInt(textValue)*365);
    updateMinsActPerMin("POST",temp,tempAvTotals);
    updateTotalMinutesOfActPerPedestrian("POST",temp,tempAvTotals)
    setTrips(tempTrips)
    setNewAdditionalInputs(temp);
  }

  const handleAverageTripSpeedChange = (id,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempAvSpeed = [...averageTripSpeed];
    switch (id) {
      case "1":
        temp.pre_project_conditions_walking.average_trip_speed=textValue;
        averageTripSpeed[0].value=textValue
        handleTripsAverageTotals(id,temp);
        break;
      case "2":
        temp.post_project_conditions_walking.average_trip_speed=textValue;
        averageTripSpeed[1].value=textValue
        handleTripsAverageTotals(id,temp);
      default:
        break;
    }
    setAverageTripSpeed(tempAvSpeed);
    setNewAdditionalInputs(temp);
  }

  const handleAverageTripDurationChange = (id,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempAvDuration = [...averageTripDuration];
    switch (id) {
      case "1":
        temp.pre_project_conditions_walking.average_trip_duration=textValue;
        tempAvDuration[0].value=textValue
        handleTripsAverageTotals(id,temp);
        break;
      case "2":
        temp.post_project_conditions_walking.average_trip_duration=textValue;
        tempAvDuration[1].value=textValue
        handleTripsAverageTotals(id,temp);
      default:
        break;
    }
    setAverageTripDuration(tempAvDuration);
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  const handleTripsAverageTotals = (id,temp) =>{
    let tempAvTotals = [...averageTripTotals];
    let avTripDistance = 0;
    let tempJourneyLenght = [...journeyLength];
    switch (id) {
      case "1":
        avTripDistance = (parseFloat(newAdditionalInputs.pre_project_conditions_walking.average_trip_duration)*
        (parseFloat(newAdditionalInputs.pre_project_conditions_walking.average_trip_speed)/60));
        temp.pre_project_conditions_walking.average_trip_distance=avTripDistance
        tempAvTotals[0].value=(avTripDistance).toFixed(1);

        updateMinsActPerMin("PRE",temp,tempAvTotals);
        updateTotalMinutesOfActPerPedestrian("PRE",temp,tempAvTotals)

      case "2":
        avTripDistance = (parseFloat(newAdditionalInputs.post_project_conditions_walking.average_trip_duration)*
        (parseFloat(newAdditionalInputs.post_project_conditions_walking.average_trip_speed)/60));
        temp.post_project_conditions_walking.average_trip_distance=avTripDistance
        tempAvTotals[1].value=(avTripDistance).toFixed(1);

        
        temp.journey_length.bicycle=avTripDistance;
        tempJourneyLenght[1].value=avTripDistance.toFixed(1);
        setJourneyLenght(tempJourneyLenght)
  
        updateMinsActPerMin("POST",temp,tempAvTotals);
        updateTotalMinutesOfActPerPedestrian("POST",temp,tempAvTotals)
    }
    setAverageTripTotals(tempAvTotals)
    setNewAdditionalInputs(temp);
  }

  const updateMinsActPerMin = (type, temp, tempAvTotals) =>{
    let minsActPerYear = ""
    switch(type){
      case "PRE":
        minsActPerYear = parseFloat(temp.pre_project_conditions_walking.total_trips_per_day)*
        parseFloat(newAdditionalInputs.pre_project_conditions_walking.average_trip_duration);
        temp.pre_project_conditions_walking.total_minutes_of_activity_per_year = minsActPerYear;
        tempAvTotals[2].value=minsActPerYear;
      case "POST":
        minsActPerYear = parseFloat(temp.post_project_conditions_walking.total_trips_per_day)*
        parseFloat(newAdditionalInputs.post_project_conditions_walking.average_trip_duration);
        temp.post_project_conditions_walking.total_minutes_of_activity_per_year = minsActPerYear;
        tempAvTotals[3].value=minsActPerYear;
    }
  }

  const handleTripsPerYearPerUser = (textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempTrips = [...trips];
    let tempAvTotals = [...averageTripTotals];
    temp.pedestrian_travel_characteristics.trips_per_year_per_user=textValue;
    setTripsPerYearPerDistance(textValue)

    temp.pre_project_conditions_walking.total_pedestrians_per_year = temp.pre_project_conditions_walking.total_trips_per_day/parseFloat(textValue)
    temp.post_project_conditions_walking.total_pedestrians_per_year = temp.post_project_conditions_walking.total_trips_per_day/parseFloat(textValue)
    tempTrips[2].value=(temp.pre_project_conditions_walking.total_trips_per_day/textValue).toFixed(1)
    tempTrips[3].value=(temp.post_project_conditions_walking.total_trips_per_day/textValue).toFixed(1)

    updateTotalMinutesOfActPerPedestrian("PRE",temp,tempAvTotals)
    updateTotalMinutesOfActPerPedestrian("POST",temp,tempAvTotals)

    if(!(Utils.ValidateErrors(textValue,"trips_per_year_per_user_error", advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }
    
    setTrips(tempTrips)
    setNewAdditionalInputs(temp)
  }

  const updateTotalMinutesOfActPerPedestrian = (type, temp, tempAvTotals) => {
    let totalMinsPerPedestrian = 0;
    switch(type){
      case "PRE":
        if(temp.pre_project_conditions_walking.total_minutes_of_activity_per_year==0 && temp.pre_project_conditions_walking.total_pedestrians_per_year==0){
          temp.pre_project_conditions_walking.total_minutes_of_activity_per_pedestrian = 0;
          tempAvTotals[4].value=0;
        }else{
          totalMinsPerPedestrian = temp.pre_project_conditions_walking.total_minutes_of_activity_per_year / 
          temp.pre_project_conditions_walking.total_pedestrians_per_year;
          temp.pre_project_conditions_walking.total_minutes_of_activity_per_pedestrian = totalMinsPerPedestrian;
          tempAvTotals[4].value=(totalMinsPerPedestrian).toFixed(1);
        }
      case "POST":
        if(temp.post_project_conditions_walking.total_minutes_of_activity_per_year==0 && temp.post_project_conditions_walking.total_pedestrians_per_year==0){
          temp.post_project_conditions_walking.total_minutes_of_activity_per_pedestrian = 0;
          tempAvTotals[5].value=0;
        }else{
          totalMinsPerPedestrian = temp.post_project_conditions_walking.total_minutes_of_activity_per_year / 
          temp.post_project_conditions_walking.total_pedestrians_per_year;
          temp.post_project_conditions_walking.total_minutes_of_activity_per_pedestrian = totalMinsPerPedestrian;
          tempAvTotals[5].value=(totalMinsPerPedestrian).toFixed(1);
        }
      }
  }


  const handleOtherModeChangePercentage = (textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempPedestrians = [...inducedPedestrians];
    temp.proportion_project_other_modes.total_mode_shift = textValue;
    tempPedestrians[1].value = 100-parseFloat(textValue)
    temp.proportion_project_origin_modes.bicycle = 100-textValue

    if(!(Utils.ValidateErrors(textValue,"mode_share_error",advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }

    updateTotalPedestrians(temp)
    validateTotalOriginMode(temp);
    setInducedPedestrians(tempPedestrians)
    setNewAdditionalInputs(temp)
  }

  const handleOriginModeChangePercentage = (id, textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempPedestrians = [...inducedPedestrians];
    switch (id) {
      case "1":
      temp.proportion_project_origin_modes.walk = textValue
      tempPedestrians[0].value = textValue;
      break;
      case "3":
      temp.proportion_project_origin_modes.car_and_motorcycle_share = textValue
      tempPedestrians[2].value = textValue;
      break;
      case "4":
      temp.proportion_project_origin_modes.car_and_motorcycle_solo = textValue
      tempPedestrians[3].value = textValue;
      break;
      case "5":
      temp.proportion_project_origin_modes.taxi_and_minibus = textValue
      tempPedestrians[4].value = textValue;
      break;
      case "6":
      temp.proportion_project_origin_modes.public_transport = textValue
      tempPedestrians[5].value = textValue;
      break;
      default:
      break;
    }
    updateTotalPedestrians(temp)
    setInducedPedestrians(tempPedestrians)
    setNewAdditionalInputs(temp)
  }


  const handleJourneyLenghtChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempJourneyLenght = [...journeyLength];
    switch (id) {
      case "1":
      temp.journey_length.walk = textValue
      tempJourneyLenght[0].value = textValue;
      break;
      case "3":
      temp.journey_length.car_and_motorcycle_share = textValue
      tempJourneyLenght[2].value = textValue;
      break;
      case "4":
      temp.journey_length.car_and_motorcycle_solo = textValue
      tempJourneyLenght[3].value = textValue;
      break;
      case "5":
      temp.journey_length.taxi_and_minibus = textValue
      tempJourneyLenght[4].value = textValue;
      break;
      case "6":
      temp.journey_length.public_transport = textValue
      tempJourneyLenght[5].value = textValue;
      break;
      default:
      break;
    }
    setJourneyLenght(tempJourneyLenght)
    setNewAdditionalInputs(temp)
  }

  const updateTotalPedestrians = (temp)=> {
    temp.proportion_project_origin_modes.total = temp.proportion_project_origin_modes.walk+temp.proportion_project_origin_modes.bicycle+
    temp.proportion_project_origin_modes.car_and_motorcycle_share+temp.proportion_project_origin_modes.car_and_motorcycle_solo+
    temp.proportion_project_origin_modes.taxi_and_minibus+temp.proportion_project_origin_modes.public_transport;
    validateTotalOriginMode(temp);
  }

  const validateTotalOriginMode = (temp) => {
    let tempAdvanceErrors = [...advanceErrors]
    console.log("TOTAL: ", temp.proportion_project_origin_modes.total)
    if(temp.proportion_project_origin_modes.total==100){
      tempAdvanceErrors = tempAdvanceErrors.filter(error=>error.errorType!="origin_mode_total_error");
      setAdvanceErrors(tempAdvanceErrors)
    }else{
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }
  }

  const handleCloseOne = () => {
    setOpen(false);
  };

  const handleOpenOne = () => {
    setOpen(true);
  };

  const handlePreConditionChange = (value) => {
    let temp = newAdditionalInputs;
    let tempTrips = [... trips]
    let tempAvTripDuration = [... averageTripDuration]
    let tempAvTripSpeed = [... averageTripSpeed]
    let tempAvTripTotals = [... averageTripTotals]
    if(value==false){
      temp.pre_project_conditions_walking.trips_per_day=0;
      temp.pre_project_conditions_walking.total_trips_per_day=0;
      temp.pre_project_conditions_walking.total_pedestrians_per_year=0;
      temp.pre_project_conditions_walking.average_trip_duration=0;
      temp.pre_project_conditions_walking.average_trip_speed=0;
      temp.pre_project_conditions_walking.average_trip_distance=0;
      temp.pre_project_conditions_walking.total_minutes_of_activity_per_year=0;
      temp.pre_project_conditions_walking.total_minutes_of_activity_per_pedestrian=0;
      tempTrips[0].value=0;
      tempTrips[2].value=0;
      tempAvTripDuration[0].value = 0;
      tempAvTripSpeed[0].value = 0;
      tempAvTripTotals[0].value = 0;
      tempAvTripTotals[2].value = 0;
      tempAvTripTotals[4].value = 0;
      setTrips(tempTrips);
      setAverageTripDuration(tempAvTripDuration);
      setAverageTripSpeed(tempAvTripSpeed);
      setAverageTripTotals(tempAvTripTotals);
      setNewAdditionalInputs(temp);
    }
    setIncludePreProject(value)
  }

  const handleTripVerification = (value) => {
    if(!includePreProject && (value=="1" || value=="3")){
      return false
    }
    return true;
  }

  const handleTotalsVerification = (value) => {
    if(!includePreProject && (value=="1" || value=="3" || value=="5")){
      return false
    }
    return true;
  }

  const handleGeneralVerification = (value) => {
    if(!includePreProject && value=="1"){
      return false
    }
    return true;
  }


  //Used to validate if Error has been Fixed
  // const validateErrors = (value, errorType) => {
  //   let tempAdvanceErrors = [...advanceErrors]
  //   if(value!="" && value!=0){
  //     let tempList = tempAdvanceErrors.filter(error=>error.errorType!=errorType);
  //     setAdvanceErrors(tempList)
  //   }
  // }

  const handleAssessmentTime = (value) =>{
    let temp = userInputs;
    temp.assessmentTime = value;

    if(!(Utils.ValidateErrors(value,"assessment_time_error",advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }

    setUserInputs((userInputs) => ({ ...userInputs, assessmentTime: value }));
  }

  return (
    <TabPanel value="3/2" className="p-0 my-4 mt-5">
      
      <Grid item xs={12} md={12} className="mt-5 mb-5">
        <FormControl fullWidth>
          <InputLabel id="input-first-label" sx={{ fontWeight: "bold" }}>
            Do you want to include Pre-Project Conditions?
          </InputLabel>
          <Select
            labelId=""
            id="select-first-label"
            open={open}
            onClose={handleCloseOne}
            onOpen={handleOpenOne}
            value={includePreProject}
            onChange={(e)=>handlePreConditionChange(e.target.value)}
            label="Select Ciclyng Population Composition Method"
          >
            <MenuItem value={true}>
              <em>Yes</em>
            </MenuItem>
            <MenuItem value={false}>
              <em>No</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container spacing={3} className="align-items-end">
        {includePreProject && <Grid item xs={12} md={includePreProject ? 6: 12}>
          <h6>Pre-Project Conditions</h6>
          <FormControl fullWidth className="mt-3">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={TRIPS_PER_DAY}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
            <TextField
              id="outlined-number"
              label="Trips Per Day (*)"
              helperText="Number"
              error={advanceErrors.findIndex(error=>error.errorType=="trips_per_day_error")>=0}
              value={newAdditionalInputs.pre_project_conditions_walking.trips_per_day}
              onChange={(e)=>handlePreTripsPerDayChange(e.target.value)}
              type="number"
            />
          </FormControl>
        </Grid>}
        <Grid item xs={12} md={includePreProject ? 6: 12}>
          <div className="d-flex flex-column">
            <h6 className="">Post-Project Conditions</h6>
            <FormControl fullWidth className="mt-3">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={TRIPS_PER_DAY}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
              <TextField
                id="outlined-number"
                label="Trips Per Day (*)"
                error={advanceErrors.findIndex(error=>error.errorType=="trips_per_day_post_error")>=0}
                helperText="Number"
                type="number"
                value={newAdditionalInputs.post_project_conditions_walking.trips_per_day}
                onChange={(e)=>handlePostTripsPerDayChange(e.target.value)}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mt-0">
        {trips.map((data, index) => ( handleTripVerification(data.id) &&
          <Grid item xs={12} md={includePreProject ? 6 : 12} key={index}>
            <FormControl fullWidth>
            {(data.id==="1" || data.id==="2") && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={TOTAL_TRIPS_PER_YEAR}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>}
              <TextField
                fullWidth
                id="automatic-filling"
                disabled={true}
                // hidden={!handleTripVerification(data.id) && !includePreProject}
                label={data.label}
                helperText={data.helperText}
                type="number"
                variant="standard"
                value={data.value} />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} className="mt-0">
        {averageTripDuration.map((data, index) => ( handleGeneralVerification(data.id) &&
          <Grid item xs={12} md={includePreProject ? 6: 12} key={index}>
            <FormControl fullWidth>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={AVERAGE_TRIP_DURATION}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
              <TextField
                id="outlined-number"
                label={data.label}
                helperText={data.helperText}
                type="number"
                value={data.value}
                onChange={(e)=>handleAverageTripDurationChange(data.id,e.target.value)}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} className="mt-0">
        {averageTripSpeed.map((data, index) => ( handleGeneralVerification(data.id) &&
          <Grid item xs={12} md={includePreProject ? 6: 12} key={index}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={AVERAGE_TRIP_SPEED}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
            <FormControl fullWidth>
              <TextField
                id="outlined-number"
                label={data.label}
                helperText={data.helperText}
                type="number"
                value={data.value}
                onChange={(e)=>handleAverageTripSpeedChange(data.id,e.target.value)}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3} className="mt-0">
        {averageTripTotals.map((data, index) => ( handleTotalsVerification(data.id) &&
          <Grid item xs={12} md={includePreProject ? 6: 12} key={index}>
            <FormControl fullWidth>
            {(data.id==="1" || data.id==="2") && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={AVERAGE_TRIP_DISTANCE}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>}
              <TextField
                fullWidth
                id="automatic-filling"
                label={data.label}
                disabled={true}
                helperText={data.helperText}
                type="number"
                variant="standard"
                value={data.value}
                // hidden={!handleTotalsVerification(data.id) && !includePreProject}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className="mt-3">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
            <span className="icon"></span>
            </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Source of rationale"
            variant="standard"
          />
        </FormControl>
      </Grid>
      <Grid container spacing={3} className="align-items-end">
        <Grid item xs={12} md={6}>
          <h6 className="mt-5">Cyclist travel characteristics</h6>
          <FormControl fullWidth className="mt-2">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={MINIMUM_TRIP_DISTANCE}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
            <TextField
              value={minTripDistance}
              id="outlined-number"
              label="Minimum trip distance"
              helperText="Km"
              type="number"
              onChange={(e)=>handleTravelCharacteristicsChange(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="">
            <FormControl fullWidth className="mt-3">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={TRIPS_PER_YEAR_PER_USER}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
              <TextField
                value={tripsPerYearPerDistance}
                id="outlined-number"
                label="Trips per year per user"
                error={advanceErrors.findIndex(error=>error.errorType=="trips_per_year_per_user_error")>=0}
                helperText="Trips/year/user"
                type="number"
                onChange={(e)=>handleTripsPerYearPerUser(e.target.value)}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className="mt-3">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
              <span className="icon"></span>
            </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Source of rationale"
            variant="standard"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <h6 className="mt-5">
          Proportion of new cyclist trips induced by the project
        </h6>
        <FormControl fullWidth className="mt-2">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={PROPORTION_NEW_PEDESTRIAN_TRIPS}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
          <TextField
            id="outlined-number"
            label="New cycling trips"
            helperText="Percentage"
            type="number"
            value={newPedestrianTrips}
            onChange={(e)=>handleProportionOfNewPedestrianChange(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <h6 className="mt-5">
          Proportion of project induced cyclists from other modes
        </h6>
        <FormControl fullWidth className="mt-2">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={TOTAL_MODE_SHIFT}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
          <TextField
            id="outlined-number"
            label="Total mode shift (*)"
            helperText="Percentage"
            value={newAdditionalInputs.proportion_project_other_modes.total_mode_shift}
            error={advanceErrors.findIndex(error=>error.errorType=="mode_share_error")>=0}
            onChange={(e)=>handleOtherModeChangePercentage(e.target.value)}
            min="0"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </FormControl>
      </Grid>
      <h6 className="mt-5">
        Proportion of project induced cyclists by origin modes <StyledTooltip title={PROPORTION_PROJECT_INDUCED} arrow><span className="icon"></span></StyledTooltip>
      </h6>
      <Grid container spacing={3} className="mt-0">
        {inducedPedestrians.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              {index === 1 ? (
                <TextField
                  className="mt-2"
                  id="automatic-filling"
                  disabled={true}
                  label={data.label}
                  helperText={data.helperText}
                  value={data.value}
                  variant="standard"
                  type="number"
                />
              ) : (
                <TextField
                  id="filled-number"
                  label={data.label}
                  helperText={data.helperText}
                  value={data.value}
                  onChange={(e)=>handleOriginModeChangePercentage(data.id,e.target.value)}
                  variant="outlined"
                  type="number"
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className="mt-3">
          <TextField
            id="automatic-filling"
            error={advanceErrors.findIndex(error=>error.errorType=="origin_mode_total_error")>=0}
            disabled={true}
            label="Total"
            helperText="Percentage"
            variant="standard"
            value={newAdditionalInputs.proportion_project_origin_modes.total}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className="mt-2">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
               <span className="icon"></span>
            </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Source of rationale"
            variant="standard"
          />
        </FormControl>
      </Grid>
      <h6 className="mt-5">Journey length <StyledTooltip title={JOURNEY_LENGHT} arrow><span className="icon"></span></StyledTooltip></h6>
      <Grid container spacing={3} className="mt-0">
        {journeyLength.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              {index === 1 ? (
                <TextField
                  className="mt-2"
                  id="automatic-filling"
                  disabled={true}
                  label={data.label}
                  helperText={data.helperText}
                  value={data.value}
                  variant="standard"
                  type="number"
                />
              ) : (
                <TextField
                  id="filled-number"
                  label={data.label}
                  helperText={data.helperText}
                  value={data.value}
                  variant="outlined"
                  type="number"
                  onChange={(e)=>handleJourneyLenghtChange(data.id,e.target.value)}
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className="mt-3">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
              <span className="icon"></span>
            </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Source of rationale"
            variant="standard"
          />
        </FormControl>
      </Grid>
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        Fill in the information below to evaluate the economic benefits of your actions including Value of a Statistical Life (VSL), Value of a Life Year (VOLY), discounted implementation costs 
       and benefit to cost ratio. Assessment time is required to project the timeframe over which health benefits and operating costs will be considered in the estimation of economic benefits. 
       Cost data (CAPEX and OPEX) are optional input fields if users have data available and wish to see results for the discounted implementation costs and benefit to cost ratio. 
      </Typography>
      <h6 className="mt-5">Cost</h6>
      <Grid container spacing={3} className="mt-0">
      <Grid item xs={12} md={6}>
          <FormControl fullWidth>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={TIME_FRAME} arrow>
            <span className="icon"></span>
          </StyledTooltip></div>
            <TextField
              id="outlined-helperText"
              label="Assessment timeframe"
              //defaultValue="Assessment Time"
              helperText="# of Years"
              error={advanceErrors.findIndex(error=>error.errorType=="assessment_time_error")>=0}
              defaultValue={userInputs.assessmentTime}
              onChange={(e)=> handleAssessmentTime(e.target.value)}
              type="number"
            />
          </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
        {cost.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
            {data.id==="1" ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={CAPEX}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div> : 
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={OPEX}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>}
              <TextField
                id="outlined-number"
                label={data.label}
                error={advanceErrors.findIndex(error=>error.errorType=="capex_opex_missing")>=0}
                helperText={data.helperText}
                value={data.value}
                type="number"
                variant="outlined"
                onChange={(e)=>handleCostChange(data.label,e.target.value)}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );
}
