import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, TextField, Grid, Button, FormHelperText } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";

import { Typography } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';

import DataService from "../../services/getData";

import "../../css/databasePage.css";

import { useEffect, useState } from "react";
import InformationModal from "../basicModal/informationModal";
import CasualChainModal from "../basicModal/casualChainModal";

export default function AnalysisSelection({setListOfActions,listOfActions,cleanFieldsParent,setCleanFieldsParent, setUserInputs, 
  actionList, setHasResult, userInputs, isSimulationOption, setIsSumulationOption}) {


  const [indicatorId, setIndicatorId] = useState(""); // logic for the First Input Label

  const[actionSimulationId, setActionSimulationId] = useState("")

  const [selectedImpact,setSelectedImpact] = useState({})

  const [listOfImpacts, setListOfImpacts] = useState([]);

  const [listOfAreaActions,setListOfAreaActions] = useState([]);

  const [impactValue, setImpactValue] = useState("")

  const [impactDescription, setImpactDescription] = useState("");

  const[unitSymbol, setUnitSymbol] = useState("");

  const [otherId, setOtherId] = useState("");

  const [modalOpen, setModalOpen] = useState(false)

  const [openChainModal, setOpenChainModal] = useState(false)

  const [error, setError] = useState("");

  const [isError, setIsError] = useState(false)

  const [ hasActionError, setActionError ] = useState(false)

  const [ hasImpactError, setImpactError ] = useState(false)

  const [ bodyText, setBodyText] = useState("");

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    // zIndex: theme.zIndex.tooltip + 1,
    //margin: 4,
    [`& .MuiTooltip-tooltip`]: {
      // maxWidth: 200,
      // height: 100,
      // fontFamily: "'Grape Nuts', Helvetica",
      fontFamily:"'Roboto', 'Helvetica', 'Arial','sans-serif'",
      backgroundColor: "#EFFBFF",
      color: "black",
      fontSize: 16,
      // margin: 4,
      // padding: 8,
      whiteSpace: "pre-line"
      //border: "solid yellow 1px"
    }
  }));
  

  const [simulationType, setSimulationType] = useState([
    { id: 1, value: "Walking"},
    { id: 2, value: "Cycling"},
  ]);

  const [impactListValue, setImpactListValue] = useState("");

  const handleAreaDropdown = (event) =>{
    handleProjectSimulationType(0)
    setIndicatorId(event);
    cleanFields();
    filterActionDropdown(event)
  }

  useEffect(() => {
    setIndicatorId("")
    cleanFields();
    setCleanFieldsParent(false)
  },[cleanFieldsParent])

  const cleanFields = () =>{
    setActionSimulationId("")
    setListOfImpacts([])
    setSelectedImpact({})
    setImpactValue("")
    setImpactDescription("")
    setUnitSymbol("")
    setListOfAreaActions([])
    // setIsOther(false)
    setListOfImpacts([])
    setActionError(false)
    setImpactError(false)
  }

  const handleButtonShowTable = () => {
  let actionInfo = actionList.find(action => action.actionSimulationId === actionSimulationId);
  if(validateFields() && !validateListLength() && !validateIndicatorType(actionInfo)){
    if(!validateListOfAreaActions(actionInfo)){
      //actionInfo = data.find(action => action.indicatorId === indicatorId && action.actionSimulationTypeName=='Other');
      //setListOfActions(listOfActions => [...listOfActions, {area:actionInfo.indicatorName,action:actionInfo.actionSimulationTypeName}])
    setListOfActions(listOfActions => [...listOfActions, {area:actionInfo.indicatorName,action:actionInfo.actionSimulationName, actionId: actionInfo.actionSimulationId,
      actionType: actionInfo.actionSimulationTypeName,impact:impactValue+unitSymbol,impactListValue:impactListValue,impactListType:actionInfo.impactTypeName}])
    // }else if(!isOther){
    //   actionInfo = data.find(action => action.actionSimulationId === actionSimulationId);
    //   setListOfActions(listOfActions => [...listOfActions, {area:actionInfo.indicatorName,action:actionInfo.actionSimulationName,impact:impactValue+unitSymbol}])
     }
    setHasResult(false)
    setIndicatorId("")
    cleanFields()
    }
  };

  const handleDeleteTable = (index) => {
    let newArr = listOfActions.splice(index, 1);
    setListOfImpacts(newArr)
  };

  const validateListLength = () =>{
    if(listOfActions.length>=5){
      setBodyText("You can only add up to 5 actions in a simulation")
      setModalOpen(true);
      return true;
    }
    return false;
  }

  const validateIndicatorType = (action) =>{
    if(listOfActions.find(indicator => indicator.area === action.indicatorName)){
      setBodyText("You cannot add more then one action for the same indicator")
      setModalOpen(true);
      return true;
    }
    return false;
  }

  const selectedActionValue = actionSimulationId
  
  const filterAreaDropdown =  [...new Map(actionList.map(item => [item.indicatorName, item])).values()];

  const filterActionDropdown = (id) =>{
    let temp = actionList.filter(action => {return action.indicatorId === id});
    let other = temp.find(item => item.actionType == 'Other')
    if(other){
      setOtherId(other.actionSimulationTypeId)
      setListOfAreaActions([...temp, {actionSimulationId:other.actionSimulationTypeName, actionSimulationName: other.actionSimulationTypeName}])
    }else{
      setListOfAreaActions(temp)
    }
  }

  const validateFields = () =>{
    if(selectedImpact.impactTypeName == undefined){
      setActionError(true)
      return false
    }
    if(!impactValue && selectedImpact.impactTypeName!='Binary'){
      setImpactError(true)
      return false
    }
    return true
  }

  const validateListOfAreaActions = (actionInfo) =>{
    if(listOfActions.find(item => item.actionType=='Other') && actionInfo.actionSimulationTypeName=="Other"){
      setBodyText("That action cannot be added because there can only be 1 non-listed action in the simulation")
      setModalOpen(true);
      return true;
    }
    if(listOfActions.find(item => item.actionType!=actionInfo.actionSimulationTypeName)){
      setBodyText("You cannot mix 15 minute action types with non-listed action types")
      setModalOpen(true);
      return true;
    }
    return false;
  }

  const handleImpactDropdown = (event) => {
    setActionSimulationId(event);
    const temp = actionList.find(action => action.actionSimulationId === event);
    let list = [];
    setSelectedImpact(temp);
    setImpactValue("")
    setImpactDescription("")
    if(temp.impactTypeName!='Binary'){
      list = [{impactTypeName: temp.listItem1Name,impactDescription: temp.listItem1Description, impactValue:temp.listItem1Value},
        {impactTypeName: temp.listItem2Name,impactDescription: temp.listItem2Description, impactValue:temp.listItem2Value},
        {impactTypeName: temp.listItem3Name,impactDescription: temp.listItem3Description, impactValue:temp.listItem3Value}];
      setListOfImpacts(list);
      temp?.valueName ? unitSymbolMaker(temp.valueUnitInstruction) : setUnitSymbol("");
    }else{
      setImpactValue("")
    }
    setActionError(false)
    setImpactError(false)
  }

  const handleUpdateImpactDropdown = (event) =>{
    const temp = listOfImpacts.find(impact => impact.impactTypeName===event)
    setImpactDescription(temp.impactDescription)
    setImpactListValue(parseFloat(temp.impactValue))
    setImpactValue(event)
    setImpactError(false)
  }

  const unitSymbolMaker = (unit)=>{
    let value = ""
    switch (unit) {
      case 'Percentage':
        value = '%'
        break;
      case 'Square meters':
        value = 'm2'
        break;
    }
    setUnitSymbol(value)
  }

  const openChainModalOnClick = () =>{
    if(!openChainModal){
      setOpenChainModal(true)
    }else{
      setOpenChainModal(false)
    }
  }

  const handleProjectSimulationType = (value) => {
    setHasResult(false)
    setUserInputs((userInputs) => ({ ...userInputs, typeOfSimulation: value }));
  }

  const handleSimulationOption = (event) => {
    if (event.target.value == 'no'){
      setIndicatorId("")
      cleanFields();
      setCleanFieldsParent(false)
      setListOfActions([])
      setIsSumulationOption('no')
    }else{
      setIsSumulationOption('yes')
    }
    setUserInputs((userInputs) => ({ ...userInputs, typeOfSimulation: "" }));
  }

  return (
    <TabPanel value="1" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        This page allows the user to define the project for simulation of health impacts. 
        The user should add actions included in the project. For each action, the user should define its level of impact where required.
      </Typography>

      <h5 style={{ marginTop: "30px" }}>Simulation Options</h5>
      <Typography className="paragraph_default mt-4" gutterBottom>
        This tool enables you to choose from a pre-defined set of urban design actions you want to simulate 
        or to manually input the data related to the changes in walking/cycling.
      </Typography>
      <Typography className="paragraph_default mt-4" gutterBottom>
        DO YOU WANT TO CHOOSE FROM A PRE-DEFINED SET OF ACTIONS?
      </Typography>
      <br></br>
      <div className="mb-4">
      <FormControl component="fieldset">
        <RadioGroup name="option" value={isSimulationOption} onChange={handleSimulationOption}>
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>YES</span>
                <br />
                (you can choose between pre-defined actions)
              </Typography>
            }
          />
          <br></br>
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={
              <Typography variant="body1">
                <span style={{ fontWeight: 'bold' }}>NO</span>
                <br />
                (you will be asked to input data related to the changes in walking/cycling)
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
      </div>

  {isSimulationOption==='yes' && <>
    <h6 className="mb-3">Actions</h6>
    <Typography className="paragraph_default my-3" gutterBottom>
      In this page, you are asked to define the actions within your project. Each action is associated with improving a specific indicator within the city. 
      </Typography>
      <Typography
        className="component_first_subtitle mb-4"
        variant="caption"
        gutterBottom
      >
       You can add up to 5 actions to your simulation as long as they are linked to different indicators. With respect to impact, the selected level of impact will determine the magnitude of your action.
      Depending on the indicator, you can either specify impact by entering a numerical value or selecting a pre-defined level (e.g. minimum, medium, optimal). 
      Hover over the information icon (i) to quickly find out more details about the actions and impact levels selected, or consult the 'Database and terms' page for further clarification.
      </Typography>
        <Grid
          container
          spacing={3}
          className="mb-3"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="input-first-label">Indicator</InputLabel>
            <Select
              labelId=""
              id="select-first-label"
              value={indicatorId}
              defaultValue={indicatorId}
              onChange={(e) => handleAreaDropdown(e.target.value)}
              label="Area"
            >
              <MenuItem disabled value=''>
                <em>Select an Indicator</em>
              </MenuItem>
              {filterAreaDropdown.map((action, index) => (
                <MenuItem key={`indicator-${index}`} value={action.indicatorId}>
                  {action.indicatorName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          {indicatorId && (
            <>
              <FormControl fullWidth>
                <InputLabel id="input-second-label">Select an action</InputLabel>
                <Select
                  labelId=""
                  id="select-second-label"
                  defaultValue=''
                  value={selectedActionValue}
                  onChange={(e) => handleImpactDropdown(e.target.value)}
                  label="Action"
                >
                  <MenuItem disabled value=''>
                    <em>Select an Action</em>
                  </MenuItem>
                  {listOfAreaActions.map((action, index) => (
                    <MenuItem key={`action-${index}`} value={action.actionSimulationId}>
                      {action.actionSimulationName}
                    </MenuItem>
                  ))}
                </Select>
                {selectedImpact.actionSimulationId && (
                  <StyledTooltip title={selectedImpact.actionSimulationDescription} arrow>
                  <span className="icon mt-1"></span>
                </StyledTooltip>
                )}
                {hasActionError && (
                  <FormHelperText error>This is required!</FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {selectedImpact.actionSimulationId && selectedImpact.impactTypeName != 'Binary' && (
            <>
              <FormControl fullWidth>
                {selectedImpact.impactTypeName == 'List' && <><InputLabel id="demo-controlled-open-select-label">
                  Impact
                </InputLabel><Select
                  labelId=""
                  id="select-second-label"
                  defaultValue=''
                  value={impactValue}
                  required
                  onChange={(e) => handleUpdateImpactDropdown(e.target.value)}
                  label="Impact"
                >
                    <MenuItem value='' disabled>
                      <em>Select an Impact Level</em>
                    </MenuItem>
                    {listOfImpacts.map((impact) => <MenuItem key={impact.impactTypeName} value={impact.impactTypeName}>{impact.impactTypeName}</MenuItem>)}
                  </Select>
                  {!impactDescription && <StyledTooltip title={(selectedImpact.listItem1Name ? selectedImpact.listItem1Name + " : " + selectedImpact.listItem1Description + ". \n" : "")
                    + (selectedImpact.listItem2Name ? selectedImpact.listItem2Name + " : " + selectedImpact.listItem2Description + ". \n" : "")
                    + (selectedImpact.listItem3Name ? selectedImpact.listItem3Name + " : " + selectedImpact.listItem3Description + ". \n" : "")} arrow>
                    <span className="icon mt-1"></span>
                  </StyledTooltip>}
                  {impactDescription && <StyledTooltip title={impactDescription} arrow>
                    <span className="icon mt-1"></span>
                  </StyledTooltip>}
                  {hasImpactError && <FormHelperText error>This field is required!</FormHelperText>}
                </>}

                {selectedImpact.impactTypeName == 'Value' &&
                  <><TextField type="number"
                    required
                    onChange={(e) => setImpactValue(e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">{unitSymbol}</InputAdornment>,
                    }}
                    // error={impactValue === ""}
                    // helperText={impactValue === "" ? 'Please insert Value' : ' '}
                    label={selectedImpact.valueName} />
                    <span id="helperText">{selectedImpact.valueUnitInstruction}</span>
                    {hasImpactError && <FormHelperText error>This field is required!</FormHelperText>}</>}
              </FormControl>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            onClick={() => handleButtonShowTable()}
            variant="outlined"
            size="large"
            style={{ width: "100%", height: "56px" }}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </Grid>
      </Grid><div className="py-4 mt-5">
          {listOfActions.length > 0 && (<><TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Indicator</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Impact</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listOfActions.map((action, index) => (
                  <TableRow key={index} value={action}>
                    <TableCell>{action.area}</TableCell>
                    <TableCell>{action.action}</TableCell>
                    <TableCell>{action.impact}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className="c-40-default-color-iconDelete"
                        onClick={() => handleDeleteTable(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </>)}
        </div></>}

      {isSimulationOption==='no' && 
      <><h6 className="mb-3">Project Type</h6><Grid
          container
          spacing={3}
          className="mb-3"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="input-first-label">Project Type</InputLabel>
              <Select
                labelId=""
                value={userInputs.typeOfSimulation}
                id="select-first-label"
                onChange={(e) => handleProjectSimulationType(e.target.value)}
                label="Area"
              >
                <MenuItem disabled value=''>
                  <em>Select a Project Type</em>
                </MenuItem>
                {simulationType.map((type) => <MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid></>}
        {isSimulationOption!==""  && <div
              className="mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
            <Button onClick={openChainModalOnClick} className="default_modal">
              Causal Chain
            </Button>
          </div>}

      {<CasualChainModal title="Causal Chain" simulationType={isSimulationOption} modalOpen={openChainModal} setModalOpen={setOpenChainModal}/>}

      {<InformationModal body={bodyText} modalOpen={modalOpen} setModalOpen={setModalOpen} 
        positiveButtonModalLabel='OK'/>}
    </TabPanel>
  );
}
