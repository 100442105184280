import AppRouter from "./components/template/template";
import DataService from "./services/getData";
import '@fontsource/roboto/300.css';
import "./css/App.min.css";

function App() {

  //DataService.GetCitiesToJson();
  DataService.GetCities();
  //console.log("JsonCities=", jsonCities);

  return (
    <div className="App c40">
      <AppRouter />
    </div>
  );
}

export default App;
