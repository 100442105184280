import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useState } from "react";
import {
  NavLink,
  Routes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import LoginDialog from "../loginForm/loginForm";

import "./template.min.css";

import HomePage from "../../pages/home";
import ProjectPage from "../../pages/project";
import AuthService from "../../services/auth.service";
import ErrorPage from "../../pages/errorPage";
import DatabasePage from "../../pages/databasePage";
import AboutPage from "../../pages/about";

import c40_logo from "../../assets/c40_logo_correct.svg";

function Home() {
  checkPathName();
  return <HomePage />;
}

function Databaseterms() {
  checkPathName();
  return <DatabasePage />;
}

function ShowErrorPage() {
  return (
    <>
      <ErrorPage />
    </>
  );
}

function Project() {
  checkPathName();
  const user = AuthService.getCurrentUser();
   return user ? <ProjectPage /> 
   : window.location.reload(true);
}

function About() {
  checkPathName();
   return <AboutPage />;
}

function checkPathName() {
 //console.log("Pathname=", window.location.pathname)
  if (window.location.pathname === "/")
    document.body.style.overflow = "hidden";
  //else if (window.location.pathname === "/project")
  //  document.body.style.overflowX = "hidden";
  else
    document.body.style= "";
}

function AppRouter() {
  const [currentUser, setCurrentUser] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState("");
  // const navigate = useNavigate();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    AuthService.logout().then(
      () => {
        //navigate("/");
        setCurrentUser(undefined);
        localStorage.clear();
        window.location.reload();        
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(resMessage);
      }
    );
  };

  React.useEffect(() => {
    const user = AuthService.getCurrentUser();
    //const user = "Test";
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  return (
    <div>
      <Router>
        <AppBar position="static" sx={{ backgroundColor: "#23bced" }}>
          {/*<a className="go-home" href="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="48" className="logo-out-navbar">
              <path d="M.1 12.09v-.07C.1 5.4 5.06-.02 12.11-.02c4.3 0 6.89 1.5 9.11 3.63L18.6 6.63c-1.86-1.73-3.85-2.89-6.51-2.89-4.46 0-7.68 3.66-7.68 8.21v.07c0 4.56 3.22 8.28 7.68 8.28 2.87 0 4.69-1.16 6.7-3.02l2.61 2.66c-2.43 2.53-5.09 4.12-9.45 4.12C5.16 24.06.1 18.77.1 12.11m36.29 6.33H24.65l-.76-2.93L36.82.21h3.5v14.87h3.34v3.35H40.3v5.24h-3.9v-5.23zm0-3.35V5.87l-7.65 9.21 7.65.01zm10.91-3v-.07c0-6.55 5.05-12.04 12.2-12.04 7.15 0 12.14 5.42 12.14 11.95V12c0 6.56-5.05 12.04-12.2 12.04-7.16 0-12.15-5.42-12.15-11.95m20.08 0v-.07c0-4.51-3.29-8.27-7.92-8.27-4.62 0-7.87 3.68-7.87 8.2v.07c0 4.52 3.3 8.28 7.92 8.28s7.85-3.71 7.85-8.21M.1 39.75v-.04a7.88 7.88 0 018.03-8.06c2.3-.08 4.51.8 6.12 2.43l-1.76 2.03c-1.25-1.16-2.58-1.94-4.37-1.94-2.98 0-5.13 2.45-5.13 5.5v.04c0 3.05 2.15 5.54 5.13 5.54 1.92 0 3.14-.78 4.47-2.03L14.35 45a8.01 8.01 0 01-6.32 2.76 7.84 7.84 0 01-7.91-8m17.25-7.84h2.73v15.57h-2.73zm10.71 2.54h-4.93v-2.54h12.63v2.54h-4.93V47.5h-2.77zm10.75-2.54h2.73v15.57h-2.73zm6.9 0h11.54v2.45h-8.8v4.05h7.8v2.45h-7.8v4.17h8.91v2.46H45.73zm13.85 13.31l1.65-1.96c1.5 1.29 2.98 2.02 4.92 2.02 1.69 0 2.75-.78 2.75-1.96v-.04c0-1.11-.62-1.71-3.51-2.4-3.31-.79-5.18-1.77-5.18-4.64v-.05c0-2.66 2.22-4.51 5.31-4.51a8.64 8.64 0 015.65 1.96L69.7 35.7a7.07 7.07 0 00-4.22-1.6c-1.61 0-2.54.82-2.54 1.85V36c0 1.2.72 1.74 3.7 2.45 3.28.8 5 1.98 5 4.56v.05c0 2.91-2.3 4.64-5.57 4.64a9.56 9.56 0 01-6.5-2.49" />            
            </svg>
          </a>*/}
          <a className="go-home" href="/">
              <img width = "82" height = "82" src={c40_logo}></img>            
          </a>
          <Container className="col-12 col-lg-8">
            <Toolbar disableGutters>
              <nav>
                <NavLink exact="true" activeclassname="active" to="/">
                  Home
                </NavLink>
                {currentUser && (
                  <NavLink activeclassname="active" to="/project">
                    Project
                  </NavLink>
                )}
                <NavLink activeclassname="active" to="/databaseterms">
                  Database and Terms
                </NavLink>
                <NavLink activeclassname="active" to="/about">
                  About
                </NavLink>
              </nav>
            </Toolbar>
          </Container>

          <div id="btn-login">
            {currentUser ? (
              <Button variant="contained" onClick={logOut}>
                Logout
              </Button>
            ) : (
              <LoginDialog />
            )}
          </div>
          
        </AppBar>

          <Routes>
            <Route path='/' element={<Home/>} />
            {currentUser ? (
              <Route path='/Project' element={<Project/>} />
            ) : (
              <Route path='/Project' element={<ShowErrorPage/>} />
            )}
            <Route path='/databaseterms' element={<Databaseterms/>} />
            <Route path='/about' element={<About/>} />
          </Routes>
      </Router>
    </div>
  );
}

export default AppRouter;