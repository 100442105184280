import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useState, useEffect } from "react";
import Health from "./health";
import Economic from "./economic";
import UrbanMobility from "./urbanMobility";

import Walking from "./walking";
import Cycling from "./cycling";
import Cost from "./cost";

import "../../css/databasePage.css";
import DataService from "../../services/getData";
import MuiAlert from '@mui/material/Alert';
import { Snackbar, SnackbarValue, Typography } from "@mui/material";
import Safety from "./safety";

export default function GeneralParam({advanceErrors, setAdvanceErrors,userInputs, setNewAdditionalInputs, newAdditionalInputs, 
  listOfActions, setUserInputs, yldTable, mortalityTable, populationTable, singleNationalCompositionTable, setSingleNationalCompositionTable,
  citySpecificCompositionTable, setCitySpecificCompositionTable, setSelectedCurrency, selectedCurrency, includePreProject}) {
  // const [selectedCurrency, setSelectedCurrency]=useState({});
  const [valueSecondTab, setValueSecondTab] = useState("1");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [additionalInputs, setAdditionalInputs] = useState({
      ageRangeOfCyclists: [{age_range_id:"",max_age:"",min_age:""}]
  })
  // const [newAdditionalInputs, setNewAdditionalInputs] = useState({})
  const [healthErrorList, setHealthErrorList] = useState([]);
  // const [currencyList, setCurrencyList] = useState([]);


  const [SnackbarValue, setSnackbarValue] = useState({
    severity:"warning",
    message:""
  })

  const handleClose = () => setSnackbarValue({
    severity:"warning",
    message:""
  });

  useEffect(() => {
    if(!(SnackbarValue.message=="")){
      setOpenSnackbar(true)
    }else{
      setOpenSnackbar(false)
    }
  },[SnackbarValue])

  useEffect(() => {
    // Validate current valueSecondTab whenever userInputs.typeOfSimulation changes
    if (userInputs.typeOfSimulation === 0) {
      setValueSecondTab("1");
    }
  }, [userInputs.typeOfSimulation]);
  
  // const getData = async () => {
  // try {
  //   const dataTemp = await DataService.getAdditionalInputs(cityInformation.regionName);
  //   setNewAdditionalInputs(dataTemp.data);
  //   setAdditionalInputs(dataTemp.data)
  //   } catch (error) {
  //     setSnackbarValue({severity:'error',
  //     message:error.response.data.message})
  //   }
  // }

  // console.log('City: ', cityInformation.countryName)
  // console.log('Currency: ',selectedCurrency.nation)
  // console.log('Currency Type: ',selectedCurrency.currencyType)

  // const getCurrency = async () =>{
  //   try{
  //     const dataTemp = await DataService.getCurrency()
      
  //     setCurrencyList(dataTemp.data)
  //     const currency = dataTemp.data.find(currency => currency.nation == cityInformation.countryName)
  //     console.log("Currency=", currency);
  //     setSelectedCurrency(currency)
  //   }catch (error) {
  //     setSnackbarValue({severity:'error',
  //     message:error.response.data.message})
  //   }
  // }

  // console.log(newAdditionalInputs)
  // console.log(additionalInputs)

  const handleChangeSecondTab = (event, newValue) => {
    // console.log(additionalInputs)
    // console.log(newAdditionalInputs)
    // if((additionalInputs) != (newAdditionalInputs.morbidity_risk_reduction)) {
    //   setHealthErrorList([...healthErrorList,"Morbidity"]);
    //   setSnackbarValue({severity:'error',
    //   message:"cocó"})
    // } else {
      // if (advanceErrors.length < 1)
      // {
      setValueSecondTab(newValue);
      // } else {
      //   setSnackbarValue({severity:'error',
      //   message:"Please verify current page errors before advancing (" + advanceErrors.map(error => `${error.errorMessage}`) + ")"})
      // };
    // }
  };

  const validateSimulationType = () => {
    if(userInputs.typeOfSimulation==1){
      return 'Walking'
    }else if(userInputs.typeOfSimulation==2){
      return 'Cycling'
    }
    return null;
  }
  // validateFields()

  return (
    <TabPanel value="2" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        On this page, the user can consult all the default general parameters that have been found in the literature and that will be used in the simulation. 
        Users do not need to enter or modify any data on this page, unless they have specific information available that they want to take into account in the analysis.
      </Typography>
      <TabContext value={valueSecondTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="mt-5">
          <TabList onChange={handleChangeSecondTab} aria-label="lab API tabs example" id="tabsLevel2" value={valueSecondTab}>
            <Tab label="Health" value="1" />
            {userInputs.typeOfSimulation !=0 && <Tab label="Economic" value="2" />}
            {userInputs.typeOfSimulation !=0 && <Tab label="Urban Mobility" value="3" />}
            {listOfActions.find(item => item.area == 'Urban crime rate') && <Tab label="Safety" value="4" />}
            {/* {listOfActions.find(item => item.actionType == 'Other') && listOfActions.find(item => item.area == 'Walking') && !(listOfActions.some(item => item.area == 'Cycling' && item.actionType=="Other")) && 
            <Tab label="Walking" value="4" />}
            {listOfActions.find(item => item.actionType == 'Other') && listOfActions.find(item => item.area == 'Cycling') && !(listOfActions.some(item => item.area == 'Walking' && item.actionType=="Other")) && 
            <Tab label="Cycling" value="5" />} */}
            {/* <Tab label="Cost" value="6" /> */}
          </TabList>
        </Box>
        <Health includePreProject={includePreProject} newAdditionalInputs={newAdditionalInputs} yldTable={yldTable} mortalityTable={mortalityTable}
        setNewAdditionalInputs={setNewAdditionalInputs} healthErrorList={healthErrorList} populationTable={populationTable} setAdvanceErrors={setAdvanceErrors} advanceErrors={advanceErrors} 
        setUserInputs={setUserInputs} userInputs={userInputs} listOfActions={listOfActions}/>
        {userInputs.typeOfSimulation !=0 && 
          <Economic newAdditionalInputs={newAdditionalInputs}
          setNewAdditionalInputs={setNewAdditionalInputs} setSelectedCurrency={setSelectedCurrency} 
          selectedCurrency={selectedCurrency}
        />}
        {userInputs.typeOfSimulation !=0 && 
          <UrbanMobility newAdditionalInputs={newAdditionalInputs} userInputs={userInputs}
          setNewAdditionalInputs={setNewAdditionalInputs} setAdvanceErrors={setAdvanceErrors} advanceErrors={advanceErrors} setUserInputs={setUserInputs}
          populationTable={populationTable} singleNationalCompositionTable={singleNationalCompositionTable} 
          setSingleNationalCompositionTable={setSingleNationalCompositionTable} 
          citySpecificCompositionTable={citySpecificCompositionTable} 
          setCitySpecificCompositionTable={setCitySpecificCompositionTable} includePreProject={includePreProject}
          actionType={validateSimulationType()} listOfActions={listOfActions}
        />}
        {/* <Walking newAdditionalInputs={newAdditionalInputs}
        setNewAdditionalInputs={setNewAdditionalInputs} userInputs={userInputs}/>
        <Cycling newAdditionalInputs={newAdditionalInputs}
        setNewAdditionalInputs={setNewAdditionalInputs} userInputs={userInputs}/> */}
        {/* <Cost newAdditionalInputs={newAdditionalInputs}
        setNewAdditionalInputs={setNewAdditionalInputs}/> */}
        <Safety userInputs={userInputs} setUserInputs={setUserInputs} setAdvanceErrors={setAdvanceErrors} advanceErrors={advanceErrors} 
        listOfActions={listOfActions} includePreProject={includePreProject}></Safety>
      </TabContext>
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={handleClose}>
                <MuiAlert severity={SnackbarValue.severity}>{SnackbarValue.message}</MuiAlert >
        </Snackbar>
    </TabPanel>
  );
}
