import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from 'react';
import PropTypes from 'prop-types';


const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, customStyles }) => ({
    [`& .MuiTooltip-tooltip`]: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
      backgroundColor: "#EFFBFF",
      color: "black",
      fontSize: 16,
      whiteSpace: "pre-line",
      ...customStyles, // Allow passing custom styles
    },
  }));
  
  StyledTooltip.propTypes = {
    customStyles: PropTypes.object, // Custom styles for the tooltip
  };
  
  export default StyledTooltip;