
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import AnalysisSelection from "./analysisSelection";
import "../../css/databasePage.css";

import { useState, useEffect } from "react";
import Save from "./save";
import InformationModal from "../basicModal/informationModal";
import ConfirmationModal from "../basicModal/confirmationModal";
import Simulation from "./simulation";
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from "@mui/material";
import GeneralParam from "./generalParam";
import Walking from "./walking";
import Cycling from "./cycling";
import Cost from "./cost";
import Utils from "../../utils/miscUtils";
// import AnalysisSelectionTeste from "./analysisSelectionsTeste";

export default function ProjectStep({listOfActions, setListOfActions, cityInformation, currencyList, setNewAdditionalInputs, 
  newAdditionalInputs, yldTable, mortalityTable, populationTable, singleNationalCompositionTable,setSingleNationalCompositionTable,
  citySpecificCompositionTable,setCitySpecificCompositionTable, actionList, hasResult, setHasResult, simulationResults, setSimulationResults,
  setSelectedCurrency, selectedCurrency, userInputs, setUserInputs, setListOfProjects, projects, setSavedProject, includePreProject, setIncludePreProject,
  isSimulationOption, setIsSumulationOption
}) {
  const [value, setValue] = useState("1");
  const [noActionOpenModal, setNoActionOpenModal] = useState(false)
  const [onlyOneOtherActionModal, setOnlyOneOtherActionModal] = useState(false)
  const [cleanFields, setCleanFields] = useState(false);
  const [firstTimeNextTab, setFirstTimeNextTab] = useState(true);
  const [advanceErrors, setAdvanceErrors] = useState([])
  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  
  const [projectData, setProjectData] = useState({
    projectName:"",
    projectDescription: "",
    projectInfo: "",
    actionSimulationId: "",
    creationDate:"",
  })
  
  const onSnackbarClose =() => {
    setOpenSnackbar(false)
  }

  const handleChange = (event, newValue) => {
    addAdvanceErrors();
    if(
      // userInputs.projectName=="" || userInputs.assessmentTime=="" || 
    userInputs.projectYear=="" || userInputs.projectYear==0){
      setMessage("Please make sure you fill all the fields before advancing")
      setOpenSnackbar(true)
    }else if((newValue=="2" || newValue=="3/1" || newValue=="3/2" || newValue=="3/3" || newValue=="4") && 
    (userInputs.typeOfSimulation==0 && listOfActions.length<1)){
      setNoActionOpenModal(true)
    // }else if((newValue=="2" || newValue=="3/1" || newValue=="3/2" || newValue=="3/3" || newValue=="4") && (listOfActions.length==1 && listOfActions.find(item => item.actionType == 'Other')) && firstTimeNextTab){
    //   setOnlyOneOtherActionModal(true)
    //   setFirstTimeNextTab(false)
    }else if((newValue=="4" || newValue=="3/1" || newValue=="3/2" || newValue=="3/3" || newValue=="1") && 
    (advanceErrors.find(error => error.errorType == "project_year_error"))){
      setMessage("Please make sure there are no errors before proceeding to the simulation")
      setOpenSnackbar(true)
    }else if(newValue=="5" && hasResult==false){
      setMessage("Please make sure to do a project simulation first before proceeding to the Save tab")
      setOpenSnackbar(true)
    // }else if(newValue=="4" && advanceErrors.find(error => error.errorType == "capex_opex_missing")){
    //   setMessage("Do not forget to fill both CAPEX and OPEX on the Project Parameters Tab before moving to the Simulation Tab")
    //   setOpenSnackbar(true)
    }else{
      setValue(newValue);
      setCleanFields(true)
    }
  };

  useEffect(()=>{
    if(listOfActions.length<=1){setFirstTimeNextTab(true)}
  },[listOfActions])

  const setProjectTab = () =>{
    if(userInputs.typeOfSimulation==1){
      return (<Tab label="Project Parameters" value="3/1" />)
    }else if(userInputs.typeOfSimulation==2){
      return (<Tab label="Project Parameters" value="3/2" />)
    }else{
      return (<Tab label="Project Parameters" value="3/3" />)
    }
  }

  const addAdvanceErrors = () => {
    let tempAdvanceErrors = [...advanceErrors]
    if(userInputs.projectYear=="" || userInputs.projectYear==0 && !(tempAdvanceErrors.find(error => error.errorType == "project_year_error"))){
      setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
        errorMessage:"Please Add a Valid Project Year",
        errorType: "project_year_error"}]);
    }
    // if((newAdditionalInputs.project_costs_estimates.capex=="" || newAdditionalInputs.project_costs_estimates.capex==0 
    // || newAdditionalInputs.project_costs_estimates.opex=="" || newAdditionalInputs.project_costs_estimates.opex==0 )
    // && !(tempAdvanceErrors.find(error => error.errorType == "capex_opex_missing"))){
    //   setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
    //     errorMessage:"Do not forget to fill both CAPEX and OPEX on the Project Parameters Tab",
    //     errorType: "capex_opex_missing"}]);
    // }
  }

  console.log("ADVANCE ERROR: ", advanceErrors);

  useEffect(()=>{
    // console.log("NEW ADDITIONAL INPUTS: ", newAdditionalInputs)
    // console.log("User Inputs: ", userInputs)
    // console.log("RETURN: ", (newAdditionalInputs.proportion_project_other_modes.total_mode_shift==0 || newAdditionalInputs.proportion_project_other_modes.total_mode_shift=="0")
    // && !(advanceErrors.find(error => error.errorType == "mode_share_error")) && userInputs.typeOfSimulation!=0)
    // console.log("RETURN 1: ", (newAdditionalInputs.proportion_project_other_modes.total_mode_shift==0 || newAdditionalInputs.proportion_project_other_modes.total_mode_shift=="0"))
    // console.log("RETURN 2: ", !(advanceErrors.find(error => error.errorType == "mode_share_error")))
    // console.log("RETURN 3: ", userInputs.typeOfSimulation!=0)
    Utils.VerifyFields(newAdditionalInputs, userInputs, advanceErrors, setAdvanceErrors, includePreProject, listOfActions)
  },[newAdditionalInputs, userInputs, advanceErrors, includePreProject, listOfActions])

  return (
    <div>
      <Box sx={{ width: "100%", marginTop: "32px" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList  onChange={handleChange} aria-label="Tabs" id="tabsLevel1">
              <Tab label="Analysis Selection" value="1" />
              <Tab label="General Parameters" value="2" />
              {setProjectTab()}



              {/* {listOfActions.find(item => item.actionType == 'Other') && listOfActions.find(item => item.area == 'Walking') && !(listOfActions.some(item => item.area == 'Cycling' && item.actionType=="Other")) 
              && <Tab label="Project Parameters" value="3/1" />}
              {listOfActions.find(item => item.actionType == 'Other') && listOfActions.find(item => item.area == 'Cycling') && !(listOfActions.some(item => item.area == 'Walking' && item.actionType=="Other")) 
              && <Tab label="Project Parameters" value="3/2" />} */}
              <Tab label="Simulation" value="4" />
              <Tab label="Save" value="5" />
            </TabList>
          </Box>
          <AnalysisSelection setlistOfActions={setListOfActions} cleanFieldsParent={cleanFields} setCleanFieldsParent={setCleanFields} setUserInputs={setUserInputs}
          userInputs={userInputs} actionList={actionList} setListOfActions={setListOfActions} listOfActions={listOfActions} setHasResult={setHasResult} advanceErrors={advanceErrors} 
          setAdvanceErrors={setAdvanceErrors} isSimulationOption={isSimulationOption} setIsSumulationOption={setIsSumulationOption}/>
          <GeneralParam cityInformation={cityInformation} advanceErrors={advanceErrors} setAdvanceErrors={setAdvanceErrors} userInputs={userInputs}
          currencyList={currencyList} selectedCurrency={selectedCurrency} setNewAdditionalInputs={setNewAdditionalInputs} newAdditionalInputs={newAdditionalInputs}
          listOfActions={listOfActions} setUserInputs={setUserInputs} yldTable={yldTable} mortalityTable={mortalityTable} populationTable={populationTable}
          singleNationalCompositionTable={singleNationalCompositionTable} setSingleNationalCompositionTable={setSingleNationalCompositionTable}
          citySpecificCompositionTable={citySpecificCompositionTable} setCitySpecificCompositionTable={setCitySpecificCompositionTable} 
          setSelectedCurrency={setSelectedCurrency} includePreProject={includePreProject}/>
          <Walking newAdditionalInputs={newAdditionalInputs} advanceErrors={advanceErrors} setAdvanceErrors={setAdvanceErrors}
          setNewAdditionalInputs={setNewAdditionalInputs} userInputs={userInputs} includePreProject={includePreProject} setIncludePreProject={setIncludePreProject} setUserInputs={setUserInputs} listOfActions={listOfActions}
          />
          <Cycling newAdditionalInputs={newAdditionalInputs} advanceErrors={advanceErrors} setAdvanceErrors={setAdvanceErrors} setUserInputs={setUserInputs}
          userInputs={userInputs} setNewAdditionalInputs={setNewAdditionalInputs} includePreProject={includePreProject} setIncludePreProject={setIncludePreProject} listOfActions={listOfActions}/>
          <Cost newAdditionalInputs={newAdditionalInputs}
          setNewAdditionalInputs={setNewAdditionalInputs} advanceErrors={advanceErrors} setAdvanceErrors={setAdvanceErrors} setUserInputs={setUserInputs}
          userInputs={userInputs}/>
          <Simulation userInputs={userInputs} setUserInputs={setUserInputs} citySpecificCompositionTable={citySpecificCompositionTable} singleNationalCompositionTable={singleNationalCompositionTable}
          newAdditionalInputs={newAdditionalInputs} listOfActions={listOfActions} selectedCurrency={selectedCurrency}  setSimulationResults={setSimulationResults} setHasResult={setHasResult} 
          hasResult={hasResult} setAdvanceErrors={setAdvanceErrors} advanceErrors={advanceErrors} includePreProject={includePreProject}/>
          <Save simulationResults={simulationResults} projectData={projectData} setProjectData={setProjectData} setListOfProjects={setListOfProjects} 
        projects={projects} setSavedProject={setSavedProject} userInputs={userInputs} setUserInputs={setUserInputs}/>
        </TabContext>
      </Box>
      {<InformationModal body="Please complete your selection before proceeding to the next tab" modalOpen={noActionOpenModal} setModalOpen={setNoActionOpenModal} 
        positiveButtonModalLabel='OK'/>}
      <ConfirmationModal modalOpen={onlyOneOtherActionModal} setModalOpen={setOnlyOneOtherActionModal} body = "A non-listed action will change only the mobility parameters, it will not be linked to the 15-minute city indicators. See the causal chain for non-listed actions"
      positiveButtonModalLabel='OK' negativeButtonModalLabel='Cancel' value={value} setValue={setValue} typeOfTab="Up"/>
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={onSnackbarClose}>
        <MuiAlert severity="warning">{message}</MuiAlert >
      </Snackbar>
    </div>
  );
}
