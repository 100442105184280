import React, { useEffect } from "react";

import {
  FormControl,
  TextField,
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  Snackbar,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import MuiAlert from '@mui/material/Alert';

import "../../css/databasePage.css";

import { useState } from "react";
import PopulationTableModal from "../basicModal/populationTableModal";
import NationalTableModal from "../basicModal/nationalTableModal";
import Utils from "../../utils/miscUtils";
import CitySpecificTableModal from "../basicModal/citySpecificTableModal";
import InformationModal from "../basicModal/informationModal";
import StyledTooltip from "../../utils/styledToolTip";
import { CITY_MODE_SHARE_TITLE, MINIMUM_AGE, MAXIMUM_AGE, VEHICLE_LOAD_FACTORS_TITLE, SOURCE_TITLE} from "../../utils/tooltipConstants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function UrbanMobility({newAdditionalInputs,setNewAdditionalInputs, advanceErrors, setAdvanceErrors, setUserInputs, userInputs, populationTable, 
  singleNationalCompositionTable, setSingleNationalCompositionTable, actionType, citySpecificCompositionTable, setCitySpecificCompositionTable, includePreProject, listOfActions}) {
  const [open, setOpen] = useState(false);
  const [openNationalModal, setOpenNationalModal] = useState(false)
  const [openCitySpecificModal, setOpenCitySpecificModal] = useState(false)
  const [modalType, setModalType] = useState(userInputs.populationCompositionMethod);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [totalCyclistPopulation,setTotalCyclistPopulation] = useState(100)
  const [totalModeShare, setTotalModeShare] = useState(newAdditionalInputs.city_mode_share.walk+
    newAdditionalInputs.city_mode_share.bicycle+
    newAdditionalInputs.city_mode_share.car_and_motor_cycle_solo+
    newAdditionalInputs.city_mode_share.car_and_motor_cycle_share+
    newAdditionalInputs.city_mode_share.taxi_or_mini_bus+
    newAdditionalInputs.city_mode_share.public_transport)

  const [openModal, setOpenModal] = useState(false);
  const [openInformationModal, setOpenInformationModal] = useState(false)
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const onSnackbarClose =() => {
    setOpenSnackbar(false)
  }

  const openCompositionModalOnClick = () => {
    switch (modalType) {
      case "National":
        showNationalModal();
        break;
      case "City-Specific":
        showCityModal();
        break;
      default:
        showNationalModal();
        break;
    }
  }

  const showNationalModal = () =>{
    if (advanceErrors.findIndex(error=>error.errorType=="min_age_error")>=0)
      {
        setOpenSnackbar(true)
      } else {
        if(!openNationalModal){
          setOpenNationalModal(true)
        }else{
          setOpenNationalModal(false)
        }
      };
  }

  const showCityModal = () =>{
    if(!openCitySpecificModal){
      setOpenCitySpecificModal(true)
    }else{
      setOpenCitySpecificModal(false)
    }
  }
  

  const [cyclistPopul, setCylistPopulation] = useState([
    { id: "1", label: "Male", helperText: "Percentage of cyclists", value:newAdditionalInputs.cyclists_population_gender.male_percentage},
    { id: "2", label: "Female", helperText: "Percentage of cyclists", value:newAdditionalInputs.cyclists_population_gender.female_percentage},
  ]);
  
  const [cityModeShare,setCityModeShare] = useState([
    { id: "1", label: "Walk", helperText: "Percentage", value:newAdditionalInputs.city_mode_share.walk},
    { id: "2", label: "Bicycle", helperText: "Percentage", value:newAdditionalInputs.city_mode_share.bicycle},
    { id: "3", label: "Car & motorcycle (ride share)", helperText: "Percentage", value:newAdditionalInputs.city_mode_share.car_and_motor_cycle_share},
    { id: "4", label: "Car & motorcycle (solo drive)", helperText: "Percentage", value:newAdditionalInputs.city_mode_share.car_and_motor_cycle_solo},
    { id: "5", label: "Taxi / minibus", helperText: "Percentage", value:newAdditionalInputs.city_mode_share.taxi_or_mini_bus},
    { id: "6", label: "Public transport (bus etc.)", helperText: "Percentage",value:newAdditionalInputs.city_mode_share.public_transport},
  
    // adicione mais objetos aqui para gerar mais TextField
  ]);
  
  const [rangeOfCyclists, setRangeOfCyclists] = useState([
    { id: "1", label: "Minimum age", helperText: "Years old", value:newAdditionalInputs.age_range_of_cyclists.min_age},
    { id: "2", label: "Maximum age", helperText: "Years old", value:newAdditionalInputs.age_range_of_cyclists.max_age},
  ]);
  const [vehicleLoad, setVehicleLoad] = useState([
    {
      id: "1",
      label: "Car & motorcycle (ride share) (*)",
      helperText: "Persons/vehicle",
      value:newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_share
    },
    {
      id: "2",
      label: "Car & motorcycle (solo driver) (*)",
      helperText: "Persons/vehicle",
      value: newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_solo
    },
    { id: "3", label: "Taxi/minibus (*)", helperText: "Persons/vehicle", 
    value:newAdditionalInputs.vehicle_load_factors.taxi_and_minibus},
    {
      id: "4",
      label: "Public transport (bus etc.) (*)",
      helperText: "Persons/vehicle",
      value:newAdditionalInputs.vehicle_load_factors.public_transport
    },
  ]);

  const handleCyclistPopulationChange = (dataLabel,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempCyclistPop = [...cyclistPopul]
    let tempAdvanceErrors = [...advanceErrors]
    switch (dataLabel) {
      case "Female":
        temp.cyclists_population_gender.female_percentage=textValue
        tempCyclistPop[1].value = textValue
        break;
      case "Male":
        temp.cyclists_population_gender.male_percentage=textValue
        tempCyclistPop[0].value = textValue
        break;
      default:
        break;
      }
      setCylistPopulation(tempCyclistPop)
      setTotalCyclistPopulation(parseFloat(temp.cyclists_population_gender.female_percentage) + 
      parseFloat(temp.cyclists_population_gender.male_percentage))

      if(parseFloat(temp.cyclists_population_gender.female_percentage) + 
        parseFloat(temp.cyclists_population_gender.male_percentage) == 100){
          tempAdvanceErrors = tempAdvanceErrors.filter(error=>error.errorType!="cyclist_population_error");
          setAdvanceErrors(tempAdvanceErrors)
      }else{
        Utils.VerifyFields(temp, userInputs, tempAdvanceErrors, setAdvanceErrors,includePreProject, listOfActions)
      }
      setNewAdditionalInputs(temp)
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  const handleAgeRangeChange = (dataLabel,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempRange = [...rangeOfCyclists]
    let tempAdvanceErrors = [...advanceErrors]
    let tempNationalTable = [...singleNationalCompositionTable]
    switch (dataLabel) {
      case "Minimum age":
        temp.age_range_of_cyclists.min_age=textValue
        tempRange[0].value=textValue
        break;
      case "Maximum age":
        temp.age_range_of_cyclists.max_age=textValue
        tempRange[1].value=textValue
        break;
      default:
        break;
      }
      setRangeOfCyclists(tempRange)

      if(parseFloat(temp.age_range_of_cyclists.min_age)>parseFloat(temp.age_range_of_cyclists.max_age)){
        if(!(tempAdvanceErrors.find(error => error.errorType == "min_age_error"))){
          setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
            errorMessage:"Minimum Age cannot be superior to Maximum age",
            errorType: "min_age_error"}]);
        }
      }else if(parseFloat(temp.age_range_of_cyclists.min_age)<=parseFloat(temp.age_range_of_cyclists.max_age) 
      && tempAdvanceErrors.findIndex(error=>error.errorType=="min_age_error")>=0){
        let tempIndex = tempAdvanceErrors.indexOf(error=>error.errorType=="min_age_error");
        tempAdvanceErrors.splice(tempIndex,1)
        setAdvanceErrors(tempAdvanceErrors)
      }
      tempNationalTable = Utils.CreateNationalCompositionTable(populationTable,temp.age_range_of_cyclists.min_age,temp.age_range_of_cyclists.max_age)
      setSingleNationalCompositionTable(tempNationalTable)
      Utils.ValidateUpperTabErrors(tempAdvanceErrors)
      setNewAdditionalInputs(temp)
  }

  const handleCompositionChange = (value) => {
    if(value=="City-Specific"){setOpenInformationModal(true)}
    setUserInputs((userInputs) => ({ ...userInputs, populationCompositionMethod: value }));
    tableReset(value);
    setModalType(value);
  };

  const tableReset = (compositionType) => {
    let tempAdvanceErrors = [... advanceErrors]
    let tempCityTable = [... citySpecificCompositionTable]
    let tempNationalTable = [... singleNationalCompositionTable]
    let tempIndex ="";
    if(compositionType=="National"){
      cleanErrorTables(tempAdvanceErrors,"over_100_percentage_error_city_table")
      Utils.ValidateNationalPercentage(tempNationalTable,tempAdvanceErrors,setAdvanceErrors)
    }else{
      cleanErrorTables(tempAdvanceErrors,"over_100_percentage_error_national_table")
      Utils.ValidateCityPercentage(tempCityTable, tempAdvanceErrors, setAdvanceErrors)
    }
  }


  const cleanErrorTables = (errorList, errorType)=>{
    if(errorList.findIndex(error=>error.errorType==errorType)>=0){
      let tempIndex = errorList.indexOf(error=>error.errorType==errorType);
      errorList.splice(tempIndex,1)
    }
  }

  const handleVehicleLoadChange = (id,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let errorName = "";
    let tempVehicle = [...vehicleLoad];
    switch (id) {
      case "1":
        temp.vehicle_load_factors.car_and_motorcycle_share=textValue
        tempVehicle[0].value=textValue;
        break;
      case "2":
        temp.vehicle_load_factors.car_and_motorcycle_solo=textValue
        tempVehicle[1].value=textValue;
        break;
      case "3":
        temp.vehicle_load_factors.taxi_and_minibus=textValue
        tempVehicle[2].value=textValue;
      break;
      case "4":
        temp.vehicle_load_factors.public_transport=textValue
        tempVehicle[3].value=textValue;
      break;
      default:
        break;
      }

      if(!(Utils.ValidateErrors(textValue, findErrorName(id), advanceErrors, setAdvanceErrors))){
        Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject, listOfActions)
      }

      setVehicleLoad(tempVehicle)
      setNewAdditionalInputs(temp)
  }

  const findErrorName = (index) => {
    let errorName = "";
    switch(index){
      case ("1"):
        errorName = "load_factor_car_moto_share_error";
        break;
      case ("2"):
        errorName = "load_factor_car_moto_solo_error";
        break;
      case("3"):
        errorName = "load_factor_taxi_minibus_error";
        break;
      case("4"):
        errorName = "load_factor_public_transport_error";
        break;
    }
    return errorName;
  }

  const handleCityModeShareChange = (id,textValue) =>{
    textValue = validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempCityModeShare = [...cityModeShare];
    switch (id) {
      case "1":
        temp.city_mode_share.walk=textValue
        tempCityModeShare[0].value = textValue
        break;
      case "2":
        temp.city_mode_share.bicycle=textValue
        tempCityModeShare[1].value = textValue
        break;
      case "3":
        temp.city_mode_share.car_and_motor_cycle_share=textValue
        tempCityModeShare[2].value = textValue
      break;
      case "4":
        temp.city_mode_share.car_and_motor_cycle_solo=textValue
        tempCityModeShare[3].value = textValue
      break;
      case "5":
        temp.city_mode_share.taxi_or_mini_bus=textValue
        tempCityModeShare[4].value = textValue
      break;
      case "6":
        temp.city_mode_share.public_transport=textValue
        tempCityModeShare[5].value = textValue
      break;
      default:
        break;
      }
      updateTotalModeShare(temp)
      setCityModeShare(tempCityModeShare)
      setNewAdditionalInputs(temp)
  }
  
  const updateTotalModeShare = (temp) =>{
    let tempAdvanceErrors = [...advanceErrors]
    let tempTotalShare = temp.city_mode_share.walk+temp.city_mode_share.bicycle+temp.city_mode_share.car_and_motor_cycle_solo+
    temp.city_mode_share.car_and_motor_cycle_share+temp.city_mode_share.taxi_or_mini_bus+temp.city_mode_share.public_transport;

    Utils.ValidateTotalPercentage(tempTotalShare, tempAdvanceErrors, setAdvanceErrors, "over_100_percentage_error_mode_share");
    // if(tempTotalShare>100){
    //   if(!(tempAdvanceErrors.find(error => error.errorType == "over_100_percentage_error"))){
    //     setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
    //       errorMessage:"The total value of all the fields cannot be superior to 100%",
    //       errorType: "over_100_percentage_error"}]);
    //   }
    // }else if(tempTotalShare<=100 && tempAdvanceErrors.findIndex(error=>error.errorType=="over_100_percentage_error")>=0){
    //   let tempIndex = tempAdvanceErrors.indexOf(error=>error.errorType=="over_100_percentage_error");
    //   tempAdvanceErrors.splice(tempIndex,1)
    //   setAdvanceErrors(tempAdvanceErrors)
    // }
    setTotalModeShare(tempTotalShare)
  }

  const handleCloseOne = () => {
    setOpen(false);
  };

  const handleOpenOne = () => {
    setOpen(true);
  };

  return (
    <TabPanel value="3" className="p-0 my-4">
      <label htmlFor="" className=" mt-4 label-default">
        City Mode Share <StyledTooltip title={CITY_MODE_SHARE_TITLE} arrow><span className="icon"></span></StyledTooltip>
      </label>
      <Grid container spacing={3} className="mt-0">
        {cityModeShare.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth variant="outlined">
              {index % 2 === 0 ? (
                <><TextField
                  id={data.id}
                  label={data.label}
                  value={data.value}
                  helperText={data.helperText}
                  onChange={(e)=>handleCityModeShareChange(data.id,e.target.value)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  aria-describedby="outlined-weight-helper-text"
                  type="number" /></>
              ) : (
                <>
                  <TextField
                    id={data.id}
                    label={data.label}
                    value={data.value}
                    onChange={(e)=>handleCityModeShareChange(data.id,e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    type="number"
                  />
                  <FormHelperText id="outlined-weight-helper-text">
                    {data.helperText}
                  </FormHelperText>
                </>
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={12} md={12} className="mt-2">
        <FormControl fullWidth>
          <TextField
            InputProps={{
              readOnly: true
            }}
            id="automatic-filling"
            label="Total"
            helperText="Total City Mode Share Percentage"
            variant="standard"
            value={totalModeShare}
            disabled={true}
            error={advanceErrors.findIndex(error=>error.errorType=="over_100_percentage_error_mode_share")>=0}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} className="mt-2">
        <FormControl fullWidth>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
              <span className="icon"></span>
            </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Override source/rationale"
            helperText="Default Source: C40: Climate Action for Urban Sustainability Database;
            Regional Proxy Mode Shares; 2016"
            variant="standard"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12} className="mt-5">
        <FormControl fullWidth>
          <InputLabel id="input-first-label" sx={{ fontWeight: "bold" }}>
            Select Population Composition Method
          </InputLabel>
          <Select
            labelId=""
            id="select-first-label"
            open={open}
            onClose={handleCloseOne}
            onOpen={handleOpenOne}
            value={modalType}
            onChange={(e)=>handleCompositionChange(e.target.value)}
            label="Select Ciclyng Population Composition Method"
          >
            <MenuItem disabled={true} value="">
              <em>Select a method</em>
            </MenuItem>
            {newAdditionalInputs.pedestrian_composition.map((option) => (
                <MenuItem key={option.pedestrian_composition_id} value={option.method_name}>
                  {option.method_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {actionType=="Cycling" && <label htmlFor="" className=" mt-5 label-default">
        Cyclist Population Composition By Gender
      </label>}
      {actionType=="Walking" && <label htmlFor="" className=" mt-5 label-default">
        Pedestrian Population Composition By Gender
      </label>}
      <Grid container spacing={3} className="mt-2">
        {cyclistPopul.map((field, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              <TextField
                id={field.id}
                label={field.label}
                helperText={field.helperText}
                value={field.value}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={(e)=>handleCyclistPopulationChange(field.label,e.target.value)}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={12} md={12} className="mt-2">
        <FormControl fullWidth>
          <TextField
            InputProps={{
              readOnly: true
            }}
            id="automatic-filling"
            label="Total"
            helperText="Percentage of cyclists"
            variant="standard"
            value={totalCyclistPopulation}
            error={advanceErrors.findIndex(error=>error.errorType=="cyclist_population_error")>=0}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} className="mt-2">
        <FormControl fullWidth>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
            <span className="icon"></span>
          </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Override source/rationale"
            helperText="Default Source: C40: Generic Assumptions"
            variant="standard"
          />
        </FormControl>
      </Grid>

      {actionType=="Cyclists" && <label htmlFor="" className=" mt-4 label-default">
        Age Range Of Cyclists To Be Included In The Analysis
      </label>}
      {actionType=="Walking" && <label htmlFor="" className=" mt-4 label-default">
        Age Range Of Pedestrians To Be Included In The Analysis
      </label>}
      <Grid container spacing={3} className="mt-4">
        {rangeOfCyclists.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                  // className="tooltip-inputbox"
                    title={
                      data.id === "1"
                        ? MINIMUM_AGE
                        : MAXIMUM_AGE
                    }
                    arrow
                    // style={{
                    //   alignSelf: "end",
                    //   marginBottom: "5px",
                    // }}
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
              <TextField
                id="outlined-number"
                label={data.label}
                helperText={data.helperText}
                value={data.value}
                error={advanceErrors.findIndex(error=>error.errorType=="min_age_error")>=0}
                type="number"
                onChange={(e)=>handleAgeRangeChange(data.label,e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
      {actionType=="Cycling" && <Button onClick={openCompositionModalOnClick} className="default_modal">
          Cyclist Population Composition By Age Cohort
        </Button>}
      {actionType=="Walking" && <Button onClick={openCompositionModalOnClick} className="default_modal">
          Pedestrian Population Composition By Age Cohort
      </Button>}
      </div>
      <label htmlFor="" className=" mt-4 label-default">
        Vehicle Load Factors <StyledTooltip title={VEHICLE_LOAD_FACTORS_TITLE} arrow><span className="icon"></span></StyledTooltip>
      </label>
      <Grid container spacing={3} className="mt-4">
        {vehicleLoad.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              <TextField
                id="outlined-number"
                label={data.label}
                helperText={data.helperText}
                value={data.value}
                error={advanceErrors.findIndex(error=>error.errorType==findErrorName(data.id))>=0}
                onChange={(e)=>handleVehicleLoadChange(data.id,e.target.value)}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      {<NationalTableModal title="National Composition By Age Cohort" modalOpen={openNationalModal} setModalOpen={setOpenNationalModal} 
        table={singleNationalCompositionTable} actionType={actionType} setTable={setSingleNationalCompositionTable} advanceErrors={advanceErrors} 
        setAdvanceErrors={setAdvanceErrors}/>}
      {<CitySpecificTableModal title="City-Specific Composition By Age Cohort" modalOpen={openCitySpecificModal} setModalOpen={setOpenCitySpecificModal} 
      table={citySpecificCompositionTable} actionType={actionType} setTable={setCitySpecificCompositionTable} advanceErrors={advanceErrors} 
      setAdvanceErrors={setAdvanceErrors}/>}
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={onSnackbarClose}>
          <MuiAlert severity="warning">Minimum Age cannot be superior to Maximum age</MuiAlert >
      </Snackbar>
      {<InformationModal body="You will need to fill all the information on the Pedestrian/Cyclist Composition By Age Cohort in order to proceed" modalOpen={openInformationModal} 
      setModalOpen={setOpenInformationModal} 
        positiveButtonModalLabel='OK'/>}
    </TabPanel>
  );
}
