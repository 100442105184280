import axios from "axios";

const URLs = {
  cities: "/api/region/get-cities-map", 
  city: "/api/region/get-city-map", 
  indicators: "/api/indicators/get-all-indicators",  
  neighborhoods: "/api/region/get-city-neighborhood-polygon",
  neighborhoodGrid:"/api/region/get-neighborhood-large-grid-score-map",
  neighborhoodGridLevel2:"/api/region/get-neighborhood-small-grid-score-map",
  citiesScores: "/api/region/get-city-scores",
  actions: "/api/region/get-cities-action",
  projects:"/api/project/load-projects",
  deleteProject:"api/project/delete-project",
  additionalInputs:"api/inputs/get-default-inputs",  
  currency:"api/region/get-country-currency",
  yld:"api/region/get-support-tables",
  simulateProject:"api/project/simulate-project",
  saveProject:"api/project/save-project"
}

/* Get City Scores
  parameter: stage=BEFORE, AFTER or DIFFERENCE
*/
const GetCitiesScores = async (stage = "BEFORE", simulationId = "") => {
  let additionalParams = "?projectStage=" + stage;
  if (stage === "AFTER") 
    additionalParams = "?projectStage=AFTER" //&simulationId="+simulationId;
  else if (stage === "DIFFERENCE")
    additionalParams = "?projectStage=DIFFERENCE" //&simulationId="+simulationId;

  try {
    return axios.get(URLs.citiesScores + additionalParams)
      .then((response) => {
        //console.log("NeighborHoods = ", response)
        return response;
      });    
  } catch (err) {
    console.log("Got City scores error=", err);
  }
};

/* Get City Neighborhoods */
const GetCityNeighborhoods = async (cityId, stage = "", simulationId = "") => {
  try {
    //console.log("get City Neighborhoods for Stage=",stage);
    if (stage === "AFTER") 
      stage = "&projectStage=AFTER";
    else 
      if (stage === "DIFFERENCE") stage = "&projectStage=DIFFERENCE";
      else
        stage = "";

    return axios.get(URLs.neighborhoods+"?cityId="+cityId+stage+"&simulationId="+simulationId)
      .then((response) => {
        //console.log("NeighborHoods = ", response)
        return response;
      });    
  } catch (err) {
    console.log("Got NeighborHoods Error=", err);
  }
};

/* Get City Neighborhood Grid */
const GetCityGrid = async (cityId, stage = "", simulationId="") => {
  //console.log("get City large Grid for Stage=",stage);
  if (stage === "AFTER") 
    stage = "&projectStage=AFTER";
  else 
    if (stage === "DIFFERENCE") stage = "&projectStage=DIFFERENCE";
    else
      stage = "";

  try {
    return axios.get(URLs.neighborhoodGrid+"?cityId="+cityId+stage+"&simulationId="+simulationId)
      .then((response) => {
        //console.log("NeighborHoods = ", response)
        return response;
      });    
  } catch (err) {
    console.log("Got NeighborHood Grid Error=", err);
  }
};

/* Get City Neighborhood Grid Level 2*/
const GetCityGrid2 = async (id, stage = "") => {
  //console.log("get City small Grid for Stage =",stage);
  if (stage === "AFTER") 
    stage = "&projectStage=AFTER";
  else 
    if (stage === "DIFFERENCE") stage = "&projectStage=DIFFERENCE";
    else
      stage = "";

  try {
    return axios.get(URLs.neighborhoodGridLevel2+"?neighborhoodId="+id+stage)
      .then((response) => {
        //console.log("NeighborHoods = ", response)
        return response;
      });    
  } catch (err) {
    console.log("Got NeighborHood Grid Level 2 Error=", err);
  }
};

/* Get Indicators */
const GetIndicators = async () => {
  try {
    return axios.get(URLs.indicators)
      .then((response) => {
        //console.log("Indicators=", response)
        return response;
      });
    
  } catch (err) {
    console.log("Got Indicators Error=", err);
  }
};

/* Get Cities */
const GetCitiesToJson = () => {
  //const [data, setData] = useState();
  //useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(URLs.cities);
        localStorage.setItem("cities", JSON.stringify(response.data));
        //setData(response.data);        
        //setError(null);
      } catch (err) {
        //setError(err.message);
        //setData(null);
      } finally {
        //setLoading(false);
      }
    };
    getData();
  //}, []);
}

/**
 * Get Cities
 */
const GetCities = async () => {
  try {
    const response = await axios.get(URLs.cities,);
    localStorage.setItem("cities", JSON.stringify(response.data));
    return response.data;
  } catch (err) {
    console.log("Got Cities Error=",err);
  }
};

/**
 * Get one City data
 */
const GetCity = async (cityId) => {
  try {
    return axios.get(URLs.city+"?cityId="+cityId,)
      .then((response) => {
        //console.log("Response=", response)
        return response;
      });
    
  } catch (err) {
    console.log("Get City Error=", err);
  }
};

const GetActions = async () => {
  try {
    return axios.get(URLs.actions)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Get Actions Error=", err);
  }
};

const GetUserProjects = async () => {
  try {
    return axios.get(URLs.projects)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Get Projects Error=", err);
  }
};

const DeleteUserProject = async (projectNumber) => {
  try {
    return axios.delete(URLs.deleteProject+"?projectNumber="+projectNumber)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Got Projects Error=", err);
  }
};

const getAdditionalInputs = async (regionName, countryName) => {
  try {
    return axios.get(URLs.additionalInputs+"?regionName="+regionName+"&countryName="+countryName)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Got Projects Error=", err);
  }
};

const getYLD = async (countryName) => {
  try {
    return axios.get(URLs.yld+"?countryName="+countryName)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Got Projects Error=", err);
  }
};

const SimulateUserProject = async (userInputs) => {
  try {
    return axios.post(URLs.simulateProject,userInputs)
      .then((response) => {
        return response;
      });
    
  } catch (err) {
    console.log("Got Projects Error=", err);
  }
};

  const SaveUserProject = async (projectData) => {
    try {
      return axios.post(URLs.saveProject,projectData)
        .then((response) => {
          return response;
        });
      
    } catch (err) {
      console.log("Got Projects Error=", err);
    }
  };

const DataService = {
  GetCities, /*GetCitiesToJson,*/ GetCity, GetCityGrid, GetCityGrid2, GetActions, GetIndicators, GetUserProjects, 
  GetCityNeighborhoods, DeleteUserProject, getAdditionalInputs, getYLD, GetCitiesScores, SimulateUserProject, SaveUserProject
}

export default DataService;
