import React, { useState, useEffect  } from 'react';
import { Button, FormControl, MenuItem, Typography, Grid, Select as SelectCity } from '@mui/material';
import { Circle as CircleStyle, Fill, Style } from 'ol/style.js';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Select from 'ol/interaction/Select.js';
import DataService from '../services/getData';
import { fromLonLat } from "ol/proj";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';
import MapUtils from '../utils/mapUtils';
import BarChart from "../components/chart/chart"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip from '@mui/material/Tooltip';
import '../css/home.min.css'
import GeoJSON from "ol/format/GeoJSON.js";

function HomePage() {
	//const [hideOverlay, setHideOverlay] = useState(false);
	var scoreRangeValues = { max: 0, min: 0 };
	const [indicators, setIndicators] = useState([{}]);
	const [indicatorsOptions, setIndicatorsOptions] = useState("");
	const [chosenIndicator, setChosenIndicator] = useState({ id: "", title: "", description: "" });
	const [selectedCityId, setSelectedCityId] = useState("");
	const [cities, setCities] = useState([{ "cityId": "default", "originalCityName": "" }]);
	var [map, setMap] = useState();
	const [headerText, setHeaderText] = useState("");
	const [ranking, setRanking] = useState([]);
	const [chartData, setChartData] = useState({ labels: [], datasets: [{ label: "", data: [], backgroundColor: "" }] });
	const [selectedCityPolygons, setSelectedCityPolygons] = useState({});
	const [selectedNeighborhoodId, setSelectedNeighborhoodId] = useState("");
	const [loadedGrid, setLoadedGrid] = useState(false);
	const [regionOrCityName, setRegionOrCityName] = useState("area displayed");

	const hideOverlay = () => {
		setCities(MapUtils.sortCitiesByOriginalCityName(JSON.parse(localStorage.cities)));
		document.getElementById("overlay").classList.add("hide");
		LoadMapData(map);
	}

	//When there is a style reset for all features, except the one selected
	const clearAll = (keepSelected = false) => {
		map.getAllLayers()[1].getSource().getFeatures().forEach(feature => {
			if (selectedCityId === feature.id_ && keepSelected) {
				//console.log('Console log=', newStyle);
				/*if (map.getView().getZoom() > 8) 
					feature.setStyle(MapUtils.Styles.invisible)
				else*/
				feature.setStyle(MapUtils.Styles.selectStyle)
			} else
				feature.setStyle(MapUtils.Styles.normal);
			feature.set("score", "");
			feature._state = 'update';
		})
	}

	const clickedNeighborhood = (neighborhood) => {
		map.getAllLayers()[2].getSource().getFeatures().forEach(feature => {
			feature.setStyle(MapUtils.Styles.neighborhood);
		});

		if (neighborhood === "") { // || neighborhood.id_.length < 20) {
			setSelectedNeighborhoodId("");
			setSelectedCityPolygons("");
			return;
		}
		//console.log("NeighborHood clicked=", neighborhood);
		neighborhood.setStyle(MapUtils.Styles.selectedNeighborhood)
		setSelectedNeighborhoodId(neighborhood.id_);
	}

	useEffect(() => {
		if (selectedNeighborhoodId === "") {
			if (map !== undefined) {
				if (map.getAllLayers()[3] !== undefined) {
					map.getAllLayers()[3].setMaxZoom(Infinity);
				}
				if (map.getAllLayers()[4] !== undefined) map.removeLayer(map.getAllLayers()[4]);
			}
			const currentSelectedCityId = document.getElementById("select-city").nextSibling.value;
			if (currentSelectedCityId === "")
				return;
			const feature = map.getAllLayers()[1].getSource().getFeatureById(currentSelectedCityId);
			setHeaderText(feature.values_.city + ", " + feature.values_.country);
			return;
		}

		//if (map !== undefined) if(map.getAllLayers()[3] !== undefined) map.getAllLayers()[3].setMaxZoom(15)
		const neighborhoodName = map.getAllLayers()[2].getSource().getFeatureById(selectedNeighborhoodId).values_.name; //sortedFeatures.filter(item => item.id_ == selectedNeighborhoodId);
		const cityName = map.getAllLayers()[1].getSource().getFeatureById(selectedCityId).values_.name;
		setHeaderText(neighborhoodName + ", " + cityName);
		updateForNeighborhoods();
		//addCityGridSmall();
	}, [selectedNeighborhoodId])

	const updateForNeighborhoods = () => {
		//let sortedFeatures = MapUtils.sortCitiesByName(map.getAllLayers()[2].getSource().getFeatures());
		let sortedFeatures = map.getAllLayers()[2].getSource().getFeatures();
	
		sortedFeatures = MapUtils.sortFeaturesByScore(sortedFeatures);
		setRanking(sortedFeatures);		
		updateChartDataForNeighborhood(sortedFeatures);
		/*if (selectedNeighborhoodId != "") {
			addCityGridSmall();
		}*/
	}

	const updateChartDataForNeighborhood = (sortedFeatures) => {
		if (sortedFeatures === undefined) return;
		const total = 10;
		const currentIndex = sortedFeatures.findIndex(function (item, i) { return item.id_ === selectedNeighborhoodId });

		//console.log("CurrentIndex= ", currentIndex +"  SelectedCityID=", currentSelectedCityId);
		let firstPos = currentIndex - Math.floor((total - 1) / 2);
		let totalBars = sortedFeatures.length > total ? total : sortedFeatures.length;
		let labels = [], values = [], colors = [];

		if (currentIndex < Math.floor((total - 1) / 2) || sortedFeatures.length < total)
			firstPos = 0;
		else if (currentIndex >= sortedFeatures.length - Math.floor((total - 1) / 2))
			firstPos = sortedFeatures.length - total;

		for (var x = firstPos; x < (firstPos + totalBars) && (x < sortedFeatures.length); x++) {
			labels.push((x === currentIndex ? " " : "") + sortedFeatures[x].values_.name);
			values.push(sortedFeatures[x].values_.score);
			colors.push(sortedFeatures[x].get("colorForChart"));
		}

		if (totalBars > 2) {
			values[0] = sortedFeatures[0].values_.score
			labels[0] = (selectedNeighborhoodId === sortedFeatures[0].id_ ? " " : "") + sortedFeatures[0].values_.name
			colors[0] = sortedFeatures[0].get("colorForChart")
			values[values.length - 1] = sortedFeatures[sortedFeatures.length - 1].values_.score
			labels[values.length - 1] = (selectedNeighborhoodId === sortedFeatures[sortedFeatures.length - 1].id_ ? " " : "") + sortedFeatures[sortedFeatures.length - 1].values_.name
			colors[values.length - 1] = sortedFeatures[sortedFeatures.length - 1].get("colorForChart")
		}

		setChartData({
			labels: labels,
			datasets: [{
				label: "Neighborhood Score",
				data: values,
				backgroundColor: colors
			}]
		});
	}

	//Handle Changes
	const handleChange = (event) => {
		var id = event.target === undefined ? event : event.target.value;
		const currentSelectedCityId = document.getElementById("select-city").nextSibling.value;
		const currentSelectedIndicatorId = document.getElementById("indicator-select").value;
		if (currentSelectedCityId !== "") {
			const currentSelectedFeature = map.getLayers().array_[1].getSource().getFeatureById(currentSelectedCityId);
			if (currentSelectedIndicatorId === "") {
				currentSelectedFeature.setStyle(null)
			} else {
				var normalStyle = currentSelectedFeature.getStyle();
				currentSelectedFeature.setStyle(MapUtils.GetCityCustomStyle(normalStyle.getImage().getFill().getColor()), 1);
				//currentSelectedFeature.setStyle(getFeatureStyleForScore(currentSelectedFeature, MapUtils.Strokes.normalStroke, scoreRangeValues.max));
			}
			currentSelectedFeature._state = 'update';
		}
		if (id === "") {
			setChosenIndicator({ id: "", title: "", description: "" });
			setSelectedCityId("");
			setHeaderText("");
			clearAll();
		} else {
			var feature = map.getLayers().array_[1].getSource().getFeatureById(id);
			let newStyle = MapUtils.Styles.selectStyle;
			if (currentSelectedIndicatorId !== "") {
				newStyle = MapUtils.GetCityCustomStyle(feature.getStyle().getImage().getFill().getColor(), 4, "#23BCED")
			}
			newStyle.setZIndex(100);
			feature.setStyle(newStyle);
			feature._state = 'update';
			setHeaderText(feature.values_.city + ", " + feature.values_.country);
			setSelectedCityId(id);
		}
	}

	const handleIndicatorChange = (event) => {
		//console.log("Chosen Indicator=", event.target.value);
		if (event.target.value === "") {
			console.log('Clear Cities=', event.target.value);
			clearAll(true);
			setChosenIndicator({ id: "", title: "", description: "" });
			return;
		}
		var chosen = indicators.find(item => {
			return item.iid === event.target.value;
		})
		setChosenIndicator({ id: chosen.iid, title: chosen.in, description: chosen.idesc });
	}

	const loadIndicators = () => {
		let livabilityIndicator = {};
		DataService.GetIndicators().then(e => {
			var data = e.data.sort((a, b) => {
				if (a.ign > b.ign) { //ign = indicatorGroupName
					return -1;
				}
			});
			livabilityIndicator = data.find(element => element.iid === "iid11");
			const temp = orderIndicatorException(data, livabilityIndicator)
			setIndicators(temp);
		});
	}

	const orderIndicatorException = (array, element) => {
		let tempArray = array.filter((indicator) => indicator.iid !== "iid11")
		tempArray.splice(9, 0, element);
		return tempArray
	}

	useEffect(() => {
		var thisOptions = ""
		var prevOption = ""
		indicators.forEach(function (value, key) {
			if (prevOption !== value.ign) {
				prevOption = value.ign
				if (key > 0) thisOptions += "</optgroup>"
				thisOptions += '<optgroup label="' + value.ign + '">';
				thisOptions += '<option value="' + value.iid + '">' + value.in + '</option>'
			} else
				thisOptions += '<option value="' + value.iid + '">' + value.in + '</option>'
		});
		thisOptions += '</optgroup>';
		setIndicatorsOptions(parse(thisOptions));
	}, [indicators]);

	const getFeatureStyleForScore = (elem, newStroke = "") => {		
		let newFillColor = new Fill({ color: "white" });
		if (elem.values_.score !== "")
			newFillColor = new Fill({ color: MapUtils.GetColorForScore(elem.values_.score, scoreRangeValues) }); //MapUtils.ColorLevel()[elem.values_.score] });
		const selectedFeatureId = document.getElementById("select-city").nextSibling.value;
		let featureStroke = (elem.id_ === selectedFeatureId) ? MapUtils.Strokes.selectedStroke : MapUtils.Strokes.normalStroke
		if (newStroke !== "") featureStroke = newStroke;
		const newStyle = new Style({
			image: new CircleStyle({
				radius: 10,
				fill: newFillColor,
				stroke: featureStroke
			}),
			zIndex: elem.id_ === selectedCityId ? 100 : 1
		});
		return newStyle;
	}

	const updateChartData = (sortedFeatures) => {
		const indicatorValue = document.getElementById("indicator-select").value;
		if (sortedFeatures === undefined || indicatorValue === "") return;
		const total = 10;
		//if (sortedFeatures <= total) return;
		const currentSelectedCityId = document.getElementById("select-city").nextSibling.value;
		const currentIndex = sortedFeatures.findIndex(function (item, i) { return item.id_ === currentSelectedCityId });

		//console.log("CurrentIndex= ", currentIndex +"  SelectedCityID=", currentSelectedCityId);
		let firstPos = currentIndex - Math.floor((total - 1) / 2);
		let totalBars = sortedFeatures.length > total ? total : sortedFeatures.length;
		let labels = [], values = [], colors = [];

		if (currentIndex < Math.floor((total - 1) / 2) || sortedFeatures.length < total)
			firstPos = 0;
		else if (currentIndex >= sortedFeatures.length - Math.floor((total - 1) / 2))
			firstPos = sortedFeatures.length - total;

		for (var x = firstPos; x < (firstPos + totalBars) && (x < sortedFeatures.length); x++) {
			labels.push((x === currentIndex ? " " : "") + sortedFeatures[x].values_.city);
			values.push(sortedFeatures[x].values_.score);
			colors.push(sortedFeatures[x].getStyle().image_.fill_.color_)
		}

		if (totalBars > 2) {
			values[0] = sortedFeatures[0].values_.score
			labels[0] = (currentSelectedCityId === sortedFeatures[0].id_ ? " " : "") + sortedFeatures[0].values_.city
			colors[0] = sortedFeatures[0].getStyle().image_.fill_.color_
			values[values.length - 1] = sortedFeatures[sortedFeatures.length - 1].values_.score
			labels[values.length - 1] = (currentSelectedCityId === sortedFeatures[sortedFeatures.length - 1].id_ ? " " : "") + sortedFeatures[sortedFeatures.length - 1].values_.city
			colors[values.length - 1] = sortedFeatures[sortedFeatures.length - 1].getStyle().image_.fill_.color_
		}

		setChartData({
			labels: labels,
			datasets: [{
				label: "City Score",
				data: values,
				backgroundColor: colors
			}]
		});
	}

	const addCityGrid = () => {
		DataService.GetCityGrid(selectedCityId).then(cityGrid => {
			let gridFeature;
			let gridFeatures = [];
			let x = 0;
			cityGrid.data.forEach(grid => {
				gridFeature = MapUtils.getFeaturePolygon(grid.geom);
				gridFeature.setId("largeGrid-" + x++); //(grid.neighborhoodId);
				gridFeature.set("name", grid.name);
				gridFeature.set("scores", MapUtils.SetScores(grid));
				gridFeatures.push(gridFeature)
			})

			const gridLayer = new VectorLayer({
				source: new VectorSource({ features: gridFeatures }),
				//maxZoom: 15,
				minZoom: 8,
				opacity: 0.5
			});
			if (map.getAllLayers()[3] !== undefined) map.removeLayer(map.getAllLayers()[3]);
			gridLayer.setZIndex(50);
			map.addLayer(gridLayer);

			const extent = gridLayer.getSource().getExtent();
			console.log("New Fit Grid Layer into view. Extent?", extent);
			map.getView().fit(extent, { duration: 1000 });

			setLoadedGrid(true);
		});
	}

	const addCityGridSmall = () => {
		DataService.GetCityGrid2(selectedNeighborhoodId).then(cityGrid => {
			let gridFeature;
			let gridFeatures2 = [];
			let x = 0;
			cityGrid.data.forEach(grid => {
				//console.log("Grid 2 features size=", gridFeatures.length)
				gridFeature = MapUtils.getFeaturePolygon(grid.geom);
				gridFeature.setId("smallGrid-" + x++);
				gridFeature.set("name", grid.name);
				gridFeature.set("scores", MapUtils.SetScores(grid));
				gridFeatures2.push(gridFeature)
			})
			const smallGridLayer = new VectorLayer({
				source: new VectorSource({ features: gridFeatures2 }),
				minZoom: 15,
				opacity: 0.7
			});
			if (map.getAllLayers()[4] !== undefined) map.removeLayer(map.getAllLayers()[4]);
			//console.log("Going to add small grid layer!");
			smallGridLayer.setZIndex(40);
			map.addLayer(smallGridLayer);
			getTranslatedScoreForFeature(map.getAllLayers()[4].getSource().getFeatures(), true);
		});
	}

	useEffect(() => {
		//console.log("Has Selected City=", selectedCityId + " Features=", map.getLayers().array_[1].getSource().getFeatures());
		setLoadedGrid(false);
		if (selectedCityId !== "") {
			DataService.GetCityNeighborhoods(selectedCityId).then(neighborhoods => {
				setSelectedCityPolygons(neighborhoods);
			});

			//const selectedFeature = map.getAllLayers()[1].getSource().getFeatureById(selectedCityId);			
			/*selectedFeature.on('change', function(evt) {
				//console.log("Feature properties have changed-", evt);*/
			if (map.getView().getZoom() <= 8) {
				updateChartData(GetVisibleFeatures());
			} else {				
				updateForNeighborhoods();
			}
			//}, this);*/
		}
	}, [selectedCityId])

	const LoadMapData = (map) => {
		//console.log("Load Map Data!")
		MapUtils.LoadMapCities(map);

		// select interaction working on "click"
		const selectClick = new Select({
			//condition: click,
			style: null // MapUtils.Styles.selectStyle
		});

		selectClick.on("select", (event) => {
			if (event.selected.length > 0) {
				if (event.selected[0].getProperties().type === 'Polygon') {
					// workaround for incorrect neighborhood geometry
					if (event.selected[0].id_.includes("largeGrid-")) return;
					clickedNeighborhood(event.selected[0]);
					//const currentSelectedCityId = document.getElementById("select-city").nextSibling.value;
					//map.getLayers().array_[1].getSource().getFeatureById(currentSelectedCityId).setStyle(MapUtils.Styles.invisible);
				} else {
					handleChange(event.selected[0].id_);
				}
			} else {
				if (map.getView().getZoom() <= 8)
					handleChange("")
				else
					clickedNeighborhood("");
			}
		});
		map.addInteraction(selectClick);

		map.on('moveend', function (e) {
			//console.log("Move ended, level=", map.getView().getZoom());
			if (map.getView().getZoom() <= 8) {
				setRegionOrCityName("area displayed");
				updateChartData(GetVisibleFeatures());
			} else
				setRegionOrCityName(document.getElementById("select-city").innerHTML);
				//updateForNeighborhoods();
		});

		MapUtils.LoadTooltipOverlay(map);
	}

	useEffect(() => {
		if (selectedCityId !== "" && chosenIndicator.id === "") {
			//console.log("Adjust Features to View");
			setTimeout(function () {
				map.updateSize();
				const vectorSource = map.getLayers().array_[1].getSource();
				map.getView().fit(vectorSource.getExtent());
				map.getView().setZoom(map.getView().getZoom() - 0.2);
				/*map.getView().animate({zoom: map.getView().getZoom() - 0.1, duration: 300});*/
			}, 400);
		} else if (selectedCityId === "" && chosenIndicator.id !== "") {
			//console.log("Should clear chosen Indicator");
			setChosenIndicator({ id: "", title: "", description: "" });
		}
	}, [selectedCityId]);

	//const checkScoreForNeighborHoods = () => {
	useEffect(() => {
		console.log("Check Scores for city! Loaded Grid=", loadedGrid + " ChosenIndicator=", chosenIndicator.id);
		if (loadedGrid && chosenIndicator.id === "") {
			resetScoresForNeighborhoods();
			resetStyleForGrid();
			// No need to load grid2 or smaller grid anymore
			//resetStyleForGrid2(); 
		}
		if (!loadedGrid || chosenIndicator.id === "") return;
		//console.log("Update Chart data!");
		getTranslatedScoreForFeature(map.getAllLayers()[2].getSource().getFeatures());
		getTranslatedScoreForFeature(map.getAllLayers()[3].getSource().getFeatures(), true);
	}, [loadedGrid, chosenIndicator]);

	useEffect(() => {
		console.log("ChosenIndicator!", chosenIndicator.id + "neighborhood=" + selectedNeighborhoodId);

		// Reset neighborhood
		if (map === undefined || chosenIndicator.id === "") return;

		//applyScoreToCities();
		const features = map.getLayers().array_[1].getSource().getFeatures();
		const iid = document.getElementById("indicator-select").value;
		features.forEach(elem => {
			//elem.set("score", MapUtils.TranslatedScoreForIndicator(elem));
			elem.set("score", elem.values_.scores[iid]);
		});

		//Get Max and Min values for City Scores
		scoreRangeValues = MapUtils.GetMaxMin(features, "score");

		features.map(feature => {			
			feature.setStyle(getFeatureStyleForScore(feature, "", scoreRangeValues.max));
			feature._state = 'update';
		})

		if (map.getView().getZoom() <= 8) {
			updateChartData(GetVisibleFeatures());
		} else {
			//CheckNeigborhoods();
			console.log("selectedNeighborhoodId=", selectedNeighborhoodId);
			//updateChartDataForNeighborhood();
			updateForNeighborhoods();
			//clickedNeighborhood(selectedNeighborhoodId);
		}
	}, [chosenIndicator]);

	/*const CheckNeigborhoods = () => {
		//console.log("Display Poygon Zoom level=", map.getView().getZoom())
		const currentSelectedCityId = document.getElementById("select-city").nextSibling.value;
		let currentSelectedFeature = map.getLayers().array_[1].getSource().getFeatureById(currentSelectedCityId);
		if (map.getView().getZoom() > 8 && map.getView().getZoom() < 12 && currentSelectedCityId !== "") {
			//currentSelectedFeature.setStyle(MapUtils.Styles.invisible);				
			currentSelectedFeature.setProperties({hide: true});
		} else if (map.getView().getZoom() <= 8 && currentSelectedCityId !== "" && currentSelectedFeature.getProperties().hide) {
			//currentSelectedFeature.setStyle(MapUtils.Styles.selectedCity);
			currentSelectedFeature.setProperties({hide: false});
			//currentSelectedFeature.setStyle(getFeatureStyleForScore(currentSelectedFeature));
		}
	}*/

	useEffect(() => {
		setSelectedNeighborhoodId("");
		if (Object.keys(selectedCityPolygons).length === 0) return;

		if (map.getAllLayers()[2] !== undefined) {
			if (map.getAllLayers()[3] !== undefined) map.removeLayer(map.getAllLayers()[3]);
			//if (map.getAllLayers()[4] !== undefined) map.removeLayer(map.getAllLayers()[4]);
			map.removeLayer(map.getAllLayers()[2]);
		}

		const neighborhoodSource = new VectorSource();		
		let neighborhoodLayer = new VectorLayer({
			//source: neighborhoodSource(),
			minZoom: 8			
		});		
		map.addLayer(neighborhoodLayer);		
		//neighborhoodLayer.on('postrender', map.getView().fit(neighborhoodLayer.getSource().getExtent(), { duration: 1000 }));

		let neigborhoodFeature;
		var neigborhoodFeatures = [];
		selectedCityPolygons.data.forEach(neighborhood => {
			//console.log("Neighborhood", neighborhood.name);		
			neigborhoodFeature = MapUtils.getFeaturePolygon(neighborhood.geom);
			neigborhoodFeature.setId(neighborhood.i);
			neigborhoodFeature.set("name", neighborhood.name);
			neigborhoodFeature.set("scores", MapUtils.SetScores(neighborhood));
			neigborhoodFeatures.push(neigborhoodFeature);
		});
		neighborhoodSource.addFeatures(neigborhoodFeatures);
		neighborhoodLayer.setSource(neighborhoodSource);
		neighborhoodLayer.setZIndex(100);

		//const extent = neighborhoodSource.getExtent();
		//console.log("New Fit neighbohoords into view. Extent?", extent);
		//map.getView().fit(extent, { duration: 1000 });

		if (selectedCityId === "" || neigborhoodFeatures.length === 0) return;
		addCityGrid();

		if (chosenIndicator.id !== "") {
			getTranslatedScoreForFeature(neighborhoodLayer.getSource().getFeatures(), true);
		}
		
	}, [selectedCityPolygons]);

	const resetScoresForNeighborhoods = () => {
		if (map.getAllLayers().length === 1) return;
		map.getAllLayers()[2].getSource().getFeatures().forEach(feature => {			
			feature.set("score", "");
		});
	}

	const resetStyleForGrid = () => {
		//if (map.getAllLayers().length == 3) return;
		map.getAllLayers()[3].getSource().getFeatures().forEach(feature => {			
			feature.set("score", "");
			feature.setStyle(MapUtils.Styles.polygonStyle);
		});
	}

	/*const resetStyleForGrid2 = () => {
		if (map.getAllLayers().length === 4) return;
		map.getAllLayers()[4].getSource().getFeatures().forEach(feature => {
			//console.log("Feature Scores", neighborhood.getProperties("scores"));
			feature.set("score", "");
			feature.setStyle(MapUtils.Styles.polygonStyle);
		});
	}*/

	const getTranslatedScoreForFeature = (features, usingGrid = false) => {
		const iid = document.getElementById("indicator-select").value;
		features.forEach(feature => {
			//console.log("Feature Scores", neighborhood.getProperties("scores"));
			//feature.set("score", MapUtils.TranslatedScoreForIndicator(feature));
			feature.set("score", feature.values_.scores[iid]);
		});

		//Get Max and Min values for City Scores
		let featureRangeValues = { max: 0, min: 0 };
		featureRangeValues = MapUtils.GetMaxMin(features, "score");
		//console.log("Feature Max, min=", featureRangeValues);

		features.forEach(feature => {
			setStyleForGrid(feature, usingGrid, featureRangeValues);
		});
	}

	const setStyleForGrid = (feature, usingGrid, featureRangeValues) => {
		if (usingGrid) // Style for Grid
			feature.setStyle(MapUtils.GetStyleForScore(feature.values_.score, featureRangeValues));
		else {
			feature.set("colorForChart", MapUtils.GetColorForScore(feature.values_.score, featureRangeValues))
			if (feature.id_ === selectedNeighborhoodId)
				feature.setStyle(MapUtils.Styles.selectedNeighborhood);
			else
				feature.setStyle(MapUtils.Styles.neighborhood);
		}
	}

	const GetVisibleFeatures = () => {
		const cityId = document.getElementById("select-city").nextSibling.value;
		const indicatorId = document.getElementById("indicator-select").value;
		if (cityId === "" || indicatorId === "")
			return;

		let visible = [];
		let extent = map.getView().calculateExtent(map.getSize());
		const source = map.getAllLayers()[1].getSource();

		source.forEachFeatureInExtent(extent, function (feature) {
			visible.push(feature);
		});
		visible = MapUtils.sortCitiesByName(visible);
		const sortedFeatures = MapUtils.sortFeaturesByScore(visible);
		setRanking(sortedFeatures);
		return sortedFeatures;
	}

	useEffect(() => {
		map = new Map({
			target: "map",
			layers: [
				new TileLayer({
					source: new OSM()
				}),
				//vectorLayer
			],
			view: new View({
				center: fromLonLat([12, 24]),
				zoom: 0
			})
		});

		loadIndicators();
		setMap(map);
	}, []);

	const exportData = () => {
		//const format = new GeoJSON({ featureProjection: 'EPSG:3857' });
		const mapZoomLevel = map.getView().getZoom();
		let source
		if (mapZoomLevel < 8)
			source = map.getAllLayers()[1].getSource()
		else //(mapZoomLevel >= 8) // && mapZoomLevel <= 15)
			source = map.getAllLayers()[3].getSource(); //.getFeatures();
		//else if (mapZoomLevel > 15)
		//	source = map.getAllLayers()[4].getSource().getFeatures();

		//const myFeatures = source.getFeatures();		
		var extent = map.getView().calculateExtent(map.getSize()); //fit(source.getExtent());

		var visibleFeatures = [];
		source.forEachFeatureInExtent(extent, function (feature) {
			visibleFeatures.push(feature);
		});

		const JSONToFile = (obj, filename) => {
			const blob = new Blob([JSON.stringify(obj, null, 2)], {
			  type: 'application/json',
			});
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${filename}.json`;
			a.click();
			URL.revokeObjectURL(url);
		};
		  
		JSONToFile(visibleFeatures, 'data');		
	};

	return (
		<div>
			<div id="overlay" className="overlay overflow-auto">
				<div className="tool-intro">
					<div className="tool-text">
							<h1>Healthy Neighbourhood Explorer</h1>
						<p>
							Use this tool to explore how C40 cities and neighbourhoods <b>rank</b> on different Healthy Neighbourhood Explorer
							city indicators and <b>design</b> your own urban planning actions to improve the health of local communities.
						</p>
						<p>
							A Healthy Neighbourhood city is designed with <a target="_blank" rel="noreferrer"
							href="https://www.c40knowledgehub.org/s/article/Why-every-city-can-benefit-from-a-15-minute-city-vision?language=en_US">
							15-minute city principles</a> - creating neighbourhoods where cities can live, work and play.
							This urban planning approach creates healthier local communities by facilitating more walking and cycling.
						</p>
						<p>
							Be curious and <b>explore the neighbourhoods</b> of your city by <b>selecting </b> a city on the interactive map.
						</p>
						<p>
							Note this tool works best on a larger screen. Please access it from a computer or laptop.
						</p>
					</div>
					<div className='mt-2 mb-2 px-2 d-flex justify-content-center'>
						<iframe width="560" height="315" src="https://www.youtube.com/embed/h2_xMj9Nsfk?si=Ah2wO8q8vlSCBCGU" 
							title="YouTube video player" frameBorder="0" 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
							referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
						</iframe>					
					</div>
				</div>
				<Button variant="contained" onClick={() => hideOverlay()}>START EXPLORING</Button>				
			</div>

			<div id="map" className={(selectedCityId !== "" ? "got-city " : "") + "map-container"}>
			</div>

			<div id="popup" className="ol-popup">
				<div id="popup-content"></div>
			</div>

			<div id="panel" className={selectedCityId !== "" ? "got-city" : ""}>
				<FormControl className="select-city">
					<SelectCity id="select-city"
						displayEmpty inputProps={{ 'aria-label': 'Without label' }}
						value={selectedCityId}
						onChange={handleChange}
						defaultValue="">
						<MenuItem key={""} value="">Select a city</MenuItem>
						{cities.map((city, index) => <MenuItem key={index} value={city.cityId}>{city.originalCityName}</MenuItem>)}
					</SelectCity>
				</FormControl>

				<div className="side-panel">
					<div className="top-color-bar"></div>

					<Typography className="c-40__Title-about" variant="h6">
						Indicators
					</Typography>

					<FormControl className="select-indicator">
						<SelectCity
							displayEmpty inputProps={{ 'aria-label': 'Without label' }}
							id="indicator-select"
							onChange={handleIndicatorChange}
							native
							value={chosenIndicator.id}>
							<option value="">Select an indicator</option>
							{indicatorsOptions}
						</SelectCity>
					</FormControl>

					<Accordion className={chosenIndicator.id === "" ? "no-indicator" : ""} defaultExpanded={true}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="indicator-content" id="indicator-header">
							<Typography className="c-40__Title-about" variant="h6">
								{chosenIndicator.title}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant="body2">
								{chosenIndicator.description}
							</Typography>
						</AccordionDetails>
					</Accordion>

					{ (selectedNeighborhoodId === "" && regionOrCityName !== "area displayed") ?
						<div className={chosenIndicator.id === "" ? "d-none" : "info-block"}>
							<span>Click on a Neighbourhood</span>
							to display the ranking for the chosen indicator
						</div>
					:
					<div className="rank-block">
						<Accordion className={chosenIndicator.id === "" ? "no-indicator" : ""}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="ranks-content" id="ranks-header">
								<Typography className="c-40__Title-about" variant="h6">
									Ranks <span>{ranking.findIndex(function (item, i) { return item.id_ === selectedCityId || item.id_ === selectedNeighborhoodId }) + 1} </span>
									in<span> {ranking.length}</span> in {ranking.length !== cities.length ? regionOrCityName : "the world"}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<ul>
									{ranking.map((feature, index) => <li key={index} className={(feature.id_ === selectedCityId || feature.id_ === selectedNeighborhoodId) ? "city-rank" : ""}>
										{feature.values_.name}</li>)}
								</ul>
							</AccordionDetails>
						</Accordion>

						<div className={(chosenIndicator.id === "" ? "no-indicator " : "") + "rank-chart"}>

							<Typography variant="body2">
								High ranking indicates higher {chosenIndicator.title}
							</Typography>

							<BarChart chartData={chartData} />
							<Grid item xs={4} sx={{ m: '1.55rem' }} style={{ textAlign: "center" }}>
								<Button variant="outlined" onClick={() => exportData()} endIcon={<ArrowCircleDownIcon />}
									style={{ padding: "5px 12px", marginBottom: "10px" }}>
									Download GeoJSON data
								</Button>
							</Grid>
						</div>
					</div>
					}

				</div>
			</div>

			<div id="selected-city" className={(selectedCityId !== "" ? "got-city" : "") + " " + (chosenIndicator.id !== "" ? "got-score" : "")}>
				<h6>{headerText.split(",")[0]},<br />{headerText.split(",")[1]}</h6>
				<div className="indicator">
					<div>
						<span>{chosenIndicator.title}</span>
						<MapUtils.ColorScale />
					</div>
				</div>
			</div>

		</div>
	);
}

export default HomePage