import TabPanel from "@mui/lab/TabPanel";
import { Snackbar, SnackbarValue, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { FormControl, TextField, Grid, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';

import "../../css/databasePage.css";
import { useEffect, useState } from "react";
import DataService from "../../services/getData";
import Utils from "../../utils/miscUtils";
import { LoadingButton } from "@mui/lab";
import InformationModal from "../basicModal/informationModal";

export default function Simulation({userInputs, setUserInputs, citySpecificCompositionTable, singleNationalCompositionTable, selectedCurrency, 
  newAdditionalInputs, listOfActions, setSimulationResults, setHasResult, hasResult, setAdvanceErrors, advanceErrors, includePreProject}) {
  const [error, setError] = useState("");
	const [loading, setloading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  let tempAdvanceErrors = [...advanceErrors]
  const [modalOpen, setModalOpen] = useState(false)
  
  const handleSimulation = () =>{
    if(userInputs.typeOfSimulation==0){
      tempAdvanceErrors = Utils.UpdateAdvanceError(userInputs, tempAdvanceErrors, setAdvanceErrors)
    }
    if(Utils.VerifyFields(newAdditionalInputs, userInputs, tempAdvanceErrors, setAdvanceErrors, includePreProject,listOfActions)){
      let tempInputs = {... userInputs};
      if(handleActionsPopulation.length>0){
        handleActionsPopulation(tempInputs)
      }
      populateAdditionalInputs(tempInputs)
      getData(tempInputs)
    }else{
      //setMessage(advanceErrors[0].errorMessage)
      setOpenSnackbar(true)
    }
  }

  const onSnackbarClose =() => {
    setOpenSnackbar(false)
  }

  useEffect(()=>{
    if(advanceErrors.length!=0){
      setMessage(advanceErrors[0].errorMessage)
    }
  },[advanceErrors])

  // //Verify if there are errors, and add to array if they exist
  // const verifyFields = () => {
  //   let tempAdvanceErrors = [...advanceErrors]
  //   let error = false;
  //   if(advanceErrors.length>0){
  //     error=true
  //   }
  //     if((newAdditionalInputs.proportion_project_other_modes.total_mode_shift==0 || newAdditionalInputs.proportion_project_other_modes.total_mode_shift=="0")
  //       && !(tempAdvanceErrors.find(error => error.errorType == "mode_share_error")) && userInputs.typeOfSimulation!=0){
  //       error = true;
  //       setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
  //         errorMessage:"Please make sure to fill the Total Mode Shift on the Project Paramaters tab",
  //         errorType: "mode_share_error"}]);
  //       }
  //     if((userInputs.assessmentTime==0 || userInputs.assessmentTime=="") && !(tempAdvanceErrors.find(error => error.errorType == "assessment_time_error"))){
  //       error = true;
  //       setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
  //       errorMessage: "Please make sure to fill the Assessment Time on the Project Parameters Tab",
  //       errorType: "assessment_time_error"}]);
  //     }
  //     if((newAdditionalInputs.pre_project_conditions_walking.trips_per_day==0 || newAdditionalInputs.pre_project_conditions_walking.trips_per_day=="0") && 
  //     !(tempAdvanceErrors.find(error => error.errorType == "trips_per_day_error")) && userInputs.typeOfSimulation!=0){
  //       error = true;
  //       setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
  //         errorMessage: "Please make sure to fill the Trip Per Day (Pre) on the Project Parameters Tab",
  //         errorType: "trips_per_day_error"}]);
  //     }
  //      return !error
  //   }


  const getData = async (objectToSend) => {
		setloading(true)
    try {
      const dataTemp = await DataService.SimulateUserProject(objectToSend);
        setSimulationResults(dataTemp.data);
				setHasResult(true);
				setloading(false);
      } catch (error) {
        setModalOpen(true)
				setHasResult(false)
				setloading(false);
      }  
  }

  const populateAdditionalInputs = (tempInputs) => {
    tempInputs.userCenterPointGeom = localStorage.getItem("clickedCoordinates")
    tempInputs.averageTripDurationPreproject = newAdditionalInputs.pre_project_conditions_walking.average_trip_duration;
    tempInputs.averageTripDurationPostproject = newAdditionalInputs.post_project_conditions_walking.average_trip_duration;
    tempInputs.averageTripSpeedPreproject = newAdditionalInputs.pre_project_conditions_walking.average_trip_speed;
    tempInputs.averageTripSpeedPostproject = newAdditionalInputs.post_project_conditions_walking.average_trip_speed;
    tempInputs.averageTripDistancePreproject = newAdditionalInputs.pre_project_conditions_walking.average_trip_distance;
    tempInputs.averageTripDistancePostproject = newAdditionalInputs.post_project_conditions_walking.average_trip_distance;
    tempInputs.cityModeShareWalkPercentage = newAdditionalInputs.city_mode_share.walk;
    tempInputs.cityModeShareBicyclePercentage = newAdditionalInputs.city_mode_share.bicycle;
    tempInputs.cityModeShareCarMotorcycleSharePercentage = newAdditionalInputs.city_mode_share.car_and_motor_cycle_share;
    tempInputs.cityModeShareCarMotorcycleSoloPercentage = newAdditionalInputs.city_mode_share.car_and_motor_cycle_solo;
    tempInputs.cityModeShareTaxiMinibuPercentage = newAdditionalInputs.city_mode_share.taxi_or_mini_bus;
    tempInputs.cityModeSharePublicTransportPercentage = newAdditionalInputs.city_mode_share.public_transport;
    tempInputs.cyclingMinApplicableAge = newAdditionalInputs.physical_benefits_params[0].applicable_age_min;
    tempInputs.cyclingMaxApplicableAge = newAdditionalInputs.physical_benefits_params[0].applicable_age_max;
    tempInputs.cyclingRelativeRisk = newAdditionalInputs.physical_benefits_params[0].relative_risk;
    tempInputs.cyclingReferenceVolume = newAdditionalInputs.physical_benefits_params[0].reference_volume;
    tempInputs.cyclingCapsPercentImprove = newAdditionalInputs.physical_benefits_params[0].caps_percentage;
    tempInputs.cyclingCapsMinPWeek = newAdditionalInputs.physical_benefits_params[0].caps_min_or_week;
    tempInputs.walkingMinApplicableAge = newAdditionalInputs.physical_benefits_params[1].applicable_age_min;
    tempInputs.walkingMaxApplicableAge = newAdditionalInputs.physical_benefits_params[1].applicable_age_max;
    tempInputs.walkingRelativeRisk = newAdditionalInputs.physical_benefits_params[1].relative_risk;
    tempInputs.walkingReferenceVolume = newAdditionalInputs.physical_benefits_params[1].reference_volume;
    tempInputs.walkingCapsPercentImprove = newAdditionalInputs.physical_benefits_params[1].caps_percentage;
    tempInputs.walkingCapsMinPWeek = newAdditionalInputs.physical_benefits_params[1].caps_min_or_week;
    tempInputs.currency = selectedCurrency.id;
    tempInputs.valueLifeYear = newAdditionalInputs.economic_factor.value_of_statistical_life;
    tempInputs.valueStatisticalLife = newAdditionalInputs.economic_factor.value_of_life_year;
    tempInputs.discountRate = newAdditionalInputs.economic_factor.discount_rate;
    tempInputs.carMotorcycleRideShareJourneyLength = newAdditionalInputs.journey_length.car_and_motorcycle_share;
    tempInputs.projectCarMotorcycleRideSharePercentage = newAdditionalInputs.proportion_project_origin_modes.car_and_motorcycle_share;
    tempInputs.carMotorcycleSoloDriverJourneyLength = newAdditionalInputs.journey_length.car_and_motorcycle_solo;
    tempInputs.projectCarMotorcycleSoloDriverPercentage = newAdditionalInputs.proportion_project_origin_modes.car_and_motorcycle_share;
    tempInputs.projectTaxiMinibusPercentage = newAdditionalInputs.proportion_project_origin_modes.taxi_and_minibus;
    tempInputs.projectPublicTransportPercentage = newAdditionalInputs.proportion_project_origin_modes.public_transport;
    tempInputs.malePercentageCyclistsWalkers = newAdditionalInputs.cyclists_population_gender.male_percentage;
    tempInputs.femalePercentageCyclistsWalkers = newAdditionalInputs.cyclists_population_gender.female_percentage;
    tempInputs.projectBicyclePercentage = newAdditionalInputs.proportion_project_origin_modes.bicycle;
    tempInputs.projectWalkPercentage = newAdditionalInputs.proportion_project_origin_modes.walk;
    tempInputs.carMotorcycleRideShareJourneyLength = newAdditionalInputs.journey_length.car_and_motorcycle_share;
    tempInputs.carMotorcycleSoloDriverJourneyLength = newAdditionalInputs.journey_length.car_and_motorcycle_solo;
    tempInputs.taxiOrMinibusJourneyLength = newAdditionalInputs.journey_length.taxi_and_minibus;
    tempInputs.publicTransportJourneyLength = newAdditionalInputs.journey_length.public_transport;
    tempInputs.bicycleJourneyLength = newAdditionalInputs.journey_length.bicycle;
    tempInputs.walkJourneyLength = newAdditionalInputs.journey_length.walk;
    tempInputs.minimumAge = newAdditionalInputs.age_range_of_cyclists.min_age;
    tempInputs.maximumAge = newAdditionalInputs.age_range_of_cyclists.max_age;
    tempInputs.capex = newAdditionalInputs.project_costs_estimates.capex;
    tempInputs.opex = newAdditionalInputs.project_costs_estimates.opex;
    tempInputs.totalMinutesOfActivityPerYearPreproject = newAdditionalInputs.pre_project_conditions_walking.total_minutes_of_activity_per_year;
    tempInputs.totalMinutesOfActivityPerYearPostproject = newAdditionalInputs.post_project_conditions_walking.total_minutes_of_activity_per_year;
    tempInputs.totalMinutesOfActivityPerPersonPreproject = newAdditionalInputs.pre_project_conditions_walking.total_minutes_of_activity_per_pedestrian;
    tempInputs.totalMinutesOfActivityPerPersonPostproject = newAdditionalInputs.post_project_conditions_walking.total_minutes_of_activity_per_pedestrian;
    tempInputs.totalPeoplePerYearPostproject = newAdditionalInputs.post_project_conditions_walking.total_pedestrians_per_year;
    tempInputs.totalPeoplePerYearPreproject = newAdditionalInputs.pre_project_conditions_walking.total_pedestrians_per_year;
    tempInputs.totalTripsPerYearPostproject = newAdditionalInputs.post_project_conditions_walking.total_trips_per_day;
    tempInputs.totalTripsPerYearPreproject = newAdditionalInputs.pre_project_conditions_walking.total_trips_per_day;
    tempInputs.tripsPerDayPostproject = newAdditionalInputs.post_project_conditions_walking.trips_per_day;
    tempInputs.tripsPerDayPreproject = newAdditionalInputs.pre_project_conditions_walking.trips_per_day;
    tempInputs.tripsPerYearPerUser = newAdditionalInputs.pedestrian_travel_characteristics.trips_per_year_per_user;
    tempInputs.minimumTripDistance = newAdditionalInputs.pedestrian_travel_characteristics.minimum_trip_distance;
    tempInputs.totalModeShift = newAdditionalInputs.proportion_project_other_modes.total_mode_shift;
    tempInputs.newTrips = newAdditionalInputs.proportion_new_pedestrian_trips.new_walking_trips;
    tempInputs.carMotorcycleRideSharePersonsPVehicle =newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_share;
    tempInputs.carMotorcycleSoloDriverPersonsPVehicle =newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_solo;
    tempInputs.taxiOrMinibusPersonsPerVehicle = newAdditionalInputs.vehicle_load_factors.taxi_and_minibus;
    tempInputs.publicTransportPersonsPerVehicle = newAdditionalInputs.vehicle_load_factors.public_transport;
    tempInputs.projectTotalPercentage = newAdditionalInputs.proportion_project_origin_modes.total;
    tempInputs.coronaryHeartDiseaseMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[0].minutes_per_week;
    tempInputs.coronaryHeartDisease = newAdditionalInputs.morbidity_risk_reduction[0].risk_reduction_percentage;
    tempInputs.strokeMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[1].minutes_per_week;
    tempInputs.strokeRiskReduction = newAdditionalInputs.morbidity_risk_reduction[1].risk_reduction_percentage;
    tempInputs.dementiaMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[2].minutes_per_week;
    tempInputs.dementia = newAdditionalInputs.morbidity_risk_reduction[2].risk_reduction_percentage;
    tempInputs.typeIiDiabetesMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[3].minutes_per_week;
    tempInputs.typeIiDiabetes = newAdditionalInputs.morbidity_risk_reduction[3].risk_reduction_percentage;
    tempInputs.depressionMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[4].minutes_per_week;
    tempInputs.depression = newAdditionalInputs.morbidity_risk_reduction[4].risk_reduction_percentage;
    tempInputs.breastCancerMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[5].minutes_per_week;
    tempInputs.breastCancer = newAdditionalInputs.morbidity_risk_reduction[5].risk_reduction_percentage;
    tempInputs.colonCancerMinutesPWeek = newAdditionalInputs.morbidity_risk_reduction[6].minutes_per_week;
    tempInputs.colonCancer = newAdditionalInputs.morbidity_risk_reduction[6].risk_reduction_percentage;
    tempInputs.cyclistPopulationCompositionBAgeCohort = handleCompositionObject();
    setUserInputs(tempInputs)
  };

    const handleCompositionObject = () => {
      if(userInputs.populationCompositionMethod=="National"){
        return JSON.stringify(singleNationalCompositionTable)
      }else{
        return JSON.stringify(citySpecificCompositionTable)
      }
    }

    const handleActionsPopulation = (userInputs) =>{
      userInputs.action1Id = "";
      userInputs.action1ImpactValue = "";
      userInputs.action2Id = "";
      userInputs.action2ImpactValue = "";
      userInputs.action3Id = "";
      userInputs.action3ImpactValue = "";
      userInputs.action4Id = "";
      userInputs.action4ImpactValue = "";
      userInputs.action5Id = "";
      userInputs.action5ImpactValue = "";

      for (let i = 0; i < listOfActions.length; i += 1) {
        switch (i) {
          case 0:
            userInputs.action1Id = listOfActions[i].actionId;
            userInputs.action1ImpactValue = Utils.ImpactConverter(listOfActions[i]);
            break;
          case 1:
            userInputs.action2Id = listOfActions[i].actionId;
            userInputs.action2ImpactValue = Utils.ImpactConverter(listOfActions[i]);
            break;
          case 2:
            userInputs.action3Id = listOfActions[i].actionId;
            userInputs.action3ImpactValue = Utils.ImpactConverter(listOfActions[i]);
            break;
          case 3:
            userInputs.action4Id = listOfActions[i].actionId;
            userInputs.action4ImpactValue = Utils.ImpactConverter(listOfActions[i]);
            break;
          case 4:
            userInputs.action5Id = listOfActions[i].actionId;
            userInputs.action5ImpactValue = Utils.ImpactConverter(listOfActions[i]);
            break;
          default:
            break;
        }
      }
    }


  return (
    <TabPanel value="4" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        On this page, you will be asked to run the simulation of the live project. 
        Once the simulation is complete, you will be asked to save the project before seeing the results
      </Typography>
      <h6 className="mt-4">Simulation</h6>
      <div
        className="mt-4 pb-5 pt-5"
        style={{ display: "flex", justifyContent: "center", mb:2}}
      >
				 <LoadingButton
          onClick={handleSimulation}
					className="default_modal simulationBtn"
          loading={loading}
          variant="contained"
          loadingPosition="end"
          endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" visibility={loading ? "hidden": "visible"}>
          <path 
          id="icons8_settings_1" 
          d="M11.424,2a.5.5,0,0,0-.494.418l-.283,1.705a7.91,7.91,0,0,0-1.41.381l-1.1-1.336A.5.5,0,0,0,7.5,3.053l-1,.576a.5.5,0,0,0-.219.607l.605,1.617A8.092,8.092,0,0,0,5.854,6.889L4.236,6.283a.5.5,0,0,0-.607.219l-.576,1a.5.5,0,0,0,.115.637L4.5,9.236a7.91,7.91,0,0,0-.381,1.41l-1.705.283A.5.5,0,0,0,2,11.424v1.152a.5.5,0,0,0,.418.494l1.705.283a7.912,7.912,0,0,0,.381,1.41l-1.336,1.1a.5.5,0,0,0-.115.637l.576,1a.5.5,0,0,0,.607.219l1.617-.606a8.089,8.089,0,0,0,1.035,1.035l-.605,1.617a.5.5,0,0,0,.219.607l1,.576a.5.5,0,0,0,.637-.115l1.1-1.336a7.917,7.917,0,0,0,1.412.381l.283,1.7a.5.5,0,0,0,.494.418h1.152a.5.5,0,0,0,.494-.418l.283-1.7a7.917,7.917,0,0,0,1.41-.381l1.1,1.336a.5.5,0,0,0,.637.115l1-.576a.5.5,0,0,0,.219-.607l-.606-1.617a8.087,8.087,0,0,0,1.035-1.035l1.617.606a.5.5,0,0,0,.607-.219l.576-1a.5.5,0,0,0-.115-.637l-1.336-1.1a7.916,7.916,0,0,0,.381-1.412l1.7-.283A.5.5,0,0,0,22,12.576V11.424a.5.5,0,0,0-.418-.494l-1.7-.283a7.915,7.915,0,0,0-.381-1.41l1.336-1.1a.5.5,0,0,0,.115-.637l-.576-1a.5.5,0,0,0-.607-.219l-1.617.605a8.089,8.089,0,0,0-1.035-1.035l.606-1.617a.5.5,0,0,0-.219-.607l-1-.576a.5.5,0,0,0-.637.115L14.766,4.5a7.909,7.909,0,0,0-1.412-.381L13.07,2.418A.5.5,0,0,0,12.576,2ZM11,6.09V9.174a3,3,0,0,0-1.949,3.375L6.379,14.09A5.986,5.986,0,0,1,11,6.09Zm2,0a5.983,5.983,0,0,1,4.619,8l-2.672-1.545A2.979,2.979,0,0,0,13,9.176Zm.947,8.187,2.682,1.549a6.015,6.015,0,0,1-9.258,0l2.68-1.547a2.991,2.991,0,0,0,3.9,0Z" 
          transform="translate(-2 -2)"/>
        </svg>}
        >
          <span>Simulate Project Impacts</span>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" visibility={loading ? "hidden": "visible"}>
            <path 
            id="icons8_settings_1" 
            d="M11.424,2a.5.5,0,0,0-.494.418l-.283,1.705a7.91,7.91,0,0,0-1.41.381l-1.1-1.336A.5.5,0,0,0,7.5,3.053l-1,.576a.5.5,0,0,0-.219.607l.605,1.617A8.092,8.092,0,0,0,5.854,6.889L4.236,6.283a.5.5,0,0,0-.607.219l-.576,1a.5.5,0,0,0,.115.637L4.5,9.236a7.91,7.91,0,0,0-.381,1.41l-1.705.283A.5.5,0,0,0,2,11.424v1.152a.5.5,0,0,0,.418.494l1.705.283a7.912,7.912,0,0,0,.381,1.41l-1.336,1.1a.5.5,0,0,0-.115.637l.576,1a.5.5,0,0,0,.607.219l1.617-.606a8.089,8.089,0,0,0,1.035,1.035l-.605,1.617a.5.5,0,0,0,.219.607l1,.576a.5.5,0,0,0,.637-.115l1.1-1.336a7.917,7.917,0,0,0,1.412.381l.283,1.7a.5.5,0,0,0,.494.418h1.152a.5.5,0,0,0,.494-.418l.283-1.7a7.917,7.917,0,0,0,1.41-.381l1.1,1.336a.5.5,0,0,0,.637.115l1-.576a.5.5,0,0,0,.219-.607l-.606-1.617a8.087,8.087,0,0,0,1.035-1.035l1.617.606a.5.5,0,0,0,.607-.219l.576-1a.5.5,0,0,0-.115-.637l-1.336-1.1a7.916,7.916,0,0,0,.381-1.412l1.7-.283A.5.5,0,0,0,22,12.576V11.424a.5.5,0,0,0-.418-.494l-1.7-.283a7.915,7.915,0,0,0-.381-1.41l1.336-1.1a.5.5,0,0,0,.115-.637l-.576-1a.5.5,0,0,0-.607-.219l-1.617.605a8.089,8.089,0,0,0-1.035-1.035l.606-1.617a.5.5,0,0,0-.219-.607l-1-.576a.5.5,0,0,0-.637.115L14.766,4.5a7.909,7.909,0,0,0-1.412-.381L13.07,2.418A.5.5,0,0,0,12.576,2ZM11,6.09V9.174a3,3,0,0,0-1.949,3.375L6.379,14.09A5.986,5.986,0,0,1,11,6.09Zm2,0a5.983,5.983,0,0,1,4.619,8l-2.672-1.545A2.979,2.979,0,0,0,13,9.176Zm.947,8.187,2.682,1.549a6.015,6.015,0,0,1-9.258,0l2.68-1.547a2.991,2.991,0,0,0,3.9,0Z" 
            transform="translate(-2 -2)"/>
          </svg> */}
        </LoadingButton>
      {/* <Button onClick={handleSimulation} className="default_modal">
          Simulate Project Impacts
      </Button> */}
      </div>
			<div style={{justifyContent: 'center', display: 'flex'}}>
			{hasResult && <label htmlFor="" className="mt-4 label-default">
        Your simulation is complete. Click Next to save the project.
      </label>}
			</div>
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={onSnackbarClose}>
        <MuiAlert severity="warning">{message}</MuiAlert >
      </Snackbar>
      {<InformationModal title={"Error"} modalOpen={modalOpen} setModalOpen={setModalOpen} 
        positiveButtonModalLabel='OK' simulationError={true}/>}
    </TabPanel>
    
  );
}
