import {Button, FormControl, Grid, Stack, Typography, MenuItem, InputLabel, Select, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody, Paper, } from "@mui/material";
import BasicBarChart from "../chart/basicChart";
import { TabPanel } from "@mui/lab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export default function CompareProject({simulationResults, listOfProjectsForComparison, selectedCurrency}) {

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    // zIndex: theme.zIndex.tooltip + 1,
    //margin: 4,
    [`& .MuiTooltip-tooltip`]: {
      // maxWidth: 200,
      // height: 100,
      // fontFamily: "'Grape Nuts', Helvetica",
      fontFamily:"'Roboto', 'Helvetica', 'Arial','sans-serif'",
      backgroundColor: "#EFFBFF",
      color: "black",
      fontSize: 16,
      // margin: 4,
      // padding: 8,
      whiteSpace: "pre-line"
      //border: "solid yellow 1px"
    }
  }));

  const defineCharts = (currentValue, listOfValues) => {
		let temp = {}
    let tempProjectList = listOfProjectsForComparison.map((project) => {return project.projectName});
		temp.labels = ['Live Project',...tempProjectList]
    temp.datasets = [
      {
        label: 'Reduction in Years Lived with Disabilities',
        data: [currentValue, ...listOfValues],
        // you can set indiviual colors for each bar
        backgroundColor: [
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
        ],
      }]
      return temp;
	}

return( 
  <TabPanel value="2" className="p-0 my-4">
    <div>
        <h5 className="titleTable mt-4">Mobility <StyledTooltip title="Compare your mobility project scores to the selected project(s)" arrow><span className="icon mt-1"></span></StyledTooltip></h5>
            
            <Grid container spacing={1}>
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Indicator
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                        <TableCell className="firstLineTable">
                          Retail quality (out of 25)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">{(simulationResults.postProjectRetailFloorAreaRatio).toFixed(2)}</TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectRetailFloorAreaRatio.toFixed(2))}</TableCell>
                        ))}
                        <TableCell align="center">
                            Score
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className="firstLineTable">
                          Land-use mix (out of 25)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">{(simulationResults.postProjectLandUseMix).toFixed(2)}</TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectLandUseMix).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className="firstLineTable">
                          Intersection density (out of 25)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">{(simulationResults.postProjectStreetConnectivity).toFixed(2)}</TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectStreetConnectivity).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className="firstLineTable">
                        Population density (out of 25)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">{(simulationResults.postProjectDwellingResidentialDensity).toFixed(2)}</TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectDwellingResidentialDensity).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                        </TableRow>
                        <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                        <TableCell className="lastLineTable-html">Mobility Combined Score (out of 100)</TableCell>
                        <TableCell align="center" className="lastLineTableNumbers-html">
                        {(simulationResults.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="lastLineTableNumbers-html">{(project.projectInfo.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectRetailFloorAreaRatio).toFixed(2),
                  listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectRetailFloorAreaRatio).toFixed(2)}))} title={"Retail quality/diversity"} 
                YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectLandUseMix).toFixed(2),
                  listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectLandUseMix).toFixed(2)}))} title={"Land-use mix"} 
                YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectStreetConnectivity).toFixed(2),
                  listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectStreetConnectivity).toFixed(2)}))} title={"Intersection density"} 
                YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectDwellingResidentialDensity).toFixed(2),
                  listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectDwellingResidentialDensity).toFixed(2)}))} title={"Population density"} 
                YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
            </div>
            <div className="mt-4">
            <h5 className="titleTable">Liveability <StyledTooltip title="Compare your liveability project scores to the selected project(s)" arrow><span className="icon mt-1"></span></StyledTooltip></h5>
              
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Indicator
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Access to amenities (out of 70)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectServices).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectServices).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Access to green space (out of 10)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectNaturalArea).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{project.projectInfo.postProjectNaturalArea ? 
                              (project.projectInfo.postProjectNaturalArea).toFixed(2):0.0}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Outdoor air quality (out of 10)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectEnvironment).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectEnvironment).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Urban crime rate (out of 5)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectSafety).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectSafety).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Traffic Safety (out of 5)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectTrafficSafety).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{project.projectInfo.postProjectTrafficSafety ? (project.projectInfo.postProjectTrafficSafety).toFixed(2)
                            : 0.0}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                        <TableCell className="lastLineTable-html">
                        Liveability Combined Score (out of 100)
                        </TableCell>
                        <TableCell align="center" className="lastLineTableNumbers-html">
                        {(simulationResults.postProjectLivabilityCombined).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectLivabilityCombined).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectServices).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectServices).toFixed(2)}))} title={"Acessibility to health, sport, leisure and education and service facilities"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectNaturalArea).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectNaturalArea ? (project.projectInfo.postProjectNaturalArea).toFixed(2) : 0.0)}))} title={"Green space area and natural environment"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectEnvironment).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectEnvironment).toFixed(2)}))} title={"Concentrations of particulate matter (PM2.5) and NO2 (nitrogen dioxide)"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectSafety).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectSafety).toFixed(2)}))} title={"Crime density (Security)"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts(simulationResults.postProjectTrafficSafety,
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectTrafficSafety ? 
                  (project.projectInfo.postProjectTrafficSafety).toFixed(2) : 0.0)}))} title={"Traffic Safety"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
            </div>
            <div className="mt-4">
            <h5 className="titleTable">General <StyledTooltip title="Compare your general project scores to the selected project(s)" arrow><span className="icon mt-1"></span></StyledTooltip></h5>
              
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Indicator
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Mobility Combined Score (out of 100)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Liveability Combined Score (out of 100)
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.postProjectLivabilityCombined).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectLivabilityCombined).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                      <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                        <TableCell className="lastLineTable-html" >
                        Total score
                        </TableCell>
                        <TableCell align="center" className="lastLineTableNumbers-html">
                        {(simulationResults.postProjectGeneral).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.postProjectGeneral).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">Score</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectWalkabilityAndCyclabilityCombined).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}))} 
                title={"Mobility Combined Score"} YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectLivabilityCombined).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectLivabilityCombined).toFixed(2)}))} title={"Liveability Combined Score"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.postProjectGeneral).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.postProjectGeneral).toFixed(2)}))} title={"Total score"} 
              YText={'Scores'} isPercentage={false} isCompare={true}/>
              </div>
            </div>
            {/* <div className="mt-4">
            <h5 className="titleTable">Action Indicators</h5>
              
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Action Indicators
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Current
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Number Of Current Pedestrians In Intervention</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {simulationResults.numberOfCurrentPedestriansInIntervention}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{project.projectInfo.numberOfCurrentPedestriansInIntervention}</TableCell>
                        ))}
                        <TableCell align="center">persons/year</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div> */}
            {/* <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts(simulationResults.numberOfCurrentPedestriansInIntervention,
                listOfProjectsForComparison.map((project) => {return project.projectInfo.numberOfCurrentPedestriansInIntervention}))} 
                title={"Number Of Current Pedestrians In Intervention"} YText={'persons/year'} isPercentage={false} isCompare={true}/>
              </div>
            </div> */}
            {/* <div className="mt-4">
            <h5 className="titleTable">Output Indicators</h5>
              
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Output Indicators
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Current
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Uptake in Walking (New Pedestrians)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {simulationResults.uptakeInWalking}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{project.projectInfo.uptakeInWalking}</TableCell>
                        ))}
                        <TableCell align="center">persons/year</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                          Number of Car Trips Removed from Road
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {simulationResults.carTripsRemovedFromRoad}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{project.projectInfo.carTripsRemovedFromRoad}</TableCell>
                        ))}
                        <TableCell align="center">trips/year</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts(simulationResults.uptakeInWalking,
                listOfProjectsForComparison.map((project) => {return project.projectInfo.uptakeInWalking}))} 
                title={"Uptake in Walking (New Pedestrians)"} YText={'persons/year'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts(simulationResults.carTripsRemovedFromRoad,
                listOfProjectsForComparison.map((project) => {return project.projectInfo.carTripsRemovedFromRoad}))} title={"Number of Car Trips Removed from Road"} 
              YText={'trips/year'} isPercentage={false} isCompare={true}/>
              </div>
            </div> */}
            {/* <Typography
                className="component_first_subtitle"
                variant="caption"
                gutterBottom
              >
                The Active and Healthy City tool is in its final stages of development. The data in the section below is not finalised yet, 
                it just represents how the results will be displayed when the tool is finished.
            </Typography> */}
            <div className="mt-4">
            <h5 className="titleTable">Outcomes</h5>
              
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Outcome Indicators
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Increase in average minutes of activity</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.averageMinutesOfActivityPerUserPerDay).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.averageMinutesOfActivityPerUserPerDay).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">mins/user/day</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Additional percentage points towards WHO target for physical activity
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.physicalActivityAsPercentOfTheTarget).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.physicalActivityAsPercentOfTheTarget).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">percentage</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <div className="row pb-4 pt-4">
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.averageMinutesOfActivityPerUserPerDay).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.averageMinutesOfActivityPerUserPerDay).toFixed(2)}))} 
                title={"Increase in average minutes of activity"} 
              YText={'mins/user/day'} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.physicalActivityAsPercentOfTheTarget).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.physicalActivityAsPercentOfTheTarget).toFixed(2)}))} title={"Additional percentage points towards WHO target for physical activity"} 
              YText={'Percentage'} isPercentage={true} isCompare={true}/>
              </div>
            </div>
            <div className="mt-4">
            <h5 className="titleTable">Impact</h5>
            <h6 className="mt-3" style={{ fontWeight: 500 }}>
                Health Benefits <StyledTooltip title="Compare your health benefits project scores to the selected project(s)" arrow><span className="icon mt-1"></span></StyledTooltip> 
            </h6>
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Mortality Reduction Benefits
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Life Years Gained (all users)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.lifeYearsGained).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.lifeYearsGained).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">years</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Life Expectancy at Birth Gained
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.lifeExpectancyAtBirth).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.lifeExpectancyAtBirth).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">years</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Deaths averted</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.deathsAverted).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.deathsAverted).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">deaths</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <div className="row pb-4 pt-4">
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.lifeYearsGained).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.lifeYearsGained).toFixed(2)}))} 
                title={"Life Years Gained (all users)"} YText={'years'} isPercentage={false} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.lifeExpectancyAtBirth).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.lifeExpectancyAtBirth).toFixed(2)}))} 
                title={"Life Expectancy at Birth Gained"} YText={'years'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.deathsAverted).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.deathsAverted).toFixed(2)}))} 
                title={"Deaths averted"} 
                YText={'deaths'} isPercentage={false} isCompare={true}/>
                </div>
              </div>
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                            Morbidity Risk Reduction
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">Coronary heart disease</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.coronaryHeartReductionInYearsDisability).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.coronaryHeartReductionInYearsDisability).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        Stroke
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.strokeReductionInRiskOfDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.strokeReductionInRiskOfDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Dementia</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.dementiaReductionInRiskOfDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.dementiaReductionInRiskOfDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Type II Diabetes</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.typeIIDiabetesReductionInRiskOfDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.typeIIDiabetesReductionInRiskOfDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Depression</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.depressionReductionInRiskDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.depressionReductionInRiskDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Breast cancer (Female)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.breastCancerReductionInRiskDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.breastCancerReductionInRiskDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">Colon cancer (Male)</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.colonCancerReductionInRiskDisease).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.colonCancerReductionInRiskDisease).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">% reduction in risk</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <div className="row pb-4 pt-4">
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.coronaryHeartReductionInYearsDisability).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.coronaryHeartReductionInYearsDisability).toFixed(2)}))} 
                title={"Coronary heart disease"} YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.strokeReductionInRiskOfDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.strokeReductionInRiskOfDisease).toFixed(2)}))} 
                title={"Stroke"} YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.dementiaReductionInRiskOfDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.dementiaReductionInRiskOfDisease).toFixed(2)}))} 
                title={"Dementia"} 
                YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.typeIIDiabetesReductionInRiskOfDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.typeIIDiabetesReductionInRiskOfDisease).toFixed(2)}))} 
                title={"Type II Diabetes"} YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.depressionReductionInRiskDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.depressionReductionInRiskDisease).toFixed(2)}))} 
                title={"Depression"} YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.breastCancerReductionInRiskDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.breastCancerReductionInRiskDisease).toFixed(2)}))} 
                title={"Breast cancer (Female)"} 
                YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
                <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
                <BasicBarChart chartData={defineCharts((simulationResults.colonCancerReductionInRiskDisease).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.colonCancerReductionInRiskDisease).toFixed(2)}))} 
                title={"Colon cancer (Male)"} 
                YText={'% reduction in risk'} isPercentage={true} isCompare={true}/>
                </div>
              </div>
              {simulationResults.capex != 0 && simulationResults.opex != 0 && <><h6 className="mt-3" style={{ fontWeight: 500 }}>
          Economic Benefits
        </h6><Grid container spacing={1}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px" }}>
                      Economic Indicators
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "16px" }}>
                    Live Project
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                    ))}
                    <TableCell align="center" sx={{ fontSize: "16px" }}>
                      Unit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="firstLineTable">CAPEX</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.capex).toFixed(2)}
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.capex).toFixed(2)}</TableCell>
                    ))}
                    <TableCell align="center">{"USD " + selectedCurrency.currency_unit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="firstLineTable">Total Benefits Accumulated Over Project Life (VSL-based)</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.totalBenefitsAccumulatedOverProjectLifeVSLBased).toFixed(2)}
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.totalBenefitsAccumulatedOverProjectLifeVSLBased).toFixed(2)}</TableCell>
                    ))}
                    <TableCell align="center">{"USD " + selectedCurrency.currency_unit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="firstLineTable">OPEX</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.opex).toFixed(2)}
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.opex).toFixed(2)}</TableCell>
                    ))}
                    <TableCell align="center">{"USD " + selectedCurrency.currency_unit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="firstLineTable">Total Benefits Accumulated Over Project Life (VOLY-based)</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.totalBenefitsAccumulatedOverProjectLifeVOLYBased).toFixed(2)}
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.totalBenefitsAccumulatedOverProjectLifeVOLYBased).toFixed(2)}</TableCell>
                    ))}
                    <TableCell align="center">{"USD " + selectedCurrency.currency_unit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="firstLineTable">Discounted Implementation Costs</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.discountedImplementationCosts).toFixed(2)}
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.discountedImplementationCosts).toFixed(2)}</TableCell>
                    ))}
                    <TableCell align="center">{"USD " + selectedCurrency.currency_unit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="firstLineTable">Benefit to Cost Ratio (VSL-based)</TableCell>
                    <TableCell align="center" className="gray-bg-others-tags-td">
                      {(simulationResults.benefitToCostRatio).toFixed(2)}:1
                    </TableCell>
                    {listOfProjectsForComparison.map((project, index) => (
                      <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.benefitToCostRatio).toFixed(2)}:1</TableCell>
                    ))}
                    <TableCell align="center">ratio</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid><div className="row pb-4 pt-4">
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.capex).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.capex).toFixed(2); }))}
                title={"CAPEX"} YText={"USD " + selectedCurrency.currency_type} isPercentage={false} isCompare={true} />
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.totalBenefitsAccumulatedOverProjectLifeVSLBased).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.totalBenefitsAccumulatedOverProjectLifeVSLBased).toFixed(2); }))}
                title={"Total Benefits Accumulated Over Project Life (VSL-based)"} YText={"USD " + selectedCurrency.currency_type} isPercentage={false} isCompare={true} />
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.opex).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.opex).toFixed(2); }))}
                title={"OPEX"}
                YText={"USD " + selectedCurrency.currency_type} isPercentage={false} isCompare={true} />
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.totalBenefitsAccumulatedOverProjectLifeVOLYBased).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.totalBenefitsAccumulatedOverProjectLifeVOLYBased).toFixed(2); }))}
                title={"Total Benefits Accumulated Over Project Life (VOLY-based)"}
                YText={"USD " + selectedCurrency.currency_type} isPercentage={false} isCompare={true} />
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.discountedImplementationCosts).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.discountedImplementationCosts).toFixed(2); }))}
                title={"Discounted Implementation Costs"} YText={"USD " + selectedCurrency.currency_type} isPercentage={false} isCompare={true} />
            </div>
            <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.benefitToCostRatio).toFixed(2),
                listOfProjectsForComparison.map((project) => { return (project.projectInfo.benefitToCostRatio).toFixed(2); }))}
                title={"Benefit to Cost Ratio (VSL-based)"}
                YText={'ratio'} isPercentage={false} isCompare={true} />
            </div>
          </div></>}
              <h6 className="mt-3" style={{ fontWeight: 500 }}>
                Emissions Benefits <StyledTooltip title="Compare your emission benefits project scores to the selected project(s)" arrow><span className="icon mt-1"></span></StyledTooltip>
                </h6>
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Emissions benefits
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                        Live Project
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} sx={{ fontSize: "16px" }}>{project.projectName}</TableCell>
                        ))}
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                            Unit
                        </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">C02 reduced</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.co2reduced).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.co2reduced).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">tonnes/year</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                        NOx reduced
                        </TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.noxReduced).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.noxReduced).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">kilograms/year</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">SO2 reduced</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.so2reduced).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.so2reduced).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">kilograms/year</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">PM10 reduced</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.pm10reduced).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.pm10reduced).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">kilograms/year</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">PM2.5 reduced</TableCell>
                        <TableCell align="center" className="gray-bg-others-tags-td">
                        {(simulationResults.pm25reduced).toFixed(2)}
                        </TableCell>
                        {listOfProjectsForComparison.map((project,index) => (
                            <TableCell align="center" key={index} className="gray-bg-others-tags-td">{(project.projectInfo.pm25reduced).toFixed(2)}</TableCell>
                        ))}
                        <TableCell align="center">kilograms/year</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <div className="row pb-4 pt-4">
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.co2reduced).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.co2reduced).toFixed(2)}))} 
                title={"C02 reduced"} YText={"tonnes/year"} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.noxReduced).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.noxReduced).toFixed(2)}))} 
                title={"NOx reduced"} 
              YText={"kilograms/year"} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.so2reduced).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.so2reduced).toFixed(2)}))} 
                title={"SO2 reduced"} 
                YText={"kilograms/year"} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6" style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.pm10reduced).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.pm10reduced).toFixed(2)}))} 
                title={"PM10 reduced"} YText={"kilograms/year"} isPercentage={false} isCompare={true}/>
              </div>
              <div className="col-6 " style={{ display: "flex", justifyContent: "center" }}>
              <BasicBarChart chartData={defineCharts((simulationResults.pm25reduced).toFixed(2),
                listOfProjectsForComparison.map((project) => {return (project.projectInfo.pm25reduced).toFixed(2)}))} 
                title={"PM2.5 reduced"} 
                YText={"kilograms/year"} isPercentage={false} isCompare={true}/>
              </div>
            </div>
        </div>
      </TabPanel>
    );
}