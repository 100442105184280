import React, { useState, useEffect, useRef, Component } from 'react';
import {
  Button, FormControl, Grid, Stack, Typography, MenuItem, InputLabel, Select, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody, Paper,
} from "@mui/material";
import "../../css/databasePage.css";
import ResultsMap from './resultsMap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import DownloadIcon from '@mui/icons-material/Download';
import { mergePdfs } from "../../utils/pdfUtils"
import { PDFDocument } from "pdf-lib";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SelectProject from './selectProject';
import InformationModal from '../basicModal/informationModal';
import CompareProject from './compareProject';
import BasicBarChart from '../chart/basicChart';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  IMPACT_ON_INDICATORS, MOBILITY, LIVABILITY, GENERAL, EMISSION_BENEFITS, VLS_BASED_TITLE, VSL_BASED_RATIO_TITLE, IMPLEMENTATION_COSTS_TITLE,
  VOLY_BASED_TITLE, VSL_BASED_TITLE, DISCONT_RATE, REDUCTION_IN_YEARS_TITLE
} from "../../utils/tooltipConstants";


export default function ResultsStep({ simulationResults, listOfActions, userInputs, selectedCurrency, projects, savedProject, setActiveButtonResults, activeButtonResults, valueSecondTab, setValueSecondTab }) {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    // zIndex: theme.zIndex.tooltip + 1,
    //margin: 4,
    [`& .MuiTooltip-tooltip`]: {
      // maxWidth: 200,
      // height: 100,
      // fontFamily: "'Grape Nuts', Helvetica",
      fontFamily: "'Roboto', 'Helvetica', 'Arial','sans-serif'",
      backgroundColor: "#EFFBFF",
      color: "black",
      fontSize: 16,
      // margin: 4,
      // padding: 8,
      whiteSpace: "pre-line"
      //border: "solid yellow 1px"
    }
  }));

  const [selectedUnit, setSelectedUnit] = useState("years");
  const [listOfProjectsForComparison, setListOfProjectsForComparison] = useState([]);
  const [noActionOpenModal, setNoActionOpenModal] = useState(false);
  const [lifeExpectancyAndGained, setLifeExpectancyAndGained] = useState({
    lifeYearsGained: simulationResults.lifeYearsGained,
    lifeExpectancyAtBirth: simulationResults.lifeExpectancyAtBirth
  })
  const [chartRiskOfDisease, setChartRiskOfDisease] = useState({ labels: [], datasets: [{ label: "", data: [], backgroundColor: "" }] });
  const [chartReductionDisability, setChartReductionDisability] = useState({ labels: [], datasets: [{ label: "", data: [], backgroundColor: "" }] });

  const data = [
    {
      disease: "Coronary heart disease",
      riskReduction: simulationResults.coronaryHeartReductionInRiskOfDisease * 100 + " %",
      disabilityReduction: simulationResults.coronaryHeartReductionInYearsDisability,
    },
    {
      disease: "Stroke", riskReduction: simulationResults.strokeReductionInRiskOfDisease * 100 + " %",
      disabilityReduction: simulationResults.strokeReductionInYearsDisability
    },
    {
      disease: "Dementia", riskReduction: simulationResults.dementiaReductionInRiskOfDisease * 100 + " %",
      disabilityReduction: simulationResults.dementiaReductionInYearsDisability
    },
    {
      disease: "Type II Diabetes",
      riskReduction: simulationResults.typeIIDiabetesReductionInRiskOfDisease * 100 + " %",
      disabilityReduction: simulationResults.typeIIDiabetesReductionInYearsDisability,
    },
    {
      disease: "Depression", riskReduction: simulationResults.depressionReductionInRiskDisease * 100 + " %",
      disabilityReduction: simulationResults.depressionReductionInYearsDisability
    },
    {
      disease: "Breast cancer (female)",
      riskReduction: simulationResults.breastCancerReductionInRiskDisease * 100 + " %",
      disabilityReduction: simulationResults.breastCancerReductionInYearsDisability,
    },
    {
      disease: "Colon cancer (male)",
      riskReduction: simulationResults.colonCancerReductionInRiskDisease * 100 + " %",
      disabilityReduction: simulationResults.colonCancerReductionInYearsDisability,
    },
  ];

  const handleUnitChange = (event) => {
    let temp = { ...lifeExpectancyAndGained }
    switch (event.target.value) {
      case "years":
        temp.lifeYearsGained = simulationResults.lifeYearsGained
        temp.lifeExpectancyAtBirth = simulationResults.lifeExpectancyAtBirth
        break;
      case "months":
        temp.lifeYearsGained = (simulationResults.lifeYearsGained * 12).toFixed(2)
        temp.lifeExpectancyAtBirth = (simulationResults.lifeExpectancyAtBirth * 12).toFixed(2)
        break;
      case "days":
        temp.lifeYearsGained = (simulationResults.lifeYearsGained * 365).toFixed(2)
        temp.lifeExpectancyAtBirth = (simulationResults.lifeExpectancyAtBirth * 365).toFixed(2)
        break;
      default:
        break;
    }
    setLifeExpectancyAndGained(temp)
    setSelectedUnit(event.target.value);
  };

  const handleButtonClickResults = (button) => {
    setActiveButtonResults(button);
  };

  const handleSave = async () => {
    // Get the element.
    const element = document.getElementById('results-to-pdf');
    const title = document.getElementById(userInputs.typeOfSimulation == 0 ? 'pdf-title' : 'pdf-title-2').cloneNode(true);
    const subtitle = document.createElement("h5") // id='pdf-subtitle'>"+userInputs.projectName+"</h5>
    subtitle.innerHTML = userInputs.projectName;
    var clone = element.firstChild.cloneNode(true);
    clone.prepend(subtitle)
    clone.prepend(title);
    clone.classList.add("css-for-pdf")
    clone.style.paddingTop = "0px";
    clone.style.paddingLeft = '0px';
    clone.style.paddingRight = '00px';
    const pagebreak = { mode: 'css', before: '.before', after: '.after', avoid: '.avoid' }

    console.log('Generate the PDF');
    // Generate the PDF.
    var pdf = html2pdf()
      .from(clone)
      .set({
        margin: 0.5, //[0.2, 0.25, 0.1, 0.25],
        filename: 'c15results.pdf',
        pagebreak: pagebreak,
        html2canvas: { dpi: 300, scale: 1 },
        jsPDF: { orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true }
      })

    pdf.save();
    return;

    var elementMap = document.getElementById('map');
    elementMap.classList.add("map-for-pdf")
    //element.style.scale = "0.2";    
    var pdf2 = html2pdf()
      .from(elementMap)
      .set({
        //margin: 0.3,
        //filename: 'out2.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 300, scale: 1 }, //, width: 2200, height: 1200},
        jsPDF: { orientation: 'landscape', unit: 'px', format: 'a4', compressPDF: true }
      })

    //pdf.output('arraybuffer').then(async (result) => {
    //  console.log("PDF arrayBuffer completed=", result )
    const pdfDoc = await PDFDocument.create();
    pdf2.output('arraybuffer').then(async (result2) => {
      const firstDoc = await PDFDocument.load(result2)
      const firstPage = await pdfDoc.copyPages(firstDoc, firstDoc.getPageIndices());
      firstPage.forEach((page) => pdfDoc.addPage(page));

      pdf.output('arraybuffer').then(async (result) => {
        const secondDoc = await PDFDocument.load(result);
        const secondPage = await pdfDoc.copyPages(secondDoc, secondDoc.getPageIndices());
        secondPage.forEach((page) => pdfDoc.addPage(page));
        const pdfBytes = await pdfDoc.save();
        //await pdfDoc.save("c15results.pdf")
        let file = new Blob([pdfBytes], { type: 'application/pdf' });

        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    });
  }

  const handleChangeSecondTab = (event, newValue) => {
    if (newValue == "2" && listOfProjectsForComparison < 1) {
      setNoActionOpenModal(true)
    } else {
      setValueSecondTab(newValue)
    }
  };

  const adjustPercentgaeValues = (value) => {
    return parseFloat(value) > 0 ? "+" + value : value
  }

  const setTotalPopulation = () => {
    let total = ((userInputs.totalTripsPerYearPostproject / userInputs.tripsPerYearPerUser) - userInputs.totalPeoplePerYearPreproject) * userInputs.totalModeShift / 100;
    return total.toFixed(0);
  }

  const setHealthText = () => {
    if (userInputs.typeOfSimulation == 1) {
      return (<Typography >
        Mortality Reduction Benefits for the {setTotalPopulation()} New Pedestrians
      </Typography>)
    } else if (userInputs.typeOfSimulation == 2) {
      return (<Typography >
        Mortality Reduction Benefits for the {setTotalPopulation()} New Cyclists
      </Typography>)
    } else {
      return (<Typography >
        Summary of impact on mortality reduction. Mortality refers to premature death.
      </Typography>)
    }
  }

  const calculateNumberPeopleBenefits = () => {
    return userInputs.totalPeoplePerYearPostproject;
  }

  const calculateNewUsers = () => {
    return (calculateNumberPeopleBenefits() - userInputs.totalPeoplePerYearPreproject) *
      (extraValuesForCalculatingNewUsers())
  }

  const extraValuesForCalculatingNewUsers = () => {
    let tempArray = []
    let tempTotal = "";
    for (let index = 0; index < 4; index++) {
      let tempVar = 0;
      switch (index) {
        case 0:
          tempVar = calculatePopulationFormula(userInputs.cityModeShareCarMotorcycleSharePercentage / 100)
          break;
        case 1:
          tempVar = calculatePopulationFormula(userInputs.cityModeShareCarMotorcycleSoloPercentage / 100)
          break;
        case 2:
          tempVar = calculatePopulationFormula(userInputs.cityModeSharePublicTransportPercentage / 100)
          break;
        case 3:
          tempVar = calculatePopulationFormula(userInputs.cityModeShareTaxiMinibuPercentage / 100)
          break;
        default:
          break;
      }
      tempArray.push(tempVar)
    }
    tempTotal = tempArray.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    return tempTotal + (userInputs.newTrips / 100)
  }

  const calculatePopulationFormula = (value) => {
    let tempValue =
      (userInputs.totalModeShift / 100) * (value / (userInputs.cityModeShareCarMotorcycleSharePercentage / 100 +
        userInputs.cityModeShareCarMotorcycleSoloPercentage / 100 + userInputs.cityModeSharePublicTransportPercentage / 100 +
        userInputs.cityModeShareTaxiMinibuPercentage / 100))
    return tempValue;
  }


  const calculateAverageUsers = () => {
    let tempValue = ((((userInputs.totalMinutesOfActivityPerYearPostproject -
      userInputs.totalMinutesOfActivityPerYearPreproject) * extraValuesForCalculatingNewUsers()) / calculateNewUsers()) / 52)
    return isNaN(tempValue) ? 0 : tempValue;
  }

  const updateRiskChart = () => {
    const labels = data.map(d => d.disease)
    const dataPercentages = [
      parseFloat(simulationResults.coronaryHeartReductionInRiskOfDisease).toFixed(2),
      parseFloat(simulationResults.strokeReductionInRiskOfDisease).toFixed(2),
      parseFloat(simulationResults.dementiaReductionInRiskOfDisease).toFixed(2),
      parseFloat(simulationResults.typeIIDiabetesReductionInRiskOfDisease).toFixed(2),
      parseFloat(simulationResults.depressionReductionInRiskDisease).toFixed(2),
      parseFloat(simulationResults.breastCancerReductionInRiskDisease).toFixed(2),
      parseFloat(simulationResults.colonCancerReductionInRiskDisease).toFixed(2)
    ].map(d => Math.round(d * 100))

    const datasets = [
      {
        label: 'Reduction in Risk of Disease (%)',
        data: dataPercentages,
        backgroundColor: [
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
        ],
      }
    ]

    return { labels, datasets }
  }

  const updateYearsWithDisabilityChart = () => {
    let tempRisk = {}
    tempRisk.labels = [data[0].disease, data[1].disease, data[2].disease, data[3].disease, data[4].disease, data[5].disease, data[6].disease]
    tempRisk.datasets = [
      {
        label: 'Reduction in Years Lived with Disabilities',
        data: [parseFloat(simulationResults.coronaryHeartReductionInYearsDisability).toFixed(5), parseFloat(simulationResults.strokeReductionInYearsDisability).toFixed(5),
        parseFloat(simulationResults.dementiaReductionInYearsDisability).toFixed(5), parseFloat(simulationResults.typeIIDiabetesReductionInYearsDisability).toFixed(5),
        parseFloat(simulationResults.depressionReductionInYearsDisability).toFixed(5), parseFloat(simulationResults.breastCancerReductionInYearsDisability).toFixed(5),
        parseFloat(simulationResults.colonCancerReductionInYearsDisability).toFixed(5)],
        // you can set indiviual colors for each bar
        backgroundColor: [
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
          'rgba(0,170,255,1.000)',
        ],
      }]
    return tempRisk;
  }

  console.log(activeButtonResults)

  return (
    <div>
      <div className="container mt-4 d-flex justify-content-center mb-auto" id={"results"}>
        <Stack className="button-group mt-2" direction="row" spacing={0}>
          {["Live Project", "Compare projects"].map((buttonTypeResults) => (
            <Button key={buttonTypeResults}
              variant="outlined" style={{
                backgroundColor: activeButtonResults === buttonTypeResults ? "#008CB9" : "#23BCED",
                color: "white", borderRadius: buttonTypeResults === "Live Project" ? "4px 0px 0px 4px" : "0px 4px 4px 0px"
              }}
              onClick={() => handleButtonClickResults(buttonTypeResults)}
            >
              {buttonTypeResults.charAt(0).toUpperCase() +
                buttonTypeResults.slice(1)}
            </Button>
          ))}
        </Stack>
      </div>
      <Typography className="component_first_subtitle" variant="caption" gutterBottom>
        Results from this tool are modelled results providing the scale of potential impacts within a local community.
        These should not be relied upon for investment decisions. Local factors and contexts can mean that the beneficial outcomes are not realised as predicted by models.
      </Typography>

      <div className={activeButtonResults == "Live Project" ? "show" : "hide"}>

        {userInputs.typeOfSimulation == 0 && <h5 className="mt-5" id="pdf-title">
          Impact on indicators{" "}
          {/*For phase 1 this tooltip will be removed and the “i” icon as well */}
          {/*<StyledTooltip title={IMPACT_ON_INDICATORS} arrow><span className="icon mt-1"></span></StyledTooltip>*/}
        </h5>}
        {/*For phase 1 removed map*/}
        {/*{userInputs.typeOfSimulation == 0 && <div id="results-map">
          <ResultsMap simulationResults={simulationResults} />
        </div>}
          */}
        <div id="results-to-pdf">
          {/*Remove "no-map" for the if condition to to get the map back*/}
          <div className={userInputs.typeOfSimulation == 0 ? " mt-4 after-results-map no-map" : "mt-4 after-results-map no-map"}>
            {listOfActions.length > 0 && <><h5 className="titleTable mt-4">Summary of Project Actions</h5>
              <div className="py-4 mt-2">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Indicator</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Impact</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listOfActions.map((action, index) => (
                        <TableRow key={index} value={action}>
                          <TableCell>{action.area}</TableCell>
                          <TableCell>{action.action}</TableCell>
                          <TableCell>{action.impact}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div></>}
            {userInputs.typeOfSimulation == 0 && <>
              <h5 className="titleTable mt-4">Mobility</h5>
              <StyledTooltip title={MOBILITY}
                arrow><span className="icon mt-1"></span></StyledTooltip>
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>Indicator</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Pre-Project Score
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Post-Project Score
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Change in Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="firstLineTable">
                          Retail quality (out of 25)
                        </TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.preProjectRetailFloorAreaRatio).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.postProjectRetailFloorAreaRatio).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td" >{/*(simulationResults.differenceRetailFloorAreaRatio).toFixed(2)*/}
                          {(() => {
                              const value = Number(simulationResults.differenceRetailFloorAreaRatio).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                          Land-use mix (out of 25)
                        </TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.preProjectLandUseMix).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.postProjectLandUseMix).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td" >{/*(simulationResults.differenceLandUseMix).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceLandUseMix).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                          Intersection density (out of 25)
                        </TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.preProjectStreetConnectivity).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.postProjectStreetConnectivity).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td" >{/*((simulationResults.differenceStreetConnectivity).toFixed(2))*/}
                            {(() => {
                              const value = Number(simulationResults.differenceStreetConnectivity).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="firstLineTable">
                          Population density (out of 25)
                        </TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.preProjectDwellingResidentialDensity).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td">{(simulationResults.postProjectDwellingResidentialDensity).toFixed(2)}</TableCell>
                        <TableCell className="gray-bg-others-tags-td" >{/*(simulationResults.differenceDwellingDensity).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceDwellingDensity).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                        <TableCell className="lastLineTable-html">Mobility Combined Score (out of 100)</TableCell>
                        <TableCell className="lastLineTableNumbers-html">
                          {(simulationResults.preProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                        </TableCell>
                        <TableCell className="lastLineTableNumbers-html">
                          {(simulationResults.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                        </TableCell>
                        <TableCell className="lastLineTableNumbers-html" >
                          {/*(simulationResults.differenceWalkabilityAndCyclabilityCombined).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceWalkabilityAndCyclabilityCombined).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>}
            <div className="mt-4">
              {userInputs.typeOfSimulation == 0 && <><h5 className="titleTable">Liveability</h5>
                <StyledTooltip title={LIVABILITY} arrow><span className="icon mt-1"></span></StyledTooltip>
                <Grid container spacing={1}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px", width: "34%" }}>
                            Indicator
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Pre-Project Score
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Post-Project Score
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>Change in Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="firstLineTable">Access to amenities (out of 70)</TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectServices).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectServices).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceServices).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceServices).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="firstLineTable">
                            Outdoor air quality (out of 10)
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectEnvironment).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectEnvironment).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceEnvironment).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceEnvironment).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="firstLineTable">
                            Access to green space (out of 10)
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectNaturalArea).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectNaturalArea).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceProjectNaturalArea).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceProjectNaturalArea).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="firstLineTable">Urban crime rate (out of 5)</TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectSafety).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectSafety).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceSafety).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceSafety).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="firstLineTable">
                            Traffic safety (out of 5)
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectTrafficSafety).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectTrafficSafety).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceProjectTrafficSafety).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceProjectTrafficSafety).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                          <TableCell className="lastLineTable-html">
                            Liveability Combined Score (out of 100)
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html">
                            {(simulationResults.preProjectLivabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html">
                            {(simulationResults.postProjectLivabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html" >
                            {/*(simulationResults.differenceLivabilityCombined).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceLivabilityCombined).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid></>}

              {userInputs.typeOfSimulation == 0 && <><div className="mt-3 before">
                <h5 className="titleTable">General</h5>
                <StyledTooltip title={GENERAL} arrow><span className="icon mt-1"></span></StyledTooltip>
                <Grid container spacing={1}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px", width: "33%" }}>
                            Indicator
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Pre-Project Score
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Post-Project Score
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Change in Score
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="firstLineTable">Mobility Combined Score (out of 100)</TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectWalkabilityAndCyclabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceWalkabilityAndCyclabilityCombined).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceWalkabilityAndCyclabilityCombined).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="firstLineTable">
                            Liveability Combined Score (out of 100)
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.preProjectLivabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td">
                            {(simulationResults.postProjectLivabilityCombined).toFixed(2)}
                          </TableCell>
                          <TableCell className="gray-bg-others-tags-td" >
                            {/*(simulationResults.differenceLivabilityCombined).toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceLivabilityCombined).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ borderTop: '1px solid black', backgroundColor: '#f2f2f2' }}>
                          <TableCell className="lastLineTable-html" >
                            Total score (Mobility x Liveability)
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html">
                            {(simulationResults.preProjectGeneral).toFixed(2)}
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html">
                            {(simulationResults.postProjectGeneral).toFixed(2)}
                          </TableCell>
                          <TableCell className="lastLineTableNumbers-html" >{/*simulationResults.differenceGeneral.toFixed(2)*/}
                            {(() => {
                              const value = Number(simulationResults.differenceGeneral).toFixed(2);
                              return value === '-0.00' ? '0.00' : value;
                            })()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div></>}

              {/* <h6 className="mt-3" style={{ fontWeight: 500 }}>
                Description
              </h6>
              <Typography className="mt-3">
                The {userInputs.projectName} is an Infrastucture improvement
                iniciative that will benefit approximately {calculateNumberPeopleBenefits().toFixed(0)} people, 
                including {calculateNewUsers().toFixed(0)} new users. The new users will benefit 
                from an average from of {calculateAverageUsers().toFixed(0)} minutes of new physical activity per week.
              </Typography> */}
              {/* <Grid container spacing={1} className="mt-3">
                      <table>
                        <thead>
                            <tr>
                              <th>Number Of Current Pedestrians In Intervention</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="table-td" data-percentage="persons/year">
                                <span className="iconClock"></span>{simulationResults.numberOfCurrentPedestriansInIntervention}
                              </td>
                              </tr>
                          </tbody>
                        </table>
                    </Grid> */}

              {/* <Typography
                className="component_first_subtitle"
                variant="caption"
                gutterBottom
              >
                The Active and Healthy City tool is in its final stages of development. The data in the section below is not finalised yet, 
                it just represents how the results will be displayed when the tool is finished.
              </Typography> */}
              <div className="mt-3 before">
                <h5 className="mt-5" id="pdf-title-2">OUTCOMES</h5>
                <Typography className="mt-3">
                  Explore the impact of your project on physical activity of the local community in the selected area.
                </Typography>
                <Grid container spacing={1} className="mt-3">
                  <table>
                    <thead>
                      <tr>
                        <th>Increase in average minutes of activity<StyledTooltip title="The calculation is based on the assumption that individuals will undertake two journeys per day, five days per week, and for a total of 25 weeks per year. 
                        This assumption takes into consideration the typical commuting patterns of an average individual while also accounting for factors that may affect the likelihood of walking or cycling, such as holidays and weather conditions." arrow><span className="icon mt-1"></span></StyledTooltip></th>
                        {/*<th>Additional percentage points towards WHO target for physical activity <StyledTooltip title="Adults aged 18–64 should do at least 150 minutes of moderate-intensity 
                        aerobic physical activity throughout the week." arrow><span className="icon mt-1"></span></StyledTooltip></th>*/}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-td" data-percentage="mins/user/week">
                          <span className="iconClock"></span>{(simulationResults.averageMinutesOfActivityPerUserPerDay).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </div>

              <h5 className="mt-5">Benefits</h5>
              <Typography className="mt-3">
                Evaluate the health, emissions and economic benefits related to your actions in the selected area.
              </Typography>
              <h6 className="mt-3 mb-2" style={{ fontWeight: 600 }}>
                Health
              </h6>
              <label htmlFor="" className="label-default mt-3">
                Mortality Reduction
              </label>
              {setHealthText()}
              <Grid container spacing={2} className="mt-3">
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel id="unit-label">Unit</InputLabel>
                    <Select labelId="unit-label" id="unit-select" value={selectedUnit} onChange={handleUnitChange}>
                      <MenuItem value="years">years</MenuItem>
                      <MenuItem value="months">months</MenuItem>
                      <MenuItem value="days">days</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="mt-3">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Life Years Gained (all users) <StyledTooltip title="Years of life gained (YLG) represents the additional number of years someone is expected to live due to an intervention. YLGs account for both the frequency of deaths and the age at which they occur." arrow><span className="icon mt-1"></span></StyledTooltip>
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Deaths Averted <StyledTooltip title="An estimate of the number of deaths that are avoidable due to an intervention." arrow><span className="icon mt-1"></span></StyledTooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell className="table-td" data-percentage={selectedUnit}>
                          {parseFloat(lifeExpectancyAndGained.lifeYearsGained).toFixed(2)}
                        </TableCell>
                        <TableCell className="table-td" data-percentage="deaths">
                          {parseFloat(simulationResults.deathsAverted).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>
                          Life Expectancy at Birth Gained <StyledTooltip title="The average number of years gained that a newborn would live if current death rates do not change." arrow><span className="icon mt-1"></span></StyledTooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="table-td" data-percentage={selectedUnit}>
                          {parseFloat(lifeExpectancyAndGained.lifeExpectancyAtBirth).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <div className="mt-3 ">
                <label htmlFor="" className="label-default mt-5 before">
                  Morbidity Reduction
                </label>
              </div>
              <Typography >
                Summary of impact on morbidity reduction. Morbidity is the condition of living with a disease or disability.
              </Typography>

              <Grid container spacing={1} className="mt-3 before pdf-top-space">
                <table>
                  <thead>
                    <tr>
                      <th>Disease</th>
                      <th>Reduction in Risk of Disease (%)</th>
                      <th>Reduction in Years w/ Disability (years) <StyledTooltip title={REDUCTION_IN_YEARS_TITLE} arrow><span className="icon mt-1"></span></StyledTooltip></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => (
                      <tr key={row.disease}>
                        <td className="firstLineTable">{row.disease}</td>
                        <td className="table-td">{parseFloat(row.riskReduction).toFixed(2)} %</td>
                        <td className="table-td">{parseFloat(row.disabilityReduction).toFixed(5)} years</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </Grid>

              <div className="pb-4 pt-4" style={{ justifyContent: 'center', display: 'flex' }}>
                <BasicBarChart chartData={updateRiskChart()} title={"Reduction in Risk of Disease (%)"}
                  YText={'Percent Reduction'} isPercentage={true} /> </div>
              <div className="pb-4 pt-4" style={{ justifyContent: 'center', display: 'flex' }}>
                <BasicBarChart chartData={updateYearsWithDisabilityChart()} title={"Reduction in Years Lived with Disabilities"}
                  YText={'YLDs'} isPercentage={false} />
              </div>

              <h6 className="mt-3 before" style={{ fontWeight: 500 }}>
                Economic Benefits
              </h6><Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {(simulationResults.capex != 0 && simulationResults.opex != 0) || (simulationResults.capex != 0) && <TableCell sx={{ fontSize: "16px" }}>CAPEX</TableCell>}
                        <TableCell sx={{ fontSize: "16px" }}>
                          Total Benefits Accumulated Over Project Life (VSL-based) <StyledTooltip title={VSL_BASED_TITLE} arrow><span className="icon mt-1"></span></StyledTooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        {(simulationResults.capex != 0 && simulationResults.opex != 0) || (simulationResults.capex != 0) && <TableCell className="table-td" data-percentage={"USD " + selectedCurrency.currency_unit}>
                          {(simulationResults.capex).toFixed(2)}
                        </TableCell>}
                        <TableCell className="table-td" data-percentage={"USD " + selectedCurrency.currency_unit}>
                          {(simulationResults.totalBenefitsAccumulatedOverProjectLifeVSLBased).toFixed(2)}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        {(simulationResults.capex != 0 && simulationResults.opex != 0) || (simulationResults.opex != 0) && <th sx={{ fontSize: "16px" }}>OPEX</th>}
                        <th sx={{ fontSize: "16px" }}>
                          Total Benefits Accumulated Over Project Life (VOLY-based) <StyledTooltip title={VOLY_BASED_TITLE} arrow><span className="icon mt-1"></span></StyledTooltip>
                        </th>
                      </TableRow>

                      <TableRow>
                        {(simulationResults.capex != 0 && simulationResults.opex != 0) || (simulationResults.opex != 0) && <TableCell className="table-td" data-percentage={"USD " + selectedCurrency.currency_unit}>
                          {(simulationResults.opex).toFixed(2)}
                        </TableCell>}
                        <TableCell className="table-td" data-percentage={"USD " + selectedCurrency.currency_unit}>
                          {(simulationResults.totalBenefitsAccumulatedOverProjectLifeVOLYBased).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {(simulationResults.capex != 0 || simulationResults.opex != 0) && <TableRow>
                        <th sx={{ fontSize: "16px" }}>
                          Discounted Implementation Costs <StyledTooltip title={DISCONT_RATE} arrow><span className="icon mt-1"></span></StyledTooltip>
                        </th>
                        <th sx={{ fontSize: "16px" }}>
                          Benefit to Cost Ratio (VSL-based) <StyledTooltip title={VSL_BASED_RATIO_TITLE} arrow><span className="icon mt-1"></span></StyledTooltip>
                        </th>
                      </TableRow>}
                      {(simulationResults.capex != 0 || simulationResults.opex != 0) && <TableRow>
                        <TableCell className="table-td" data-percentage={"USD " + selectedCurrency.currency_unit}>
                          {(simulationResults.discountedImplementationCosts).toFixed(2)}
                        </TableCell>
                        <TableCell className="table-td">
                          {(simulationResults.benefitToCostRatio).toFixed(2)}:1
                        </TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <h6 className="mt-5" style={{ fontWeight: 500 }}>
                Emissions Benefits <StyledTooltip title={EMISSION_BENEFITS} arrow><span className="icon mt-1"></span></StyledTooltip>
              </h6>
              <Grid container spacing={1}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>CO₂ reduced</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>NOx reduced</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          className="table-td"
                          data-percentage="tonnes/year"
                        >
                          {(simulationResults.co2reduced).toFixed(2)}
                        </TableCell>
                        <TableCell
                          className="table-td"
                          data-percentage="kilograms/year"
                        >
                          {(simulationResults.noxReduced).toFixed(2)}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <th sx={{ fontSize: "16px" }}>SO₂ reduced</th>
                        <th sx={{ fontSize: "16px" }}>PM10 reduced</th>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          className="table-td"
                          data-percentage="kilograms/year"
                        >
                          {(simulationResults.so2reduced).toFixed(2)}
                        </TableCell>
                        <TableCell className="table-td" data-percentage="kilograms/year">
                          {(simulationResults.pm10reduced).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <th sx={{ fontSize: "16px" }}>PM2.5 reduced</th>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          className="table-td"
                          data-percentage="kilograms/year"
                        >
                          {(simulationResults.pm25reduced).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </div>
        </div>

        <Grid item xs={4} sx={{ m: '2rem' }} style={{ textAlign: "center" }}>
          <Button variant="outlined" onClick={() => handleSave()} endIcon={<DownloadIcon />}>Download as PDF</Button>
        </Grid>
      </div>
      <div className={activeButtonResults == "Compare projects" ? "show" : "hide"}>
        <TabContext value={valueSecondTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="mt-5">
            <TabList onChange={handleChangeSecondTab} aria-label="Tabs" id="tabsLevel3">
              <Tab label="Projects To Compare" value="1" />
              <Tab label="Results" value="2" />
            </TabList>
          </Box>
          <SelectProject projects={projects} listOfProjectsForComparison={listOfProjectsForComparison}
            setListOfProjectsForComparison={setListOfProjectsForComparison} savedProject={savedProject} />
          <CompareProject simulationResults={simulationResults} listOfProjectsForComparison={listOfProjectsForComparison} selectedCurrency={selectedCurrency} />
          {<InformationModal body="Please add at least one other simulation for comparison" modalOpen={noActionOpenModal} setModalOpen={setNoActionOpenModal}
            positiveButtonModalLabel='OK' />}
        </TabContext>
      </div>
    </div>
  );
}
