import { Bar } from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/*ChartJS.register({
  id: 'custom_canvas_background_color',
  afterUpdate: (chart, args, options) => {
    const {ctx} = chart;
    //console.log("CTX=", ctx)
    //const currentCity = document.getElementById("select-city").innerHTML;
    //console.log("Datasets", chart.data.datasets[0]);
    //chart.data.datasets[0].borderColor[2] = 'rgba(54, 162, 235, 0.2)';
    
    chart.config.data.labels.forEach((label, i) => {
      if (i == 0) {
        console.log("Index=",i + " Label=", label);
        label = "xx";
      }
      //if (label === currentCity)
      //  console.log("Change label for ", label, " ctx=", ctx.dataset)
    });
    /*ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
});*/

// Chart variables

var chartTooltip = {
  borderWidth: 0.5,
  padding: 10
}

const options = (title,YText, isPercentage) => {
  return {responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: { display: true, grid: { display: false},
      title: { display: true, text: YText }, 
      ticks: {
        display: true,
        beginAtZero: true,
        callback: function(val, index) {   
          if (isPercentage) {
            return val + '%'
          }  
          return val;
        }
      }
    },
    x: { grid: { display: false, text: YText /*,  min: 0, max: 10*/ }, 
      ticks: {
        font: function(label) {
          if (label.tick.label.toString().charAt(0) == " ")
              return { fontColor: 'blue', size: 11, weight: 'bold' }
            else
              return {size: 10}          
        },
        color: function(label) {        
          if (label.tick.label.toString().charAt(0) == " ")
              return '#23bced'
        },
        callback: function(val, index) {     
          let newVal = this.getLabelForValue(val);
          if (index == 0) 
            return [newVal];
          else if (index == this.max) 
            return [newVal];
          else return newVal
        }
      }
    },
  },  
  plugins: {
    datalabels: {
      color: 'gray',
      font: {
        weight: 'bold'
      },
      align: 'end',
      anchor: 'end',
      formatter: function(value, context) {
        if (isPercentage) {
          return value + '%';
        }
        return value;
      }
    },
    legend: { display: false}, 
    title: { display: true, text: title, 
      padding: {
      bottom: 50
  }},
    tooltip: {titleMarginBottom: 0, callbacks: {
      label: (value) => {
        //console.log("Value=",value)
        return null        
      }
    }}
    
  },
  // layout: {
  //   padding: { left: 0, right: 0}
  // }
}
};


const BasicBarChart = ({ chartData, title, YText, isPercentage, isCompare}) => {
  return (
    <div style = {isCompare ?  {position: 'relative', height:'400px',width:'340px', padding: '25px 0px'} :
     {position: 'relative', height:'480px',width:'788px', padding: '25px 0px'}}   >
      <Bar
        data={chartData}
        options={options(title,YText, isPercentage)}
      />
    </div>
  );
};

export default BasicBarChart;