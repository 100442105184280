import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Typography} from "@mui/material";

import AuthService from "../../services/auth.service";

export default function LoginDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    setError("");
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    setError("");
    const password = e.target.value;
    setPassword(password);

  };

  const validateLogin = () => {
    let valid = true;
    if (!password || !username) {
      setError("Both fields are required!")
      valid = false;
    }
    return valid;
  };

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleLogin();
    }
  }

  const [error, setError] = useState("");
  const handleLogin = (e) => {
    //e.preventDefault();
    if (validateLogin()) {
      AuthService.login(username, password).then(
        () => {
          navigate("/project");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setError(resMessage);
        }
      );
    }
  }

  return (
<div>
  <Button variant="contained" onClick={handleClickOpen}>
    Login
  </Button>
  <Dialog open={open} onClose={handleClose} id="loginForm">
    <DialogTitle>Login</DialogTitle>
    <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ alignSelf: 'flex-start', marginBottom: '16px', width: '100%' }}>
        <DialogContentText style={{ textAlign: 'left', marginBottom: '16px', width: '100%' }}>
          Please enter your login details
        </DialogContentText>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TextField
          id="username"
          value={username}
          label="Username"
          variant="outlined"
          onChange={onChangeUsername}
          error={error ? true : false}
          helperText={error ? error : null}
          required
          style={{ marginRight: '8px', flex: 1 }}  
        />
        <TextField
          id="password"
          value={password}
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={onChangePassword}
          error={error ? true : false}
          required
          onKeyPress={handleKeyPress}
          style={{ marginLeft: '8px', flex: 1 }} 
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px', alignItems: 'center' }}>
      <Typography variant="body2" style={{ marginBottom: '16px' }}>
          Login details are required to simulate urban planning actions. Please contact C40 to request login details via this{' '}
          <a href="https://forms.gle/cxjJfa4piEKxdyV27" target="_blank" rel="noopener noreferrer">
            form
          </a>{' '}
          or email{' '}
          <a href="mailto:healthyneighbourhood@C40.org">healthyneighbourhood@C40.org</a>.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {/* Changed justifyContent to 'flex-end' */}
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleLogin} style={{ marginLeft: '8px' }}>
            Login
          </Button>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</div>

  );
}