import React, { useState } from "react";

import { FormControl, TextField, Grid } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";

import "../../css/databasePage.css";
import Utils from "../../utils/miscUtils";


export default function Safety({userInputs, setUserInputs, setAdvanceErrors, advanceErrors, 
  listOfActions, includePreProject}) {

    const [crime, setCrime] = useState([
    { id: "1", label: "Crimes per 100 000 inhabitants", helperText: "Number of crimes per 100 000 inhabitants", 
    value: userInputs.numberOfCrimes},
    ]);

  const handleCrimeNumberChange = (textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = userInputs;
    let tempCrime = [...crime]
    temp.numberOfCrimes=textValue
    tempCrime[0].value = textValue;
    if(!(Utils.ValidateErrors(textValue,"crime_error", advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject, listOfActions)
    }
    setCrime(tempCrime)
    setUserInputs(temp)
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  return (
    <TabPanel value="4" className="p-0 my-4">
    <label htmlFor="" className=" mt-4 label-default">
        Safety Factors
      </label>
      <Grid container spacing={3} className="mt-0">
        {crime.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              <TextField
                id="outlined-number"
                label={data.label}
                helperText={data.helperText}
                value={data.value}
                type="number"
                variant="outlined"
                onChange={(e)=>handleCrimeNumberChange(e.target.value)}
                error={advanceErrors.findIndex(error=>error.errorType=="crime_error")>=0}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );
}
