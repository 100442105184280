import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid"
import { useState, useEffect } from "react";


import "../../css/databasePage.css";
import { FormControl, TextField } from '@mui/material';
import Utils from '../../utils/miscUtils';
import TableConfirmationModal from './tableConfirmationModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CitySpecificTableModal({title,modalOpen
,setModalOpen,table, actionType, setTable, advanceErrors, setAdvanceErrors}) {
  const [totalCityComposition, setTotalCityComposition] = useState(0);
  const [proceed, setProceed] = useState(false)

  const handleClose = () => {
    if(advanceErrors.findIndex(error=>error.errorType=="over_100_percentage_error_city_table")>=0){
      setProceed(true)
    }else{
      setProceed(false)
      setModalOpen(!modalOpen)
    }
  };

  // useEffect(()=>{
  //   let sum = table.reduce((accumulator, item) => {return accumulator + (item.mValue+item.fValue)
  //   },0);
  //   setTotalCityComposition(sum.toFixed(0))
  //   },[modalOpen])

  
  const handleCityTableChange = (ageId, sexId, textValue) =>{
    textValue = validateEmptyFields(textValue);
    const tempCityTable = [... table]
    if(sexId == "Female") {
      tempCityTable[ageId].fValue = textValue
    }else{
      tempCityTable[ageId].mValue = textValue
    }
    let sum = Utils.ValidateCityPercentage(tempCityTable,advanceErrors, setAdvanceErrors)
    //validatePercentage(tempCityTable)
    setTotalCityComposition(sum)
    setTable(tempCityTable)
  }
    
  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  // const validatePercentage = (table) =>{
  //   let tempAdvanceErrors = [...advanceErrors]
  //   const sumValues = table.reduce((accumulator, item) => {return accumulator + (item.mValue+item.fValue)
  //   },0);
  //   Utils.ValidateTotalPercentage(sumValues.toFixed(0),tempAdvanceErrors,setAdvanceErrors,"over_100_percentage_error_city_table")
  //   setTotalCityComposition(sumValues.toFixed(0));
  // }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
        <Box sx={style}>
        {title && actionType=="Walking" && <Typography id="transition-modal-title" variant="h6" component="h2">
              Pedestrian {title}
            </Typography>}
            {title && actionType=="Cycling" && <Typography id="transition-modal-title" variant="h6" component="h2">
              Cycling {title}
            </Typography>}
            <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={4}>Female</TableCell>
                <TableCell align="center" colSpan={4}>Male</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Age Cohort</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Value</TableCell>
                <TableCell align="center">Unit</TableCell>
                <TableCell align="center">Age Cohort</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Value</TableCell>
                <TableCell align="center">Unit</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {table.sort((a,b) => a.age - b.age).map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.ageF}</TableCell>
                  <TableCell align="center">{item.fSexId}</TableCell>
                  <TableCell align="center"><TextField type='number' defaultValue= {(item.fValue).toFixed(1)}
                  onChange={(e) => handleCityTableChange(item.ageF,item.fSexId,e.target.value)}></TextField></TableCell>
                  {actionType=="Walking" && <TableCell align="center">% of Pedestrians</TableCell>}
                  {actionType=="Cycling" && <TableCell align="center">% of Cyclists</TableCell>}
                  <TableCell align="center">{item.ageM}</TableCell>
                  <TableCell align="center">{item.mSexId}</TableCell>
                  <TableCell align="center"><TextField type='number' defaultValue= {(item.mValue).toFixed(1)}
                  onChange={(e) => handleCityTableChange(item.ageM,item.mSexId,e.target.value)}></TextField></TableCell>
                  {actionType=="Walking" && <TableCell align="center">% of Pedestrians</TableCell>}
                  {actionType=="Cycling" && <TableCell align="center">% of Cyclists</TableCell>}
                </TableRow>
              ))}
             </TableBody>
          </Table>
        </TableContainer>
        <Grid container item xs={12} md={12} className="mt-2">
          <FormControl fullWidth>
            <TextField
              InputProps={{
                readOnly: true
              }}
              id="automatic-filling"
              label="Total"
              helperText="Total National Composition by Percentage"
              variant="standard"
              value={totalCityComposition}
              disabled={true}
              error={advanceErrors.findIndex(error=>error.errorType=="over_100_percentage_error_city_table")>=0}
            />
          </FormControl>
          <TableConfirmationModal modalOpen={proceed} setModalOpen={setProceed} body = "The total value of the fields is different from 100%, are you sure you want to proceed?"
            positiveButtonModalLabel='Proceed' negativeButtonModalLabel='Go Back' setParentModal={setModalOpen}/>
        </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}