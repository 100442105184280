import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import { FormControl, TextField, Grid, Select } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import StyledTooltip from "../../utils/styledToolTip";
import { VALUE_OF_A_LIFE_YEAR, VALUE_OF_STATISTICAL_LIFE, DISCONT_RATE, SOURCE_TITLE} from "../../utils/tooltipConstants";

import "../../css/databasePage.css";

const currencies = [
  {
    value: "USD",
    label: "USD $",
  },
  {
    value: "EUR",
    label: "Select a currency",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export default function Economic({newAdditionalInputs,setNewAdditionalInputs, selectedCurrency, setSelectedCurrency}) {



const[textFieldsEconomicFactors,setTextFieldsEconomicFactors] = useState([
    { id:"1", label: "Value of Statistical Life", helperText: "Euros", 
    value: newAdditionalInputs.economic_factor.value_of_statistical_life },
    { id:"2",label: "Override source/rationale", value:""},
    { id:"3",label: "Value of Life Year", helperText: "Euros", value:newAdditionalInputs.economic_factor.value_of_life_year},
    { id:"4",label: "Override source/rationale", value:"" },
    { id:"5",label: "Discount rate", helperText: "Percentage", value:newAdditionalInputs.economic_factor.discount_rate },
    { id:"6",label: "Override source/rationale", value:"" },
  ]);

  const handleEconomicFactorChange = (dataLabel,textValue) =>{
    textValue=validateEmptyFields(textValue);
    let temp = newAdditionalInputs;
    let tempEconomicFactors = [...textFieldsEconomicFactors]
    switch (dataLabel) {
      case "1":
        temp.economic_factor.value_of_statistical_life=textValue
        tempEconomicFactors[0].value = textValue
        break;
        case "3":
          temp.economic_factor.value_of_life_year=textValue
          tempEconomicFactors[2].value = textValue
          break;
        case "5":
          temp.economic_factor.discount_rate=textValue
          tempEconomicFactors[4].value = textValue
          break;
      default:
        break;
    }
    setTextFieldsEconomicFactors(tempEconomicFactors)
    setNewAdditionalInputs(temp)
  }

  const handleCurrencyChange = (value) => {
    console.log(value)
    let temp = newAdditionalInputs
    temp.selectedCurrency=value;
    setSelectedCurrency(newAdditionalInputs.currency_list.find(currency => currency.id == value))
    setNewAdditionalInputs(temp)
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  return (
    <TabPanel value="2" className="p-0 my-4">
      <label htmlFor="" className=" mt-4 label-default">
        Economic Factors
      </label>
      <Grid container spacing={3} className="mt-0">
        {textFieldsEconomicFactors.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            <FormControl fullWidth>
              {index % 2 === 0 ? (
                <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                  // className="tooltip-inputbox"
                    title={
                      data.id === "1"
                        ? VALUE_OF_STATISTICAL_LIFE
                        : data.id === "3"
                        ? VALUE_OF_A_LIFE_YEAR
                        : DISCONT_RATE
                    }
                    arrow
                    // style={{
                    //   alignSelf: "end",
                    //   marginBottom: "5px",
                    // }}
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>
                  <TextField
                    id="filled-number"
                    label={data.label}
                    helperText={data.id === "5" ? data.helperText : "USD " + selectedCurrency.currency_unit}
                    variant="outlined"
                    type="number"
                    onChange={(e) => handleEconomicFactorChange(data.id, e.target.value)}
                    value={data.value}
                  />
                </>
              ) : (
                <><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
                    <span className="icon"></span>
                  </StyledTooltip></div><TextField
                      className="mt-2"
                      id="standard-search"
                      label={data.label}
                      helperText={data.helperText}
                      type="search"
                      variant="standard" /></>
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );
}
