import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import "../../css/databasePage.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationModal({title,body='You need to login in order to access this page!',modalOpen
,setModalOpen,positiveButtonModalLabel, negativeButtonModalLabel, value, setValue, onButtonPress, typeOfTab}) {
  const handleClose = () => setModalOpen(!modalOpen);
  
  const handleValue = () =>{
  if(typeOfTab=="Up"){
    setValue("2")
    setModalOpen(!modalOpen)
  }else if(typeOfTab=="Down"){
    onButtonPress()
    setModalOpen(!modalOpen)
  }}

//   React.useEffect(()=>{
//     setValue(value)
//   },[value])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            {title && <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>}
            <Typography id="transition-modal-description" sx={{ mt: 2, mb:2 }}>
              {body}
            </Typography>
            {positiveButtonModalLabel && <Button 
            variant="outlined"
            size="medium"
            style={{ width: "100%", height: "42px" }} 
            onClick={()=>{handleValue()}}>{positiveButtonModalLabel}</Button>}
            {negativeButtonModalLabel && <Button 
            variant="contained" className="btn-enabled"
            size="medium"
            style={{ width: "100%", height: "42px", marginTop: 10}} 
            onClick={()=>setModalOpen(!modalOpen)}>{negativeButtonModalLabel}</Button>}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}