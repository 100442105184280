import Feature from 'ol/Feature.js';    
import Point from "ol/geom/Point";

function CityData(id) {
    let cities = JSON.parse(localStorage.cities);
    if (cities === undefined) return;    
    let filteredCity = cities.filter(city => city.cityId === id)
    //console.log("Filtered City=", filteredCity)
    const cityFeature = new Feature({
        geometry: new Point([filteredCity[0].geom.x, filteredCity[0].geom.y])
    });     
    return cityFeature;
}

function MapData(cities) {
    if (cities === undefined) return;
    var json = { type: "FeatureCollection", features: []};
    cities.forEach(city => {
        json.features.push(
        {
            type: "Feature",
            id: city.cityId,
            geometry: { 
                type: "Point", 
                coordinates: [city.geom.x, city.geom.y]
            },
                properties: {
                score: "",
                cityId: city.cityId,
                city: city.originalCityName,
                country: city.countryName
            }
        })
    });

    //Set Cities in Storage in JSON Format
    localStorage.setItem("cityFeatures", JSON.stringify(json));
    return json;
}

const ColorLevel = () => {
  // Styles & Colors
  /*const colors = [
    "rgba(238, 255, 230, 1)", 
    "rgba(192, 245, 168, 1)", 
    "rgba(168, 223, 143, 1)", 
    "rgba(143, 204, 116, 1)", 
    "rgba(106, 168, 79, 1)", 
    "rgba(83, 136, 60, 1)", 
    "rgba(57, 106, 36, 1)", 
    "rgba(30, 74, 11, 1)",
    "rgba(15, 51, 0, 1)", 
    "rgba(1, 3, 0, 1)"
    ];*/

    const startColor = {r:253, g:231, b:36};     //rgb(253 231 36
    const midColor  = {r:121, g:209, b:81};     // rgb(121,209,81)
    const endColor  = {r:8, g:68, b:189};      //rgb(0 18 153)

    const delta_R1 = midColor.r - startColor.r
    const delta_G1 = midColor.g - startColor.g
    const delta_B1 = midColor.b - startColor.b
    const delta_R2 = endColor.r - midColor.r
    const delta_G2 = endColor.g - midColor.g
    const delta_B2 = endColor.b - midColor.b

    const step_R1 = delta_R1 / 4
    const step_G1 = delta_G1 / 4
    const step_B1 = delta_B1 / 4

    const step_R2 = delta_R2 / 4
    const step_G2 = delta_G2 / 4
    const step_B2 = delta_B2 / 4

    let R = startColor.r
    let G = startColor.g
    let B = startColor.b
    const gradient_colors = []

    let i;
    for (i=0; i<10; i++) {         
        if (i > 0 && i < 4) {
            R += step_R1
            G += step_G1
            B += step_B1
        } else if (i === 4) {
            R = midColor.r 
            G = midColor.g 
            B = midColor.b
        } else if (i === 9) {
            R = endColor.r;
            G = endColor.g;
            B = endColor.b;
        } else {     
            R += step_R2;
            G += step_G2;
            B += step_B2;
        }
        gradient_colors.push("rgb("+ R +","+ G+","+ B+")");
    }
    //console.log("Gradiend_colorw=", gradient_colors);
    return gradient_colors;
}

const CreateNationalCompositionTable = (table ,minAge, maxAge) =>{
    let temp = [];
    let total = (addArrayValues(table,minAge,maxAge));
    let i;
      for (i=0; i<96; i++) {
          if(i<minAge || i>maxAge)
              temp.push({age:i, proxyValue: 0.0})
          else{
            temp.push({age:i, proxyValue: ((table[i].mProxy+table[i].fProxy)/total)*100})
            }
        };
    return temp;
  }

  const addArrayValues = (table, minAge, maxAge) => {
    let sumM = 0;
    let sumF = 0;

    for (let i = minAge; i <= maxAge; i += 1) {
      sumM += table[i].mProxy;
      sumF += table[i].fProxy;
    }
    return sumM+sumF;
  }

  const ValidateCityPercentage = (table, advanceErrors,setAdvanceErrors) =>{
    const sumValues = table.reduce((accumulator, item) => {return accumulator + (item.mValue+item.fValue)
    },0);
    ValidateTotalPercentage(sumValues.toFixed(0),advanceErrors,setAdvanceErrors,"over_100_percentage_error_city_table")
    return sumValues.toFixed(0);
  }

  const ValidateNationalPercentage = (table, advanceErrors,setAdvanceErrors) =>{
    const sumProxyValues = table.reduce((accumulator, item) => {return accumulator + item.proxyValue
    },0);
    ValidateTotalPercentage(sumProxyValues.toFixed(0),advanceErrors,setAdvanceErrors,"over_100_percentage_error_national_table")
    return sumProxyValues.toFixed(0);
  }

  const ValidateTotalPercentage = (value, advanceErrors, setAdvanceErrors, errorType) => {

    if(value !== 100){
        if(!(advanceErrors.find(error => error.errorType === errorType))){
          advanceErrors = [...advanceErrors, {
            errorMessage:"The total value of all the fields cannot be different from 100%",
            errorType: errorType}]
          setAdvanceErrors(advanceErrors);
        }
      }else if(value === 100 && advanceErrors.findIndex(error=>error.errorType === errorType) >= 0){
        console.log("FOUND ERROR")
        let tempIndex = advanceErrors.indexOf(error=>error.errorType === errorType);
        advanceErrors.splice(tempIndex,1)
        setAdvanceErrors(advanceErrors)
      }else{
        setAdvanceErrors(advanceErrors)
      }
      
  }

  //Verify if there are errors, and add to array if they exist
  const VerifyFields = (newAdditionalInputs, userInputs, advanceErrors, setAdvanceErrors, includePreProject = true, listOfActions) => {

    let tempAdvanceErrors = [...advanceErrors]
    let error = false;
    if(advanceErrors.length>0){
      error=true
    }
    
      if((userInputs.assessmentTime===0 || userInputs.assessmentTime==="") && !(tempAdvanceErrors.find(error => error.errorType === "assessment_time_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
        errorMessage: "Please make sure to fill the Assessment Time on the Project Parameters Tab",
        errorType: "assessment_time_error"}]);
      }

      if((newAdditionalInputs.proportion_project_other_modes.total_mode_shift===0 || newAdditionalInputs.proportion_project_other_modes.total_mode_shift==="0")
        && !(tempAdvanceErrors.find(error => error.errorType === "mode_share_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage:"Please make sure to fill the Total Mode Shift on the Project Paramaters tab",
          errorType: "mode_share_error"}]);
        }
        
      if((newAdditionalInputs.pre_project_conditions_walking.trips_per_day===0 || newAdditionalInputs.pre_project_conditions_walking.trips_per_day==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "trips_per_day_error")) && userInputs.typeOfSimulation!==0 && includePreProject){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Trip Per Day (Pre) on the Project Parameters Tab",
          errorType: "trips_per_day_error"}]);
      }

      if((newAdditionalInputs.pedestrian_travel_characteristics.trips_per_year_per_user===0 || newAdditionalInputs.pedestrian_travel_characteristics.trips_per_year_per_user==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "trips_per_year_per_user_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Trip Per Year Per User on the Project Parameters Tab",
          errorType: "trips_per_year_per_user_error"}]);
      }

      if((newAdditionalInputs.post_project_conditions_walking.trips_per_day===0 || newAdditionalInputs.post_project_conditions_walking.trips_per_day==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "trips_per_day_post_error")) && userInputs.typeOfSimulation!==0 && includePreProject){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Trip Per Day (Post) on the Project Parameters Tab",
          errorType: "trips_per_day_post_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[0].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[0].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "conorary_heart_disease_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Conorary Heart Disease field on the General Parameters -> Health Tab",
          errorType: "conorary_heart_disease_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[1].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[1].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "stroke_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Stroke field on the General Parameters -> Health Tab",
          errorType: "stroke_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[2].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[2].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "dementia_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Dementia field on the General Parameters -> Health Tab",
          errorType: "dementia_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[3].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[3].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "diabetes_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Type II Diabetes field on the General Parameters -> Health Tab",
          errorType: "diabetes_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[4].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[4].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "depression_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Depression (>29 years) field on the General Parameters -> Health Tab",
          errorType: "depression_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[5].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[5].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "breast_cancer_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Breast Cancer (female) field on the General Parameters -> Health Tab",
          errorType: "breast_cancer_error"}]);
      }

      if((newAdditionalInputs.morbidity_risk_reduction[6].minutes_per_week===0 || newAdditionalInputs.morbidity_risk_reduction[6].minutes_per_week==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "colon_cancer_error"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Colon Cancer (female) field on the General Parameters -> Health Tab",
          errorType: "colon_cancer_error"}]);
      }

      if((newAdditionalInputs.physical_benefits_params[0].reference_volume===0 || newAdditionalInputs.physical_benefits_params[0].reference_volume==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "reference_volume_error_cycling"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Reference Volume (cycling) field on the General Parameters -> Health Tab",
          errorType: "reference_volume_error_cycling"}]);
      }

      if((newAdditionalInputs.physical_benefits_params[1].reference_volume===0 || newAdditionalInputs.physical_benefits_params[1].reference_volume==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "reference_volume_error_walking"))){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Reference Volume (walking) field on the General Parameters -> Health Tab",
          errorType: "reference_volume_error_walking"}]);
      }

      if((newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_share<1 || newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_share==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "load_factor_car_moto_share_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Car & motorcycle (ride share) field on the General Parameters -> Urban Mobility",
          errorType: "load_factor_car_moto_share_error"}]);
      }

      if((newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_solo<1 || newAdditionalInputs.vehicle_load_factors.car_and_motorcycle_solo==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "load_factor_car_moto_solo_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Car & motorcycle (solo driver) field on the General Parameters -> Urban Mobility",
          errorType: "load_factor_car_moto_solo_error"}]);
      }

      if((newAdditionalInputs.vehicle_load_factors.public_transport<1 || newAdditionalInputs.vehicle_load_factors.public_transport==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "load_factor_public_transport_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Public Transport(bus etc) field on the General Parameters -> Urban Mobility",
          errorType: "load_factor_public_transport_error"}]);
      }

      if((newAdditionalInputs.vehicle_load_factors.taxi_and_minibus<1 || newAdditionalInputs.vehicle_load_factors.taxi_and_minibus==="0") && 
      !(tempAdvanceErrors.find(error => error.errorType === "load_factor_taxi_minibus_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure to fill the Taxi/Minibus field on the General Parameters -> Urban Mobility",
          errorType: "load_factor_taxi_minibus_error"}]);
      }

      if((parseFloat(newAdditionalInputs.cyclists_population_gender.female_percentage) + 
      parseFloat(newAdditionalInputs.cyclists_population_gender.male_percentage)!==100) &&
      !(tempAdvanceErrors.find(error => error.errorType === "cyclist_population_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure that the Percentage of cyclists equals a total of 100%, on the General Parameters -> Urban Mobility",
          errorType: "cyclist_population_error"}]);
      }

      if(newAdditionalInputs.proportion_project_origin_modes.total!==100 &&
      !(tempAdvanceErrors.find(error => error.errorType === "origin_mode_total_error")) && userInputs.typeOfSimulation!==0){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
          errorMessage: "Please make sure that the Total Percentage of Project induced pedestrians by origin modes equals a total of 100%, on the Project Parameters tab",
          errorType: "origin_mode_total_error"}]);
      }

      if((userInputs.numberOfCrimes===0 || userInputs.numberOfCrimes==="") && !(tempAdvanceErrors.find(error => error.errorType === "crime_error")) && 
      listOfActions.find(item => item.area === 'Urban crime rate')){
        error = true;
        setAdvanceErrors(tempAdvanceErrors => [...tempAdvanceErrors, {
        errorMessage: "Please make sure to fill the Number of Crimes on the General Parameters -> Safety",
        errorType: "crime_error"}]);
      }

       return !error
    }

  //Used to validate if Error has been Fixed
  const ValidateErrors = (value, errorType, advanceErrors, setAdvanceErrors) => {
    let tempAdvanceErrors = [...advanceErrors]
    if(value!=="" && value!==0){
      let tempList = tempAdvanceErrors.filter(error=>error.errorType!==errorType);
      setAdvanceErrors(tempList)
      return true;
    }
    return false;
  }


  const ImpactConverter = (action) => {
    if(action.impactListType==='List'){
      return action.impactListValue
    }else{
      return (action.impact).replace(/(\d+).*/, '$1');
    }
  }

  const UpdateAdvanceError = (userInputs, advanceErrors, setAdvanceErrors) =>{
    let tempAdvanceErrors = [...advanceErrors]
    let tempList = tempAdvanceErrors.filter( error=>(error.errorType!=="mode_share_error" &&
      error.errorType!=="trips_per_day_error" &&
      error.errorType!=="trips_per_day_post_error" &&
      error.errorType!=="trips_per_year_per_user_error" &&
      error.errorType!=="load_factor_car_moto_share_error" &&
      error.errorType!=="load_factor_car_moto_solo_error" &&
      error.errorType!=="load_factor_public_transport_error" &&
      error.errorType!=="load_factor_taxi_minibus_error" &&
      error.errorType!=="cyclist_population_error" &&
      error.errorType!=="origin_mode_total_error"
      ));
    setAdvanceErrors(tempList)
  return tempList;
  }

const Utils = {
  MapData, ColorLevel, CityData, CreateNationalCompositionTable, ValidateCityPercentage, ValidateNationalPercentage, 
    ValidateTotalPercentage, ImpactConverter, VerifyFields, ValidateErrors, UpdateAdvanceError
}
  
export default Utils;