import sequence from "../assets/sequence.svg"
import indicator_table from "../assets/indicator_table.pdf"
import action_table from "../assets/action_table.pdf"
import pdf_icon from "../assets/pdf_icon.png"

import "../css/databasePage.css";

const steps = ["Introduction", "Setup", "Project", "Result"];

const rows = [
  { id: "Creating Complete Neighborhoods", name: "Action 2", impact: "Medium" },
  { id: "Creating Complete Neighborhoods", name: "Action 1", impact: "150 %" },
];
const rowsSecond = [
  {
    category: "Cycling",
    ageMin: "20",
    ageMax: "64",
    relRisk: "0,90",
    refVol: "100",
    capsImprove: "45%",
    capsMinWeek: "447",
  },
  {
    category: "Walking",
    ageMin: "20",
    ageMax: "74",
    relRisk: "0,89",
    refVol: "168",
    capsImprove: "30%",
    capsMinWeek: "460",
  },
];

export default function DatabasePage() {
  // const [value1, setValue1] = React.useState("Assessment Time");
  return (
    <div className="container" style={{marginTop: "64px"}}>
      <div className="container">
      <h5 style={{marginBottom: "32px"}}>Database Sources, References and Terms</h5>
<p>This page gives you a summary of how the Healthy Neighbourhood Explorer works.</p>

<ul>
  <li>Section 1 explains the logic underpinning the tool, that is how urban actions create health, economic and environmental benefits for local communities</li>
  <li>Section 2 describes the Healthy Neighbourhood indicators in more detail, including the calculation approach and data sources</li>
  <li>Section 3 describes the relationship between urban actions and indicator scores</li>
  <li>Section 4 presents general data used and the sources</li>
  <li>Section 5 provides a glossary of all terms relevant to the tool and methodology</li>
</ul>

<p>To learn more about the approach for estimating health impacts from increased walking and cycling see C40's Walking & Cycling Benefits tool <a target="_blank" rel="noopener noreferrer" href="https://www.c40.org/wp-content/uploads/2022/02/C40-Walking-and-Cycling-Benefits-Tool-Methodology.pdf">methodology report</a>.</p>

<p><strong>DISCLAIMER:</strong> The Healthy Neighbourhood Explorer is a fully functional Beta version of the tool. Please <a target="_blank" rel="noopener noreferrer"
href="https://drive.google.com/file/d/1a7sZPyg_e9GPagK5qAyJlF_PzXxU1tBV/view"> refer to this</a> FAQ for commonly asked questions by users of the Healthy Neighbourhood Explorer.  We welcome all feedback so that the tool can be continuously improved - please email <a href="mailto:healthyneighbourhood@C40.org">healthyneighbourhood@C40.org</a>.</p>
<p>Results from this tool are modelled results providing the scale of potential impacts within a local community. These should not be relied upon for investment decisions.
  Local factors and contexts can mean that the beneficial outcomes are not realised as predicted by models.</p>
<p>
C40's Chinese cities are currently not included in the tool due to issues of comparability to other cities. The municipalities of Chinese cities cover significant areas beyond the urban core of the cities, including extensive rural areas for which the indicators favouring density of housing and services are not appropriate. We will be working to find solutions to this as part of iterative improvements of the tool.
</p>
<h6 className="p-0 my-5">Section 1. How urban actions impact health</h6>
<p>The general logic underpinning the tool is represented below. It shows how an action creates a change in an indicator, this in turn drives an increase in walking and cycling (physical activity). Finally, emissions are reduced by displacing use of polluting vehicles and other health benefits arise from the increase in physical activity.</p>
  <p><img src={sequence} alt="representation of the General equation underpinning the tool" width="768" height="126"></img></p>
  <p>Each causal link represented by the blue arrows use quantitative factors (often described as multipliers) found within peer-reviewed literature. The literature has analysed multiple case studies and through meta-analysis provides generalisable quantitative relationship. Section 3 of this page, provides more detail on the quantitative relationship between urban actions, indicators and physical activity.</p>
  <h6 className="p-0 my-5">Section 2. Healthy Neighbourhood Explorer indicators: description and data sources</h6>
  <p>The Healthy Neighbourhood Index creates a weighted score out of four mobility-related indicators and five liveability-related indicators. Four of these indicators are derived from analysing Open Street Map data. OpenStreetMap data is built by a community of mappers that contribute and maintain data. The quality of data may vary per city. Another four indicators are extracted from different trusted publicly available datasets. The indicator for urban crime rate is the only indicator which users must add their own data due to a lack of consistent and globally representative datasets for cities. Details on the data sources used for indicators, how the indicators were calculated and computed into scores can be found in &nbsp; 
    <a href="https://drive.google.com/file/d/1YuHJGTQKHSPqgb8d8rl7z0lHSLb--m3a/view?usp=sharing"  rel="noopener noreferrer" target="_blank">this linked table</a>.</p>
    <a href="https://drive.google.com/file/d/1YuHJGTQKHSPqgb8d8rl7z0lHSLb--m3a/view?usp=sharing" rel="noopener noreferrer" target="_blank"><img width = "100" height = "100" src={pdf_icon}></img></a>
  <h6 className="p-0 mt-5">Section 3. Relationship between urban actions, indicator scores and physical activity</h6>
  <label htmlFor="" className="p-0 my-4 label-default">
  <strong>Section 3.1. Quantitative relationship between indicators and physical activity</strong>
  </label>
    <p>The health and GHG emissions benefits expected from urban design interventions and policies (actions) simulated in the tool result from the uptake in walking and cycling triggered by improved mobility and liveability.&nbsp;</p>
  <p>The tool allows the user to introduce actions within an area which affect a combined score across the indicators. These in turn affect the uptake of walking and cycling in the area by making these more attractive transport options for residents. The health benefits are calculated from the increase in walking and cycling. The following describe the quantitative relationship between increases in indicator scores and walking and cycling metrics:</p>
  <p><strong>WALKING:</strong> A 10 point increase in the Healthy Neighbourhood index is associated with an increase of 9 minutes of walking per walk among the population<br />
  </p>
  <p>Reference: Hirsh et al. (2013). <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/23867022/">Walk Score® and Transit Score® and walking in the multi-ethnic 
  study of atherosclerosis</a>. Aug;45(2):158-66. doi: 10.1016/j.amepre.2013.03.018</p>
  <p><strong>CYCLING</strong>: A 10 point increase in the Healthy Neighbourhood index is associated with a 0.5% increase in cycling mode share</p>
  <p>Reference: Winters et al. (2016). <a target="_blank" rel="noopener noreferrer" href="https://open.library.ubc.ca/media/stream/pdf/52383/1.0308593/3">
  Bike Score®: Associations between urban bikeability and cycling behavior in 24 cities</a>.
   International Journal of Behavioral Nutrition and Physical Activity. DOI 10.1186/s12966-016-0339-0</p>
  <label htmlFor="" className="p-0 my-4 label-default">
  <strong>Section 3.2. Quantitative impact of urban actions on indicators</strong>
  </label>
  <p>The Healthy Neighbourhood Explorer can model the health and emissions benefits of 26 urban actions. In order to model these actions, the user is asked to decide the impact level of urban action tested on the selected spatial grid. For instance, when testing the action ‘Add retail space’, users are asked to input the number of new retail units to be added within the selected spatial grid. For certain actions, the action impact level has been pre-defined and users can select an impact level. Overall, these urban actions increase the relevant indicator score using a multiplier relationship. 
     The urban actions included in this tool are described in more detail, with the description of calculation logic and impact levels for each action,&nbsp;
   <a href={action_table} rel="noopener noreferrer" target="_blank">in this linked table</a>.</p>
   <a href={action_table} rel="noopener noreferrer" target="_blank"><img width = "100" height = "100" src={pdf_icon} alt="PDF Icon"></img></a>
  <h6 className="p-0 my-5">4. Default data and assumptions</h6>
  <table width="100%" className="borderedTable">
    <tbody>
      <tr>
        <th scope="col">Data type</th>
        <th scope="col">Item</th>
        <th scope="col">Source</th>
        <th scope="col">Link to website</th>
      </tr>
      <tr>
        <td>Population data</td>
        <td>National - Population and Age structure</td>
        <td>IHME; Global Burden of Disease (GDB) National Population; 2019 </td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://ghdx.healthdata.org/gbd-2019">Link to IHME GBD - population estimates</a></td>
      </tr>
      <tr>
        <td rowSpan="4">Health</td>
        <td>Mortality rates - national</td>
        <td>IHME; Global Burden of Disease (GDB) Deathrate Data; 2019</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://ghdx.healthdata.org/gbd-2019">Link to IHME GBD - Under-5 Mortality and Adult Mortality </a></td>
      </tr>
      <tr>
        <td>Years lived with disability (YLDs) - national</td>
        <td>IHME; Global Burden of Disease (GDB) Deathrate Data &amp; National YLDs ; 2019 </td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://ghdx.healthdata.org/gbd-2019">Link to IHME GBD - Global Burden of Disease Study 2019 (GBD 2019) Disability Weights</a></td>
      </tr>
      <tr>
        <td>ITHIM physical activity risk reduction factors</td>
        <td>University of Cambridge CEDAR; ITHIM model ; 2018</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.mrc-epid.cam.ac.uk/research/research-areas/public-health-modelling/ithim/">
        Link to University of Cambridge; MRC Epidemiology Unit; ITHIM</a></td>
      </tr>
      <tr>
        <td>Physical activity benefit parameters </td>
        <td>World Health Organization; Health Economic Assessment Tool (HEAT) for walking and cycling; 2017</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.who.int/tools/heat-for-walking-and-cycling">Link to HEAT Methodology and User Guide </a></td>
      </tr>
      <tr>
        <td rowSpan="6">Mobility</td>
        <td>Mode share </td>
        <td>Environmental Insights Explorer (extracted&nbsp; in June 2023) </td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://insights.sustainability.google/">Link to Gloogle EIE</a></td>
      </tr>
      <tr>
        <td>Age range of cyclists and pedestrians</td>
        <td>World Health Organization; Health Economic Assessment Tool (HEAT) for walking and cycling; 2017</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.who.int/tools/heat-for-walking-and-cycling">Link to HEAT Methodology and User Guide </a></td>
      </tr>
      <tr>
        <td>Default cyclist and pedestrian population composition </td>
        <td>C40 Assumption based on the Population and age structure&nbsp; </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>Minutes of Activity per cyclist profile</td>
        <td>Environmental Insights Explorer (extracted&nbsp; in June 2023) </td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://insights.sustainability.google/">Link to Google EIE </a></td>
      </tr>
      <tr>
        <td>Default trips distance</td>
        <td>Environmental Insights Explorer (extracted in June 2023) </td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://insights.sustainability.google/">Link to Google EIE </a></td>
      </tr>
      <tr>
        <td>Default trips per year per user</td>
        <td>Assumption of 250 trips per year per user based on World Health Organization; Health Economic Assessment Tool (HEAT) for walking and cycling; 2017</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.who.int/tools/heat-for-walking-and-cycling">Link to HEAT Methodology and User Guide </a></td>
      </tr>
      <tr>
        <td rowSpan="4">Emissions</td>
        <td>Vehicle load factor</td>
        <td>C40; Climate Action for Urban Sustainability Database, Regional Assumptions; 2016</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.c40knowledgehub.org/s/article/Climate-action-for-URBan-sustainability-CURB-scenario-planning-tool?language=en_US">Link to C40 - CURB tool</a></td>
      </tr>
      <tr>
        <td>Fuel fleet composition</td>
        <td>ICCT; 2012</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://theicct.org/">Link to ICCT</a></td>
      </tr>
      <tr>
        <td>Vehicle fuel efficiency per vehicle class</td>
        <td>National Transit Database and Sandia National Database ; 2016</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://www.sandia.gov/">Link to Sandia</a></td>
      </tr>
      <tr>
        <td>Per mile fuel emissions</td>
        <td>Argonne Lab ; Greet Model ; 2018</td>
        <td><a target="_blank" rel="noopener noreferrer" href="https://greet.es.anl.gov/">Link to Argonne Lab - Greet model</a></td>
      </tr>
    </tbody>
  </table>
	<h6 className="p-0 my-5">5. Terminology</h6>

  
  <p style={{textAlign:"center"}}><strong>General Terms</strong></p>
  <table width="100%" className="borderlessColumn">
    <tbody>
      <tr>
        <th scope="col" style={{ textAlign: 'right', paddingRight: '1em' }}>Term</th>
        <th scope="col">Definition</th>
      </tr>
      <tr>
        <td className="highlightedColumn">Action</td>
        <td>Any urban design intervention, policy or investment initiated by urban public officials with the intention to enhance liveability and mobility and provide some contribution to climate mitigation or adaptation.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Indicator</td>
        <td>A direct or indirect measure that shows what a situation is like. An indicator always represents part of a system.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Start year of project</td>
        <td>Year of the project implementation.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Project assessment period</td>
        <td>Number of years until the project is fully implemented and accessible to the public. The project lifetime impacts the economic benefits.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Project area</td>
        <td>The selected area within the project simulation that an action will be implemented upon. The tool allows for simulation of actions on a single 1km<sup>2</sup> grid.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">15-minute city</td>
        <td>The 15-minute city weaves together strands of climate action and people-centric urban development to ensure that residents of all ages, backgrounds and abilities can meet their daily needs locally, in a short walk or bike ride from home, as well as improve public transport across the city.</td>
      </tr>
    </tbody>
  </table>
  
 <p style={{marginTop:"32px", textAlign:"center"}}><strong>Health Terms</strong></p>
  <table width="100%" className="borderlessColumn">
    <tbody>
      <tr>
        <th scope="col" style={{ textAlign: 'right', paddingRight: '1em' }}>Term</th>
        <th scope="col">Definition</th>
      </tr>
      <tr>
        <td className="highlightedColumn">Death averted</td>
        <td>A valid and meaningful way of capturing some important aspects of mortality, across the population in any given year and related to current levels of physical activity. Important to note that deaths are not &lsquo;saved&rsquo; by increased physical activity but rather shifted to older ages. </td>
      </tr>
      <tr>
        <td className="highlightedColumn">Mortality</td>
        <td>Disease number or rate in a population.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Morbidity</td>
        <td>Death number or rate in a population.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">WHO Physical activity target</td>
        <td>Adults aged 18–64 should do at least 150 minutes of moderate-intensity aerobic physical activity throughout the week.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Vehicle load factor</td>
        <td>Average occupancy of vehicles.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Years lived with disability (YLD)</td>
        <td>Measures the number of years persons are burdened by living with a disease or disability. YLD is determined by the number of years disabled weighted by level of disability caused by a disability or disease.</td>
      </tr>
    </tbody>
  </table>
  
	<p style={{marginTop:"32px", textAlign:"center"}}><strong>Mobility Terms</strong></p>
  <table width="100%" className="borderlessColumn">
    <tbody>
      <tr>
        <th scope="col" style={{ textAlign: 'right', paddingRight: '1em' }}>Term</th>
        <th scope="col">Definition</th>
      </tr>
      <tr>
        <td className="highlightedColumn">Average trip duration</td>
        <td>Average time (in minutes) of walking or cycling per person for each trip.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Average trip distance</td>
        <td>Average length of walking or cycling per person per trip.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Average trip speed</td>
        <td>Average speed of walking or cycling per person per trip, taking into account stops at crossing or due to obstacles. The WHO recommended average walking speed is 5.3 km/h, and cycling speed is 14.0 km/h.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Mode share</td>
        <td>The mode share shows the percentage of travellers using a particular mode of transport compared to the ratio of all trips made.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Mode shift</td>
        <td>The percentage switch from one transport mode to another, e.g switching from driving to walking. </td>
      </tr>
      <tr>
        <td className="highlightedColumn">Trip</td>
        <td>One way journey, e.g commuting from home to the office.</td>
      </tr>
    </tbody>
  </table>
 
	<p style={{marginTop:"32px", textAlign:"center"}}><strong>Economic Terms</strong></p>
  <table width="100%" className="borderlessColumn">
    <tbody>
      <tr>
        <th scope="col" style={{ textAlign: 'right', paddingRight: '1em' }}>Term</th>
        <th scope="col">Definition</th>
      </tr>
      <tr>
        <td className="highlightedColumn">CAPEX</td>
        <td>Capital expenditure, or CAPEX, are funds used to acquire, upgrade, and maintain physical assets such as property, industrial buildings, or equipment. </td>
      </tr>
      <tr>
        <td className="highlightedColumn">Discount rate</td>
        <td>Since economic benefits occurring in the future are generally considered less valuable than those occurring in the present, economists apply a discount rate to future benefits. Common discount rates are usually available from governments. The WHO advises to use 5% as a default value. </td>
      </tr>
      <tr>
        <td className="highlightedColumn">OPEX</td>
        <td>Operating expenses are the costs for running the operations on a daily basis.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Value of life year (VOLY)</td>
        <td>The monetary value of a year of life lost. It is based on studies that assess the willingness to pay for reducing mortality risks associated with air pollution. </td>
      </tr>
      <tr>
        <td className="highlightedColumn">Value of statistical life (VSL)</td>
        <td>The benefit of preventing a fatality, defined as the additional cost that individuals would be willing to bear for improvements in safety/reductions in risk that reduce the expected number of fatalities by one.</td>
      </tr>
      <tr>
        <td className="highlightedColumn">Difference between VOLY and VSL</td>
        <td><p>VSL is the monetary value assigned to a &quot;whole&quot; life, whereas VOLY is the value for a single year of life. VSL will therefore be much greater than VOLY.<br></br>
            You can think of VSL as how much people are willing to pay to prevent a fatality and VOLY as how much people are willing to pay to live for an extra year.</p></td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  );
}
