import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";


import "../../css/databasePage.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function YLDTableModal({title,modalOpen
,setModalOpen,table}) {
  const handleClose = () => setModalOpen(!modalOpen);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            {title && <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>}
            <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" colSpan={2}>Ischemic Heart Disease</TableCell>
                <TableCell align="center" colSpan={2}>Ischemic Stroke</TableCell>
                <TableCell align="center" colSpan={2}>Diabetes Mellitus</TableCell>
                <TableCell align="center" colSpan={2}>Breast Cancer</TableCell>
                <TableCell align="center" colSpan={2}>Colon And Rectum Cancer</TableCell>
                <TableCell align="center" colSpan={2}>Depressive Disorders</TableCell>
                <TableCell align="center" colSpan={2}>Alzheimer Disease And Other Dementias</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Age</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.Age}</TableCell>
                  <TableCell align="center">{item.mIschemicHeartDisease}</TableCell>
                  <TableCell align="center">{item.fIschemicHeartDisease}</TableCell>
                  <TableCell align="center">{item.mIschemicStroke}</TableCell>
                  <TableCell align="center">{item.fIschemicStroke}</TableCell>
                  <TableCell align="center">{item.mDiabetesMellitus}</TableCell>
                  <TableCell align="center">{item.fDiabetesMellitus}</TableCell>
                  <TableCell align="center">{item.mBreastCancer}</TableCell>
                  <TableCell align="center">{item.fBreastCancer}</TableCell>
                  <TableCell align="center">{item.mColonAndRectumCancer}</TableCell>
                  <TableCell align="center">{item.fColonAndRectumCancer}</TableCell>
                  <TableCell align="center">{item.mDepressiveDisorders}</TableCell>
                  <TableCell align="center">{item.fDepressiveDisorders}</TableCell>
                  <TableCell align="center">{item.mAlzheimerDiseaseAndOtherDementias}</TableCell>
                  <TableCell align="center">{item.fAlzheimerDiseaseAndOtherDementias}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}