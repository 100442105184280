import LogosImage from "../assets/logos.png"

import "../css/databasePage.css";

export default function About() {
  // const [value1, setValue1] = React.useState("Assessment Time");
  return (
    <div className="container" style={{marginTop: "64px"}}>
      <div className="container">
<p>C40 cities led the development of the Healthy Neighbourhood Explorer with support from key partners shown below.</p>
<br/>
<p><strong>Funder:</strong> Novo Nordisk</p>
<br />
<p><strong>Technical partners:</strong> Arup, Chair Enterpreneurship Territory Innovation - Chaire-ETI and Spacescape</p>
<br />
<p><strong>Tool developer:</strong> Inetum</p>
<br />
<p><strong>Contributors:</strong></p>
<p>Technical input and review of the quantitative impact of urban actions on Healthy Neighbourhood indicators by: 
Markus Berensson (C40), Kauê Braga (C40), Mariana Nicoletti (C40), Christina Lumsden (C40), Honorine van den Broek d’Obrenan (C40), 
Daniel Firth (C40), Carl Higgs (RMIT), Melanie Lowe (RMIT), Carolyn Daher (ISGlobal), Albert Bach (ISGlobal), 
Mark Nieuwenhuisjen (ISGlobal), Marta Rofin Serrà (Bax & Company), Taylor Reich (ITDP), Mark Petersen (NREP), 
Johan Vinther (Novo Nordisk), Vivian Pun (C40)</p>
<div style={{ marginTop: `115px` }}>
  <p><img src={LogosImage} alt="representation of the General equation underpinning the tool"></img></p>
  </div>
    </div>
    </div>
  );
}
