import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import mix from "../../assets/mix.png";
import fifteenMin from "../../assets/only_type_15_min_city.png"
import other from "../../assets/only_type_other.png"

import "../../css/databasePage.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function CasualChainModal({title, simulationType,modalOpen, setModalOpen}) {
  const handleClose = () => setModalOpen(!modalOpen);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            {title && <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>}
              {simulationType==='no' && 
              <img width = "1200" height = "750" src={other}></img>}
              {simulationType==='yes' && 
              <img width = "1200" height = "750" src={fifteenMin}></img>}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}