import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import "../../css/databasePage.css";
import { C40_EMAIL } from "../../utils/warningConstants";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: 800, // You can adjust this value as needed
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function InformationModal({title,body='You need to login in order to access this page!',modalOpen
,setModalOpen,positiveButtonModalLabel, simulationError=false}) {
  const handleClose = () => setModalOpen(!modalOpen);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
        <Box sx={{ ...style, flex: 1 }}>
            {title && <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>}
            {!simulationError && <Typography id="transition-modal-description" sx={{ mt: 2, mb:2 }}>
              {body}
            </Typography>}
            {simulationError && <Typography id="transition-modal-description" sx={{ mt: 2, mb:2 }}>
            There was an issue while processing the simulation. Please contact{' '}
            <a href={`mailto:${C40_EMAIL}`}>{C40_EMAIL}</a> for more information.
            </Typography>}
            {positiveButtonModalLabel && <Button 
            variant="outlined"
            size="medium"
            style={{ width: "100%", height: "42px" }} 
            onClick={()=>{setModalOpen(!modalOpen)}}>{positiveButtonModalLabel}</Button>}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}