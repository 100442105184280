import React, { useState } from "react";

import { FormControl, TextField, Grid, Checkbox } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import Paper from "@mui/material/Paper";

import "../../css/databasePage.css";
import InformationModal from "../basicModal/informationModal";


export default function SelectProject({projects, listOfProjectsForComparison, 
  setListOfProjectsForComparison, savedProject}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [ bodyText, setBodyText] = useState("");
  const [checkedState, setCheckedState] = useState(
  () => new Array(projects.length).fill(false));
  
  const disabledColor = {
    backgroundColor:'#f5f5f5',
    color:'#f5f5f5'
  }

  const changeListForComparison = (project, isChecked, index) => {
    let tempList = [{}];
    let tempTest = [... listOfProjectsForComparison]
    let tempChecked = [... checkedState]
    if(isChecked && validateListSize(tempTest)){
      tempList = [... listOfProjectsForComparison, {
      projectNumber:project.projectNumber,
      projectName:project.projectName,
      projectDescription: project.projectDescription,
      projectInfo: project.projectInfo,
      actionSimulationId: project.actionSimulationId,
      creationDate: project.creationDate,
    }]
      tempChecked[index]=isChecked
      setCheckedState(tempChecked)
      setListOfProjectsForComparison(tempList)
    }else if(isChecked && !validateListSize(tempTest)){
      setBodyText("You can only compare up to 5 simulations")
      setModalOpen(true);
    }else if(!isChecked){
        tempList = listOfProjectsForComparison.filter((item) => item.projectNumber != project.projectNumber)
        tempChecked[index]=isChecked
        setCheckedState(tempChecked)
        setListOfProjectsForComparison(tempList)
    }
  }

  const validateListSize = (tempList) => {
    if(tempList.length>4){
      return false
    }else{
      return true
    }
  }

  return (
    <TabPanel value="1" className="p-0 my-4">
      <Grid item xs={12} md={12} className="d-flex justify-content-center mt-5">
        <TableContainer>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                <TableRow key={index} style={project.projectNumber==savedProject.projectNumber ? disabledColor: {}}>
                  <TableCell><Checkbox
                    checked={project.projectNumber==savedProject.projectNumber ? true : checkedState[index]}
                    disabled = {(!checkedState[index] && listOfProjectsForComparison>4)||project.projectNumber==savedProject.projectNumber}
                    //{project.projectNumber==savedProject.projectNumber ? true : false}
                    onChange={(e)=>changeListForComparison(project, e.target.checked, index)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /></TableCell>
                  <TableCell>{project.projectName}</TableCell>
                  <TableCell>{project.projectDescription}</TableCell>
                  <TableCell>{project.creationDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {<InformationModal body={bodyText} modalOpen={modalOpen} setModalOpen={setModalOpen} positiveButtonModalLabel='OK'/>}
    </TabPanel>
  );
}
