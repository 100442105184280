//GENERIC
export const SOURCE_TITLE = "If you are using your own data instead of the default data, use this field to specify the source/rationale of the data entered.";

// HEALTH TAB

export const MORBIDITY_RELATIVE_RISK_REDUCTION_TITLE = "The relative risk reduction factors determine the percentage of change in chances of having a disease, considering the minutes of physical activity. These multipliers have been determined based on existing tools, and reviewed by experts from the University Cambridge and Zurich. Do only change those factors if you have city-specific studies.";
export const PHYSICAL_ACTIVITY_BENEFIT_PARAMETERS_TITLE = "The relative risk reduction factors determine the percentage of change in chances of dying, considering the minutes of physical activity. It uses a relative risk of 0.90 for cycling (representing 100 minutes of cycling per week as a common exposure level, equivalent to meeting the recommended level of at least 150 minutes of moderate-intensity physical activity per week), and 0.89 for walking (representing 170 minutes of walking per week). These multipliers have been determined based on existing tools, and reviewed by experts from the University Cambridge and Zurich. Do only change those factors if you have city-specific studies.";
export const CAPES_PERCENTAGE_AND_MINS_PER_WEEK = "As people become more active, they experience health benefits, but there comes a point where these benefits slow down and eventually stop. The cap value determines the level at which these benefits should cease."
export const APPLICABLE_AGE_MIN_TITLE = "Where applicable, edit the minimum age for experiencing a reduction in risk of diseases. If making changes please align with the risk reduction factors inserted above.";
export const APPLICABLE_AGE_MAX_TITLE = "Where applicable, edit the maximum age for experiencing a reduction in risk of diseases. If making changes please align with the risk reduction factors inserted above.";
export const REFERENCE_VOLUME_TITLE = "This represents the minutes of activity per use per week that will produce the relative risk reduction due to physical activity";


// ECONOMIC TAB
export const VALUE_OF_STATISTICAL_LIFE = "The benefit of preventing a fatality, defined as the additional cost that individuals would be willing to bear for improvements in safety/reductions in risk that reduce the expected number of fatalities by one.";
export const VALUE_OF_A_LIFE_YEAR = "VOLY The monetary value of a year of life lost. It is based on studies that assess the willingness to pay for reducing mortality risks associated with each disease. More information on the proxy values in the FAQ."
export const DISCONT_RATE = "Since economic benefits occurring in the future are generally considered less valuable than those occurring in the present, economists apply a discount rate to future benefits. Common discount rates are usually available from governments.  The WHO advises to use 5% as a default value.";


// URBAN MOBILITY
export const CITY_MODE_SHARE_TITLE = "The percentage breakdown of transport usage in your city currently"
export const MINIMUM_AGE = "The tool is designed for adult populations for the age ranges of 20–74 years for walking and 20–64 years for cycling. It should not be applied to populations of children or adolescents, since the scientific evidence used does not include these age groups. The upper age boundaries have been set by consensus to avoid inflating health benefits from misrepresenting active travel behaviour among older age groups that have higher mortality risks. If the assessed population is considerably younger or older than average, the user can specify a lower or higher age range.";
export const MAXIMUM_AGE = "The tool is designed for adult populations for the age ranges of 20–74 years for walking and 20–64 years for cycling. It should not be applied to populations of children or adolescents, since the scientific evidence used does not include these age groups. The upper age boundaries have been set by consensus to avoid inflating health benefits from misrepresenting active travel behaviour among older age groups that have higher mortality risks. If the assessed population is considerably younger or older than average, the user can specify a lower or higher age range.";
export const VEHICLE_LOAD_FACTORS_TITLE = "Average number of people in commuting per vehicle"

// PROJECT PARAMETERS

export const TRIPS_PER_DAY = "Number of daily trips (one way) in the project area for all users. This is usually count data."
export const TOTAL_TRIPS_PER_YEAR = "Number of yearly trips (one way) in the project area for all users. This is usually count data. Calculated based on the number of trips per day and the trips per year per user (below)."
export const AVERAGE_TRIP_DURATION = "Average time (in minutes) of walking or cycling per person for each trip."
export const AVERAGE_TRIP_SPEED = "Average speed of walking or cycling per person per trip.  The average speed takes into account stops at crossing or due to obstacles. The WHO recommended average walking speed is 5.3 km/h, and cycling speed is 14.0 km/h."
export const AVERAGE_TRIP_DISTANCE = "Average length of walking or cycling per person per trip. Calculated based on the average trip duration and average trip speed."
export const MINIMUM_TRIP_DISTANCE = "Shortest journey made by walking or cycling that will be considered for the analysis. The WHO recommends 1km, in the Health economic assessment tool (HEAT)."
export const TRIPS_PER_YEAR_PER_USER = "Average number of trips per person (user) per year. If this data is unavailable, consider the type of users (commuter, leisure) and frequency of use (daily, 2x week) taking into consideration bad weather and holidays when people are less likely to walk or cycle. Example: One person is commuting 5 days a week with 2 trips per day (going and coming from work) for 25 weeks a year equals 250 trips per year per user (default)."
export const TOTAL_MODE_SHIFT = "Proportion of the increase in walking or cycling that represents ‘new’ exercise, e.g. switching from driving to walking or cycling. This does NOT include people already walking or cycling who have just switched routes (therefore no new benefits from increased physical activity are created)."
export const PROPORTION_NEW_PEDESTRIAN_TRIPS = "Proportion of the increase in walking or cycling created by the project, of people who were not taking this route before or using this area. This doesn't include people who were using other mode of transport."
export const PROPORTION_PROJECT_INDUCED = "Proportion of the increase in walking or cycling that represents ‘new’ exercise, PER ORIGIN MODE. In other words, this figure represents the percentage shift to walking or cycling from each transport mode."
export const JOURNEY_LENGHT = "Average length of a trip using different modes of transport. This captures how certain routes can be shorter for prioritized vehicles.";
export const TIME_FRAME = "Assessment timeframe refers to how many years you would like to see the accumulated economic benefits of your interventions. The economic benefits are provided in terms of cumulative Value of a Statistical Life (VSL) and Value of a Life Year (VOLY) over assessment timeframe.";
export const CAPEX = "Capital expenditure, or CapEx, are funds used to acquire, upgrade, and maintain physical assets such as property, industrial buildings, or equipment.";
export const OPEX = "Operating expenses are the costs for running the operations on a daily basis.";

// RESULTS PAGE
export const IMPACT_ON_INDICATORS = "Results are shown on an interactive map and tables. Within the map, you can explore the impact of the live project on each indicator. Indicator scores represent an average for the neighbourhood including the changes due to the action on a single grid."
export const MOBILITY = "Explore the results of your live project on Mobility indicators. The results are shown for the baseline (pre-project score) and after the project (post-project score), as well as the change in score."
export const LIVABILITY = "Explore the results of your live project on Liveability indicators. The results are shown for the baseline (pre-project score) and after the project (post-project score), as well as the change in score."
export const GENERAL = "Explore the results of your live project on the total combined score (Healthy Neighbourhood index). The total score is calculated by multiplying the Mobility and Liveability scores. The results are shown for the baseline (pre-project score) and after the project (post-project score), as well as the change in score."
export const EMISSION_BENEFITS = "Emissions saved due to mode shift from polluting transport modes (car, taxi, public transport) to walking and cycling."
export const VSL_BASED_TITLE = "The value of statistical life (VSL) is the additional monetary cost that individuals would be willing to pay to reduce their risk of death."
export const VOLY_BASED_TITLE = "The value of a life year (VOLY) is the additional monetary value that individuals would be willing to pay to extend their life by one year."
export const IMPLEMENTATION_COSTS_TITLE = "Since economic benefits occurring in the future are generally considered less valuable than those occurring in the present, economists apply a discount rate to future benefits. Common discount rates are usually available from governments. The WHO advises to use 5% as a default value."
export const VSL_BASED_RATIO_TITLE = "Value of statistical life (VSL) represents the benefit of preventing a fatality, defined as the additional cost that individuals would be willing to bear for improvements in safety/reductions in risk that reduce the expected number of fatalities by one."
export const REDUCTION_IN_YEARS_TITLE = "Years lived with disability (YLD) represents the number of years of healthy life lost due to disability or disease."