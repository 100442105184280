import React from "react";
import { TabPanel } from "@mui/lab";
import { Typography } from "@mui/material";

export default function HowToUse() {
  return (
    <TabPanel value="2" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        This page describes the overall workflow and steps to conduct a project impact analysis.
      </Typography>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">1</div>
          <div className="stepTitleOne">
            Set Up: <strong>Define the urban area for the analysis </strong>
          </div>
        </div>

        <div className="textualContent">
        To start simulating the health benefits of a project, within the ‘Location’ you should define the city and urban area where the action will be implemented. 
        The tool allows you to simulate a project on a single grid, equivalent to 1 km<sup>2</sup>. 
        You can use the drop-down list to select a city, or zoom in on the interactive map.
        </div>
      </div>

      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">2</div>
          <div className="stepTitle">
            Set Up: <strong>Define demographic data, health and economic parameters</strong>
          </div>
        </div>

        <div className="textualContent">
        Once the location for the project is defined, 
        you will navigate to the 'General Parameters' to add context on the project. These parameters include city-data such as population, health data and economic data. 
        For most parameters, the tool uses default values but you should review these, and update with local data where possible.

        </div>
      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">3</div>
          <div className="stepTitle">
            Project: <strong> Select the action to be simulated </strong>
          </div>
        </div>

        <div className="textualContent">
        In the 'Project' section of the tool, you will be asked to select which indicator you want your project to have an impact on. 
        At this point, you will be given a selection of pre-defined set of actions per indicator. 
        Alternatively, you can skip simulating individual actions and instead specify expected changes in mobility parameters which increase walking and cycling. 
        </div>
      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">4</div>
          <div className="stepTitle">
            Project: <strong>Define the level of impact of the action</strong>
          </div>
        </div>

        <div className="textualContent">
        Once a pre-defined action is selected, you will need to define the level of impact. 
        For certain actions, the tool offers different pre-defined levels of impact which are tailored to each urban action. 
        </div>
      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">5</div>
          <div className="stepTitle">
            Project: <strong>Visualise the causal chain</strong>
          </div>
        </div>

        <div className="textualContent">
        Once the actions are selected, the causal chain diagrams are loaded into the 'Causal Chain Review' page, which demonstrates how the action, 
        through a series of system changes, results in outputs, outcomes, and how the impacts are calculated within the tool. 
        The user can explore this page to understand better the relationships underpinning the project's benefits assessment. 
        </div>
      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">6</div>
          <div className="stepTitle">
            Project: <strong>Insert required inputs</strong>
          </div>
        </div>

        <div className="textualContent">
        The 'Projects Inputs' table will be shown and you should input the requested data on their project. 
        For pre-defined actions, the data required is relatively minimal. For bespoke actions, you will need to provide more data.
        </div>
      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">7</div>
          <div className="stepTitle">
            Project: <strong>Save and modify</strong>
          </div>
        </div>

        <div className="textualContent">
        Once actions have been defined and all requested data registered, you will need to save the project details.
        </div>
      </div>

      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">8</div>
          <div className="stepTitle">
            Results: <strong>See the summary of the project's results</strong>
          </div>
        </div>

        <div className="textualContent">
        In the 'Live project' page, you can see the impact of the live project on each Healthy Neighbourhood indicator, physical activity, health and emissions outcomes. 
        </div>

      </div>
      <div className="verticalStep">
        <div className="upperLine">
          <div className="stepNumber">9</div>
          <div className="stepTitle">
            Results: <strong>Compare impacts of different projects</strong>
          </div>
        </div>

        <div className="textualContentNine">
        By clicking on 'Compare projects' you can select the projects to be compared and will see a dashboard with the comparison of results from alternative projects. 
        </div>
      </div>
    </TabPanel>
  );
}
