import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid"


import "../../css/databasePage.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function PopulationTableModal({title,modalOpen
,setModalOpen,table}) {
  const handleClose = () => setModalOpen(!modalOpen);



  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
        <Box sx={style}>
            {title && <Typography id="transition-modal-title" variant="h6" component="h2">
              {title}
            </Typography>}
            <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={4}>Female</TableCell>
                <TableCell align="center" colSpan={4}>Male</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Age Cohort</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Proxy Value</TableCell>
                <TableCell align="center">Unit</TableCell>
                <TableCell align="center">Age Cohort</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Default Value</TableCell>
                <TableCell align="center">Unit</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {table.sort((a,b) => a.age - b.age).map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{item.ageF}</TableCell>
                  <TableCell align="center">{item.fSexId}</TableCell>
                  <TableCell align="center">{item.fProxy.toFixed(0)}</TableCell>
                  <TableCell align="center">persons</TableCell>
                  <TableCell align="center">{item.ageM}</TableCell>
                  <TableCell align="center">{item.mSexId}</TableCell>
                  <TableCell align="center">{item.mProxy.toFixed(0)}</TableCell>
                  <TableCell align="center">persons</TableCell>
                </TableRow>
              ))}
             </TableBody>
          </Table>
        </TableContainer>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}