import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function ErrorPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 130px)'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography variant="h6">
              You need to login in order to access this page.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}