import React from "react";
import { useState, useEffect } from "react";
import { FormControl, TextField, Grid, Button, Typography } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";

import InputAdornment from "@mui/material/InputAdornment";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import Paper from "@mui/material/Paper";

import "../../css/databasePage.css";
import YLDTableModal from "../basicModal/yldTableModal";
import MortalityTableModal from "../basicModal/mortalityTableModal";
import PopulationTableModal from "../basicModal/populationTableModal";
import StyledTooltip from "../../utils/styledToolTip";
import { CAPES_PERCENTAGE_AND_MINS_PER_WEEK, MORBIDITY_RELATIVE_RISK_REDUCTION_TITLE, APPLICABLE_AGE_MAX_TITLE, APPLICABLE_AGE_MIN_TITLE, REFERENCE_VOLUME_TITLE, SOURCE_TITLE} from "../../utils/tooltipConstants";
import Utils from "../../utils/miscUtils";


const rowsPhyActi = [
  {
    category: "Cycling",
    ageMin: "20",
    ageMax: "64",
    relRisk: "0,90",
    refVol: "100",
    capsImprove: "45%",
    capsMinWeek: "447",
  },
  {
    category: "Walking",
    ageMin: "20",
    ageMax: "74",
    relRisk: "0,89",
    refVol: "168",
    capsImprove: "30%",
    capsMinWeek: "460",
  },
];

export default function Health({newAdditionalInputs,setNewAdditionalInputs, healthErrorList, yldTable, mortalityTable, populationTable, userInputs, advanceErrors, setAdvanceErrors, 
  includePreProject,listOfActions}) {

  const [openModal, setOpenModal] = useState(false)
  const [openMortalityModal, setOpenMortalityModal] = useState(false)
  const [openPopulationModal, setOpenPopulationModal] = useState(false)

  const [morbidity,setMorbidity] = useState([
    { id: "1", value:newAdditionalInputs.morbidity_risk_reduction[0].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[0].risk_reduction_percentage,
  value3:newAdditionalInputs.morbidity_risk_reduction[0].minutes_per_week},
    { id: "2", value:newAdditionalInputs.morbidity_risk_reduction[1].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[1].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[1].minutes_per_week},
    { id: "3", value:newAdditionalInputs.morbidity_risk_reduction[2].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[2].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[2].minutes_per_week},
    { id: "4", value:newAdditionalInputs.morbidity_risk_reduction[3].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[3].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[3].minutes_per_week},
    { id: "5", value:newAdditionalInputs.morbidity_risk_reduction[4].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[4].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[4].minutes_per_week},
    { id: "6", value:newAdditionalInputs.morbidity_risk_reduction[5].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[5].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[5].minutes_per_week},
    { id: "7", value:newAdditionalInputs.morbidity_risk_reduction[6].data_type + " (*)", value2:newAdditionalInputs.morbidity_risk_reduction[6].risk_reduction_percentage,
    value3:newAdditionalInputs.morbidity_risk_reduction[6].minutes_per_week}
  ]);

  const [physicalBenefitsParams,setPhysicalBenefitsParams] = useState([
    { id: "1", mode:newAdditionalInputs.physical_benefits_params[0].mode, applicableAgeMin: newAdditionalInputs.physical_benefits_params[0].applicable_age_min, 
    applicableAgeMax:newAdditionalInputs.physical_benefits_params[0].applicable_age_max, relativeRisk:newAdditionalInputs.physical_benefits_params[0].relative_risk,
    referenceVolume:newAdditionalInputs.physical_benefits_params[0].reference_volume, capsPercentage: newAdditionalInputs.physical_benefits_params[0].caps_percentage,
    capsMinOrWeek: newAdditionalInputs.physical_benefits_params[0].caps_min_or_week },
    { id: "2", mode:newAdditionalInputs.physical_benefits_params[1].mode, applicableAgeMin: newAdditionalInputs.physical_benefits_params[1].applicable_age_min, 
    applicableAgeMax:newAdditionalInputs.physical_benefits_params[1].applicable_age_max, relativeRisk:newAdditionalInputs.physical_benefits_params[1].relative_risk,
    referenceVolume:newAdditionalInputs.physical_benefits_params[1].reference_volume, capsPercentage: newAdditionalInputs.physical_benefits_params[1].caps_percentage,
    capsMinOrWeek: newAdditionalInputs.physical_benefits_params[1].caps_min_or_week }
  ]);

  const handleMinPerWeekChange = (returnedId, textValue) =>{
    textValue = validateEmptyFields(textValue)
    const index = returnedId-1
    let temp = newAdditionalInputs;
    let tempMorbidity = [...morbidity]
    temp.morbidity_risk_reduction[index].minutes_per_week=textValue;
    tempMorbidity[index].value3 = textValue
    verifyFieldName(textValue, index, temp)
    setMorbidity(tempMorbidity)
    setNewAdditionalInputs(temp)
  }

  const verifyFieldName = (textValue, index, temp) =>{
    let errorName = findErrorName(index);
    if(!(Utils.ValidateErrors(textValue, errorName, advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }
  }

  const findError = (index) =>{
    let errorName = findErrorName(index)
    return advanceErrors.some((error) => error.errorType===errorName)
  }

  const findErrorName = (index) => {
    let errorName = "";
    switch(index){
      case (0):
        errorName = "conorary_heart_disease_error";
        break;
      case (1):
        errorName = "stroke_error";
        break;
      case(2):
        errorName = "dementia_error";
        break;
      case(3):
        errorName = "diabetes_error";
        break;
      case(4):
        errorName = "depression_error";
        break;
      case(5):
        errorName = "breast_cancer_error";
        break;
      case(6):
        errorName = "colon_cancer_error";
        break;
    }
    return errorName;
  }



  const handleRiskReductionChange = (returnedId, textValue) =>{
    textValue = validateEmptyFields(textValue)
    const index = returnedId-1
    let temp = newAdditionalInputs;
    let tempMorbidity = [...morbidity]
    temp.morbidity_risk_reduction[index].risk_reduction_percentage=textValue;
    tempMorbidity[index].value2 = textValue
    setMorbidity(tempMorbidity)
    setNewAdditionalInputs(temp)
  }

  const openModalOnClick = () =>{
    if(!openModal){
      setOpenModal(true)
    }else{
      setOpenModal(false)
    }
  }

  const openMortalityModalOnClick = () =>{
    if(!openMortalityModal){
      setOpenMortalityModal(true)
    }else{
      setOpenMortalityModal(false)
    }
  }

  const openPopulationModalOnClick = () =>{
    if(!openPopulationModal){
      setOpenPopulationModal(true)
    }else{
      setOpenPopulationModal(false)
    }
  }

  const handleApplicableAgeMinChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].applicable_age_min=textValue;
        tempPhysicalBenefits[0].applicableAgeMin = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].applicable_age_min=textValue;
        tempPhysicalBenefits[1].applicableAgeMin = textValue
        break;
      default:
        break;
    }
    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }

  const handleRelativeRiskChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].relative_risk=textValue;
        tempPhysicalBenefits[0].relativeRisk = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].relative_risk=textValue;
        tempPhysicalBenefits[1].relativeRisk = textValue
        break;
      default:
        break;
    }
    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }
  const handleApplicableAgeMaxChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].applicable_age_max=textValue;
        tempPhysicalBenefits[0].applicableAgeMax = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].applicable_age_max=textValue;
        tempPhysicalBenefits[1].applicableAgeMax = textValue
        break;
      default:
        break;
    }
    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }
  
  const handleReferenceVolumeChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].reference_volume=textValue;
        tempPhysicalBenefits[0].referenceVolume = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].reference_volume=textValue;
        tempPhysicalBenefits[1].referenceVolume = textValue
        break;
      default:
        break;
    }

    if(!(Utils.ValidateErrors(textValue, findReferenceType(id), advanceErrors, setAdvanceErrors))){
      Utils.VerifyFields(temp, userInputs, advanceErrors, setAdvanceErrors, includePreProject,listOfActions)
    }

    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }

  const findReferenceType = (index) => {
    let errorType = "";
    switch(index){
      case ("1"):
        errorType = "reference_volume_error_cycling";
        break;
      case ("2"):
        errorType = "reference_volume_error_walking";
        break;
    }
    return errorType;
  }

  const findReferenceError = (index) =>{
    let errorName = findReferenceType(index)
    return advanceErrors.some((error) => error.errorType===errorName)
  }

  const handleCapsPercentageChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].caps_percentage=textValue;
        tempPhysicalBenefits[0].capsPercentage = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].caps_percentage=textValue;
        tempPhysicalBenefits[1].capsPercentage = textValue
        break;
      default:
        break;
    }
    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }

  const handleCapsMinOrWeekChange = (id, textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempPhysicalBenefits = [... physicalBenefitsParams]
    switch (id) {
      case "1":
        temp.physical_benefits_params[0].caps_min_or_week=textValue;
        tempPhysicalBenefits[0].capsMinOrWeek = textValue
        break;
      case "2":
        temp.physical_benefits_params[1].caps_min_or_week=textValue;
        tempPhysicalBenefits[1].capsMinOrWeek= textValue
        break;
      default:
        break;
    }
    setNewAdditionalInputs(temp)
    setPhysicalBenefitsParams(tempPhysicalBenefits)
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  return (
    <TabPanel value="1" className="p-0 my-4">
      <h6>Morbidity Relative Risk Reduction <StyledTooltip title={MORBIDITY_RELATIVE_RISK_REDUCTION_TITLE} arrow><span className="icon"></span></StyledTooltip> </h6>
      <label htmlFor="" className="mt-4 label-default">
        Per Minutes of Physical Activity 
      </label>
    
      {morbidity.map((data, index) => (
        <Grid container spacing={2} key={index} className="mt-2">
          <Grid item xs={6}>
            <TextField fullWidth
              id={data.id}
              label={data.value}
              value={data.value3}
              error = {findError(index)}
              // value={newAdditionalInputs.morbidity_risk_reduction.minutes_per_week}
              helperText="Minutes/week"  
              type="number"
              onChange={(e) => handleMinPerWeekChange(data.id,e.target.value)} 
                />
            </Grid>
            <Grid item xs={6}>
            <TextField fullWidth
              label='Risk Reduction'
              id="outlined-adornment-weight"
              value={data.value2}
              onChange={(e) => handleRiskReductionChange(data.id,e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                inputMode: "any",
                pattern: "[0-9]*",
              }}
              type="number"
              helperText="Percentage"
            /> 
          </Grid>
        </Grid>
      ))}
        
      <Grid item xs={12} md={12} className="mt-2">
        <FormControl fullWidth className="mt-3">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
            <span className="icon"></span>
          </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Override source/rationale"
            helperText=" Default Source: University of Cambridge CEDAR; ITHIM model;2018"
            variant="standard"
            //error={errorHealth}
          />
        </FormControl>
      </Grid>
      <div className="py-4">
      <label htmlFor="" className="label-default mb-4">
        Physical Activity Benefit Parameters
      </label>
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        This page presents the parameters used to calculate health benefits, like the age range and the reduction in relative risk due to increased activity
      </Typography>
      <br></br>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: '16px' }}>Mode</TableCell>
                <TableCell style={{ fontSize: '12px' }}>Applicable Age (min)<StyledTooltip title={APPLICABLE_AGE_MIN_TITLE} arrow><span className="icon"></span></StyledTooltip></TableCell>
                <TableCell style={{ fontSize: '12px' }}>Applicable Age (max)<StyledTooltip title={APPLICABLE_AGE_MAX_TITLE} arrow><span className="icon"></span></StyledTooltip></TableCell>
                <TableCell style={{ fontSize: '12px' }}>Relative Risk <StyledTooltip title={MORBIDITY_RELATIVE_RISK_REDUCTION_TITLE} arrow><span className="icon"></span></StyledTooltip></TableCell>
                <TableCell style={{ fontSize: '12px' }}>Reference Volume  (*)<StyledTooltip title={REFERENCE_VOLUME_TITLE} arrow><span className="icon"></span></StyledTooltip></TableCell>
                <TableCell style={{ fontSize: '11px' }}>Caps (%improve) <StyledTooltip title={CAPES_PERCENTAGE_AND_MINS_PER_WEEK} arrow><span className="icon"></span></StyledTooltip></TableCell>
                <TableCell style={{ fontSize: '11px' }}>Caps (min/week) <StyledTooltip title={CAPES_PERCENTAGE_AND_MINS_PER_WEEK} arrow><span className="icon"></span></StyledTooltip></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {physicalBenefitsParams.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.mode}</TableCell>
                  <TableCell><TextField value={item.applicableAgeMin} 
                  onChange={(e) => handleApplicableAgeMinChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell><TextField value={item.applicableAgeMax} 
                  onChange={(e) => handleApplicableAgeMaxChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell><TextField value={item.relativeRisk} 
                  onChange={(e) => handleRelativeRiskChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell><TextField value={item.referenceVolume} 
                  error={findReferenceError(item.id)}
                  onChange={(e) => handleReferenceVolumeChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell><TextField value={item.capsPercentage} 
                  onChange={(e) => handleCapsPercentageChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell><TextField value={item.capsMinOrWeek}
                  onChange={(e) => handleCapsMinOrWeekChange(item.id,e.target.value)}></TextField></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Grid item xs={12} md={12} className="mt-2">
        <FormControl fullWidth>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={SOURCE_TITLE} arrow>
            <span className="icon"></span>
          </StyledTooltip></div>
          <TextField
            id="outlined-number"
            label="Override source/rationale"
            helperText="Default Source: World Health Organization; HEAT tool;2017"
            variant="standard"
          />
        </FormControl>
      </Grid>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
      <Button onClick={openPopulationModalOnClick} className="default_modal">
          Age Structure Of National Population
      </Button>
      </div>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
      <Button onClick={openMortalityModalOnClick} className="default_modal">
          Age Specific Mortality Per Year (All Causes)
      </Button>
      </div>
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "center" }}
      >
      <Button onClick={openModalOnClick} className="default_modal">
          Years Lived with Disability (YLDS)
        </Button>
      </div>
      {<YLDTableModal title="Years Lived with Disability (YLDS)" modalOpen={openModal} setModalOpen={setOpenModal} 
        table={yldTable}/>}
      {<MortalityTableModal title="Age Specific Mortality Per Year (All Causes)" modalOpen={openMortalityModal} setModalOpen={setOpenMortalityModal} 
        table={mortalityTable}/>}
      {<PopulationTableModal title="Age Structure Of National Population" modalOpen={openPopulationModal} setModalOpen={setOpenPopulationModal} 
        table={populationTable}/>}
    </TabPanel>
  );
}
