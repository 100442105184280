import useState, * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Link } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import "./introStep.css";

import { Typography } from "@mui/material";
import HowToUse from "./howToUse";

export default function LabTabs({step, setIsBackDisabled}) {  
  const [value, setValue] = React.useState("1");
  localStorage.setItem("locationIsValid", false);

  const handleChange = (event, newValue) => {
    //if (step.hasOwnProperty("current"))
    const backBtn =  document.getElementById("btn-back"); // as HTMLButtonElement;
    console.log("Pressed! & BackBtn=", event);  
    if (step == 0 && newValue == "1" && backBtn != null) {
      //console.log("disabled Back Btn");
      backBtn.classList.add("Mui-disabled");
      backBtn.disabled = true;
      setIsBackDisabled(true);
    } else if (backBtn != null) {
      //console.log("enabled Back Btn");
      backBtn.classList.remove("Mui-disabled");
      backBtn.disabled = false;
      setIsBackDisabled(false);      
    }
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%", marginTop: "32px" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="Tabs" id="tabsLevel1">
              <Tab label="About" value="1" />
              <Tab label="How to use" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="p-0 my-4">
            <Typography className="component_first_subtitle" variant="caption" gutterBottom>
              This page provides an overview of the tool's purpose, the
              methodology used and further information about C40 Benefits
              Research team.
            </Typography>
            <Box>
              <Typography className="c-40__Title-aboutOne" variant="h6">
                Healthy Neighbourhood Explorer 
              </Typography>

              <Typography className="c-40__Paragraph-about" variant="body2">
                Climate change and non-communicable diseases are both
                increasingly pressing issues faced by cities. Clustering of
                populations in urban areas is a challenge for health,
                environment and liveability of cities, but also makes them
                central for a joint intervention. Broad cross-sector approaches
                provide opportunities to increase the health of urban
                populations, while also responding to climate issues. C40 Cities
                and Novo Nordisk have established a partnership to support
                cities in measuring and evaluating urban actions and their
                impact on health and climate. They are committed to support
                cities to advance their goals of increasing the mode share of
                active mobility options and become healthier, more liveable and
                sustainable.
              </Typography>
              <Typography variant="h6" className="c-40__Title-about">
                Tool purpose
              </Typography>

              <Typography className="c-40__Paragraph-about" variant="body2">
              The tool enables users to measure the wider benefits of urban design, walking and cycling interventions, 
              focusing on the health benefits from increased physical activities and the associated economic benefits. 
              The tool helps cities to plan and make decisions on urban interventions based on 15-minuty city principles. 
              By linking urban interventions with mobility and liveability indicators, the tool allows users to:  
                <li className="c-40__Li-about">
                Make a strong case for climate action and healthy lifestyles. 
                </li>
                <li className="c-40__Li-about">
                Identify the most impactful urban design, walking and cycling interventions that tackle both climate and health.
                </li>
                <Typography className="c-40__Paragraph-about" variant="body2">
                The tool assesses the current status of Healthy Neighbourhood indicators in a selected urban area and the resulting changes due to actions taken. 
                Based on the interventions, it then estimates the consequent change in physical mobility and health impacts. 
                </Typography>
              </Typography>
              <Typography className="c-40__Title-about" variant="h6">
                Who can use the tool?
              </Typography>

              <Typography className="c-40__Paragraph-about" variant="body2">
                The tool is designed for a wide variety of users without
                particular expertise in impact assessment. These include
                primarily transport and urban planners, traffic engineers and
                special interest groups working on transport, walking, cycling
                or the environment. The tool is also of interest to health
                economists, physical activity and health experts. It is designed
                be user-friendly with minimal data input requirement and a
                possibility to use default data, adaptable to local context.
              </Typography>

              <Typography className="c-40__Title-about" variant="h6">
                Methodology
              </Typography>

              <Typography className="c-40__Paragraph-about" variant="body2">
              After two years of research and an extensive literature review, C40 Cities and Novo Nordisk built the Walking and Cycling tool, 
              an Excel-based tool that assesses the health and climate benefits of walking and cycling actions in cities. 
              The Healthy Neughbourhood Explorer builds on this previous tool by adding urban design interventions 
              and linking them to the 15-minute city concept. It is an easy to use web-based tool that any city can run to assess the wider benefits 
              of 15-minute city actions. The development of the tool was led by C40 Cities in partnership with Chaire-ETI and Inetum. 
              The development process benefited from the contributions from a diverse group of  leading experts within urban planning and health. 
              This tool has been tested with different users and is currently a fully functional Beta version of the tool. 
              If you experience any issues or have any feedback on how we can improve the tool, please send feedback via this <a href="https://docs.google.com/forms/d/e/1FAIpQLSe8VZiKYSv251T2QmqR2K1nO3W2kjSGrE3HrKeN4SayZl2ReA/viewform?usp=sf_link" target="_blank">form</a>. 
              </Typography>
              <Typography className="c-40__Title-about" variant="h6">
                C40 Benefits Research Programme
              </Typography>

              <Typography className="c-40__Paragraph-about" variant="body2">
                Climate action has a range of wider benefits for the health and
                prosperity of cities, enabling Mayors to deliver a better
                quality of life for their citizens. From green jobs and growth,
                to active, happier lives and cleaner air and water – climate
                actions have an immediate, tangible impact on people’s lives.
                Understanding and harnessing the wider benefits of climate
                action will allow cities to tackle multiple issues at once,
                delivering urban development that provides more liveable and low
                carbon cities. For further information, please see:&nbsp; 
                
                <Link
                  className="c-40__Link-about"
                  href="https://www.c40.org/benefits"
                  target="_blank"
                  rel="noopener"
                >
                   https://www.c40.org/benefits
                </Link>
              </Typography>
              
              <Typography className="c-40__Paragraph-about" variant="body2">
               
              </Typography>
              <Typography className="c-40__Title-about" variant="h6">
                Contact us
              </Typography>
              <Typography className="c-40__Paragraph-about" variant="body2">
                <a href={`mailto:${"healthyneighbourhood@c40.org"}`}>{"healthyneighbourhood@c40.org"}</a>
              </Typography>
            </Box>
          </TabPanel>
          <HowToUse />
        </TabContext>
      </Box>
    </div>
  );
}
