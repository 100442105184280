import TabPanel from "@mui/lab/TabPanel";
import { Snackbar, SnackbarValue, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { FormControl, TextField, Grid, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from '@mui/material/Alert';

import "../../css/databasePage.css";
import React, { useEffect, useState } from 'react';
import DataService from "../../services/getData";

export default function Save({simulationResults, projectData, setProjectData, projects, setListOfProjects, setSavedProject, setUserInputs, userInputs}) {

  const [SnackbarValue, setSnackbarValue] = useState({
    severity:"warning",
    message:""
  })
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false)


  /*useEffect(()  => {
    getData()
  }
  , []);*/

  const getData = async () => {
  try {
    const dataTemp = await DataService.GetUserProjects();
    setListOfProjects(dataTemp.data);
    } catch (error) {
      setSnackbarValue({severity:'error',
      message:error.response.data.message})
    }
  }

  const handleDeleteProject = async (projectNumber) => {
    try {
      const dataTemp = await DataService.DeleteUserProject(parseFloat(projectNumber));
      setSnackbarValue({
        severity:'success',
        message:"You have successfully deleted the project"
      });
      setListOfProjects(projects.filter((project) => project.projectNumber !== projectNumber))
    }catch(error){
      setSnackbarValue({
        severity:'error',
        message:error.response.data.message
      });
    }
  }

  const handleClose = () => setSnackbarValue({
      severity:"warning",
      message:""
  });

  useEffect(() => {
    if (!(SnackbarValue.message=="")) {
      setOpenSnackbar(true)
    } else {
      setOpenSnackbar(false)
    }
  },[SnackbarValue])

  const handleSaveNameChange = (textValue) => {
    if (textValue=="") {
      setIsError(true)
    } else {
      setIsError(false)
    }
    let temp = {...projectData}
    let tempInput = {... userInputs}
    temp.projectName = textValue;
    tempInput.projectName = textValue
    setProjectData(temp)
    setUserInputs(tempInput)
  }

  const handleDescriptionChange = (textValue) => {
    let temp = {...projectData}
    temp.projectDescription = textValue;
    setProjectData(temp)
  }

  const saveProjectHandler = () => {
    let tempProjectData = {...projectData}
    if (validateFields(tempProjectData)) {
      setProjectDataToSend(tempProjectData)
      saveProject(tempProjectData)
    } else {
      setIsError(true)
      setSnackbarValue({
        severity:'error',
        message:"Please make sure to give a name to the save"
      });
    }
  }

  const setProjectDataToSend = (tempProjectData) => {
    tempProjectData.actionSimulationId=simulationResults.simulationId
    tempProjectData.projectInfo=simulationResults
    setProjectData(tempProjectData)
  }

  const saveProject = async (projectData) => {
    try {
      const dataTemp = await DataService.SaveUserProject(projectData);
      setSnackbarValue({
        severity:'success',
        message:"You have successfully saved the project"
      });
      let tempList = [... projects]
      tempList.unshift({
        projectNumber:dataTemp.data.projectNumber,
        projectName:dataTemp.data.projectName,
        projectDescription: dataTemp.data.projectDescription,
        projectInfo: dataTemp.data.projectInfo,
        actionSimulationId: dataTemp.data.actionSimulationId,
        creationDate: dataTemp.data.creationDate,
      })
      setListOfProjects(tempList)
      setSavedProject(dataTemp.data)
    } catch(error) {
      setSnackbarValue({
        severity:'error',
        message:error.response.data.message
      });
    }
  }

  const validateFields = (tempProjectData) => {
    if(tempProjectData.projectName == ""){
      return false;
    }
    return true;
  }

  return (
    <TabPanel value="5" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
        Save your live project here. You can then see the results of this project or compare the results of up to five projects.
      </Typography>
      <h6 className="mt-4">Project Name</h6>
      <Grid container>
        <Grid item xs={12} md={12}>
          <FormControl variant="standard" fullWidth>
            <TextField onChange={(e) => handleSaveNameChange(e.target.value)} 
            id="outlined-textarea" 
            label="Name" 
            error={isError}
            multiline />
          </FormControl>
        </Grid>
      </Grid>
      <div className="row mt-5">
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
          }}
          noValidate
        >
          <div>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              helperText="Provide a description for the project"
              onChange={(e) => handleDescriptionChange(e.target.value)}
              multiline
              rows={4}
            />
          </div>
        </Box>
      </div>
      <Grid item xs={12} md={12} className="d-flex justify-content-center mt-5">
        <Button onClick={() => saveProjectHandler()} variant="outlined" size="large" endIcon={<SaveIcon />}>
          Save
        </Button>
      </Grid>
      <Grid item xs={12} md={12} className="d-flex justify-content-center mt-5">
        {projects.length > 0 &&  <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
        <TableContainer>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell  style={{ width: '30%' }}>{project.projectName}</TableCell>
                  <TableCell  style={{ width: '50%' }}>{project.projectDescription}</TableCell>
                  <TableCell  style={{ width: '10%' }}>{project.creationDate.toString()}</TableCell>
                  <TableCell style={{ width: '10%' }}>
                    <IconButton
                      aria-label="delete"
                      className="c-40-default-color-iconDelete"
                      onClick={() => handleDeleteProject(project.projectNumber)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>}
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={3500} onClose={handleClose}>
                <MuiAlert  severity={SnackbarValue.severity}>{SnackbarValue.message}</MuiAlert >
        </Snackbar>
    </TabPanel>
  );
}
