import Feature from 'ol/Feature.js';    
import Point from "ol/geom/Point";
import GeoJSON from "ol/format/GeoJSON.js";
import { Circle as CircleStyle, Icon, Fill, Stroke, Style} from 'ol/style.js';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source';
import parse from 'html-react-parser';
import DataService from '../services/getData'
import Overlay from 'ol/Overlay.js';

const total = 20; // Steps
const startColor = hexToRgb("#C30000"); // Red
const middleColor = hexToRgb("#FFEB00"); // Yellow
const endColor = hexToRgb("#5DD015"); // Green

const ColorScale = () => {
	// 2 color scheme
	//let htmlStr = "<div class='color-scale' style='background: linear-gradient(0deg, "+ ColorRange[0] + " 0%, "+ 
	//	ColorRange[total-1] +" 100%)'></div>";

	// 3 color Scheme
	let htmlStr = "<div class='color-scale' style='background: linear-gradient(0deg, "+ 
	ColorRange[0] + " 4%, "+ ColorRange[9] +" 50%, " + ColorRange[total-1] +" 94%)'></div>";

	//ColorLevel().map((color, index) => {		
		/*htmlStr += "<span style='background-color:"+color+"'>"
		if (index == 0) htmlStr += "<span class='first-score'>Min score</span>";
		if (index == ColorLevel().length-1) htmlStr += "<span class='last-score'>Max score</span>";
		htmlStr += "</span>";*/
		//console.log("Color ", index + " = ", color);
	//})
	//htmlStr += "</div>";
	//console.log("HtmlStr=", htmlStr);s
	return parse(htmlStr);
}

/*
const ColorLevel = (alpha = 1) => {
	// Styles & Colors
	/*const colors = [
	"rgba(238, 255, 230, 1)", 
	"rgba(192, 245, 168, 1)", 
	"rgba(168, 223, 143, 1)", 
	"rgba(143, 204, 116, 1)", 
	"rgba(106, 168, 79, 1)", 
	"rgba(83, 136, 60, 1)", 
	"rgba(57, 106, 36, 1)", 
	"rgba(30, 74, 11, 1)",
	"rgba(15, 51, 0, 1)", 
	"rgba(1, 3, 0, 1)"
	];

	// Two color schema
	//const startColor = {r:232, g:248, b:231};
	//const endColor  = {r:3, g:194, b:64};
    //const delta_R1 = startColor.r - endColor.r
    //const delta_G1 = startColor.g - endColor.g 
    //const delta_B1 = startColor.b - endColor.b
	//const step_R1 = delta_R1 / (total)		
	//const step_G1 = delta_G1 / (total)
	//const step_B1 = delta_B1 / (total)

	// Three color scheme
	const startColor =  {r:200, g:2, b:2}; // {r:253, g:231, b:36}; yellow
	const midColor  = {r:235, g:232, b:52};     // rgb(121,209,81)
	const endColor  = {r:3, g:194, b:64};      //rgb(0 18 153)

	const delta_R1 = midColor.r - startColor.r
	const delta_G1 = midColor.g - startColor.g
	const delta_B1 = midColor.b - startColor.b
	const delta_R2 = midColor.r - endColor.r
	const delta_G2 = midColor.g - endColor.g
	const delta_B2 = endColor.b - midColor.b

	const step_R1 = delta_R1 / (total)
	const step_G1 = delta_G1 / (total)
	const step_B1 = delta_B1 / (total)
	const step_R2 = delta_R2 / 10
	const step_G2 = delta_G2 / 10
	const step_B2 = delta_B2 / 10

	let R = startColor.r
	let G = startColor.g
	let B = startColor.b
	const gradient_colors = []
	gradient_colors.push("rgba("+ R +","+ G+","+ B+","+alpha+")");

	let i;
	for (i=1; i<total ; i++) {
			if (i > 0 && i < 10) {
					R += step_R1
					G += step_G1
					B += step_B1
			} else if (i === 10) {
					R = midColor.r 
					G = midColor.g 
					B = midColor.b
			} else if (i === 19) {
					R = endColor.r;
					G = endColor.g;
					B = endColor.b;
			} else {     
					R += step_R2;
					G += step_G2;
					B += step_B2;
			}
			R -= step_R1
			G -= step_G1
			B -= step_B1			
			gradient_colors.push("rgba("+ R +","+ G+","+ B+","+alpha+")");
	}

	R = endColor.r;
	G = endColor.g;
	B = endColor.b;
	gradient_colors.push("rgba("+ R +","+ G+","+ B+","+alpha+")");
	//console.log("Gradiend_colorw=", gradient_colors);	
	return gradient_colors;
}

const ColorRange = ColorLevel(1);
*/

// Gradient 3 colors new approach
function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) { 
        factor = 0.5; 
    }
    let result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
    }
    return result;
}

function rgbToHex(rgb) {
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1).toUpperCase();
}

function calculateGradient(startColor, middleColor, endColor, steps) {
    let startRGB = [startColor.r, startColor.g, startColor.b];
    let middleRGB = [middleColor.r, middleColor.g, middleColor.b];
    let endRGB = [endColor.r, endColor.g, endColor.b];
    
    let gradientColors = [];
    
    // First half of the gradient (from start to middle)
    for (let i = 0; i < steps / 2; i++) {
        let factor = i / (steps / 2);
        let interpolatedColor = interpolateColor(startRGB, middleRGB, factor);
        gradientColors.push(rgbToHex(interpolatedColor));
    }
    
    // Second half of the gradient (from middle to end)
    for (let i = 0; i < steps / 2; i++) {
        let factor = i / (steps / 2);
        let interpolatedColor = interpolateColor(middleRGB, endRGB, factor);
        gradientColors.push(rgbToHex(interpolatedColor));
    }
    
    return gradientColors;
}

let steps = total; // Number of steps in the gradient
let ColorRange = calculateGradient(startColor, middleColor, endColor, steps);
const ColorLevel = calculateGradient(startColor, middleColor, endColor, steps);

const Strokes = { 
	normalStroke: new Stroke({ color: "#707070",  width: 1 }),
	selectedStroke: new Stroke({ color: "#23BCED", width: 4})
}

const svgMarker = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="48" height="48">'
	+ '<path d="M15 3L15 5.0625C9.730469 5.539063 5.539063 9.734375 5.0625 15L3 15L3 17L5.0625 17C5.539063 22.265625 9.734375 26.460938 15 26.9375L15 29L17 29L17 26.9375C22.265625 26.460938 26.460938 22.265625 26.9375 17L29 17L29 15L26.9375 15C26.460938 9.734375 22.269531 5.539063 17 5.0625L17 3 Z M 15 7.03125L15 9L17 9L17 7.03125C21.191406 7.484375 24.480469 10.808594 24.9375 15L23 15L23 17L24.96875 17C24.515625 21.191406 21.191406 24.515625 17 24.96875L17 23L15 23L15 24.96875C10.808594 24.515625 7.484375 21.191406 7.03125 17L9 17L9 15L7.0625 15C7.519531 10.808594 10.808594 7.484375 15 7.03125 Z M 16 12C13.789063 12 12 13.789063 12 16C12 18.210938 13.789063 20 16 20C18.210938 20 20 18.210938 20 16C20 13.789063 18.210938 12 16 12Z" fill="#23bced" />'
	+ '</svg>'

const iconStyle = new Style({
	image: new Icon({
		offset: [0, 0],
		opacity: 1,
		scale: 1,
		src: 'data:image/svg+xml;utf8,' + encodeURIComponent(svgMarker)
	}),
	zindex: 20
});

const Styles = {
	cityStyle: new Style({
		image: new CircleStyle({
			radius: 10,
			fill: new Fill({ color: "white" }),
			stroke: new Stroke({
			color: "#707070",
			width: 1
			})
		})
	}), 
	selectedCity: new Style({
		fill: new Fill({ color: "white" }),
		stroke: new Stroke({
			color: "#707070",
			width: 2
		}),
		zindex: -1
	}),
	normal: new Style({
		image: new CircleStyle({
			radius: 10,
			fill: new Fill({ color: "white" }),
			stroke: new Stroke({ color: "#707070",  width: 1 })
		})
	}),
	selectStyle: new Style({
		image: new CircleStyle({
			radius: 11,
			fill: new Fill({color: "white"}),
			stroke: new Stroke({ color: "#23BCED", width: 4})
		}),
		zIndex: 100
	}),
	polygonStyle: new Style({
		fill: new Fill({ color: "rgba(0,0,0, 0.05)" }),
		stroke: new Stroke({
			color: "#fd630b",
			width: 2
		})      
	}),
	invisible: new Style({
		fill: new Fill({color: "transparent" }),
		stroke: new Stroke({color: "transparent", width: 0})
	}),
	neighborhood: new Style({
		fill: new Fill({color: "rgba(0,0,0,0.1)" }),
		stroke: new Stroke({color: "white", width: 4})
	}),
	selectedNeighborhood: new Style({
		fill: new Fill({color: "transparent" }),
		stroke: new Stroke({color: "#23BCED", width: 8}),
		zIndex: 200
	}),
	neighborhoodForLocationStep: new Style({
		fill: new Fill({color: "rgba(0,0,0,0.4)" }),
		stroke: new Stroke({color: "white", width: 2})
	}),
	selectedNeighborhoodForLocationStep: new Style({
		fill: new Fill({color: "rgba(0,0,0,0.2)" }),
		stroke: new Stroke({color: "#23BCED", width: 4}),
		zIndex: 200
	}),
};

const GetStyleForScore = (score, rangeValues) => {
	const style = new Style({
		//fill: new Fill({ color: ColorRange[Math.floor((score*total)/maxValue)]
		fill: new Fill({ 
			color: GetColorForScore(score, rangeValues)
		})
	})
	return style;
}

const GetCityCustomStyle = (fillColor, strokeWidth, strokeColor = "#707070") => {
	return new Style({
		image: new CircleStyle({
			radius: 10,
			fill: new Fill({ color: fillColor }),
			stroke: new Stroke({ color: strokeColor,  width: strokeWidth })
		})
	})
}

const GetColorForScore = (score, rangeValues) => {
	//let maxValue = rangeValues.max;
	//workaround when color variation values is less than total colors available
	const step = (rangeValues.max-rangeValues.min)/total;
	let color = (step === 0) ? 0 : Math.floor((score-rangeValues.min)/step)
	//console.log("Real color=", color)
	if (color>19) color = 19
	return ColorRange[color];
	//return ColorRange[Utils.DecimalAdjust("round", (score*total)/maxValue, -1)]
}

const GetStyleForSelectedNeighbohood = (score=0) => {
	const style = new Style({
		fill: new Fill({ color: 'transparent' }),
		stroke: new Stroke({
			color: "#707070",
			width: 6
		}),
	})
	return style;
};

// Load Indicators
const GetIndicators = () => {
	let livabilityIndicator = {};
	let mobilityIndicator = {};
	DataService.GetIndicators().then(e => {
		var data = e.data.sort((a, b) => {
			if (a.indicatorGroupName > b.indicatorGroupName) {
				return -1;
			}
		});
		livabilityIndicator = data.find(element => element.iid === "iid11");
		mobilityIndicator = data.find(element => element.iid === "iid5");
		let temp = orderIndicatorException(data, livabilityIndicator)
		temp = orderIndicatorException2(data, mobilityIndicator)
		return temp; 
	});
}

const orderIndicatorException = (array, element) => {
	let tempArray = array.filter((indicator) => indicator.iid !== "iid11")
	tempArray.splice(9, 0, element);
	return tempArray
}

const orderIndicatorException2 = (array, element) => {
	let tempArray = array.filter((indicator) => indicator.iid !== "iid5")
	tempArray.splice(4, 0, element);
	return tempArray
}

const TranslatedScoreForIndicator = (feature) => {
	let indicatorName;
	let selectElement = document.getElementById("indicator-select");
	var text= selectElement.options[selectElement.selectedIndex].text;
	indicatorName = text.replace(/[\s()]+|[^a-zA-Z0-9]/g, "");
	//indicatorName = indicatorName.charAt(0).toLowerCase() + indicatorName.slice(1) + "Score";
	//console.log("INDICATOR NAME: ",indicatorName)
	/*if (indicatorName === 'walkingCyclingScoreScore') indicatorName = 'walkabilityCyclabilityCombinedScore';
	else if (indicatorName === 'populationdensityScore') indicatorName = 'dwellingResidentialDensityScore';
	else if (indicatorName === 'streetConnectivityPermeabilityScore') indicatorName = 'streetConnectivityScore';
	else if (indicatorName === 'lifeQualityAssessmentScore') indicatorName = 'livabilityCombinedScore';
	else if (indicatorName === 'securitySecurityScore') indicatorName = 'safetyScore';
	else if (indicatorName === 'generalScoreScore') indicatorName = 'generalScore';	*/

	if (indicatorName === 'Retailqualitydiversity') indicatorName = 'retailFloorAreaRatioScore';
	else if (indicatorName === 'Intersectiondensity') indicatorName = 'streetConnectivityScore';
	else if (indicatorName === 'Populationdensity') indicatorName = 'dwellingResidentialDensityScore';
	else if (indicatorName === 'LandUseMix') indicatorName = 'landUseMixScore';
	else if (indicatorName === 'MobilityCombinedScore') indicatorName = 'walkabilityCyclabilityCombinedScore';
	else if (indicatorName === 'LivabilityCombinedScore') indicatorName = 'livabilityCombinedScore';
	else if (indicatorName === 'CrimedensitySecurity') indicatorName = 'safetyScore';
	//else if (indicatorName === 'TrafficSafety') indicatorName = 'trafficSafetyScore';
	else if (indicatorName === 'Accessibilitytohealthsportleisureandeducationandservicefacilities') 
		indicatorName = 'servicesScore';
	//else if (indicatorName === 'Greenspaceareaandnaturalenvironment') indicatorName = "naturalAreaScore";
	else if (indicatorName === 'ConcentrationsofparticulatematterPM25andNO2nitrogendioxide')
		indicatorName = 'environmentScore';
	else if (indicatorName === 'Totalscore') indicatorName = 'generalScore';

	// if (iid === 'iid1') indicatorName = 'retailFloorAreaRatioScore';
	// else if (iid === 'iid2') indicatorName = 'streetConnectivityScore';
	// else if (iid === 'iid3') indicatorName = 'dwellingResidentialDensityScore';
	// else if (iid === 'iid4') indicatorName = 'landUseMixScore';
	// else if (iid === 'iid5') indicatorName = 'walkabilityCyclabilityCombinedScore';
	// else if (iid === 'iid11') indicatorName = 'livabilityCombinedScore';
	// else if (iid === 'iid6') indicatorName = 'safetyScore';
	// else if (iid=== 'iid7') indicatorName = 'trafficSafetyScore';
	// else if (iid === 'iid8') iid = 'servicesScore';
	// else if (iid === 'iid9') indicatorName = "naturalAreaScore";
	// else if (iid === 'iid10') iid = 'environmentScore';
	// else if (iid === 'iid12') indicatorName = 'generalScore';

	//Return feature Score for current chosen Indicator
	return feature.getProperties().scores[indicatorName];
}

const LoadMapCities = (map) => {
	var vectorSource = new VectorSource({
		format: new GeoJSON(),
		features: GetCities(map),
		//loader: vectorLoader,
		projection: "EPSG:4326"
	});      

	var vectorLayer = new VectorLayer({
		source: vectorSource,
		style: MapUtils.Styles.normal,
		maxZoom: 8
	});
				
	map.addLayer(vectorLayer);      
	map.getView().fit(vectorSource.getExtent());

	map.getView().animate({
		zoom: map.getView().getZoom() - 0.1,
		duration: 300
	})
}

const GetCities = (map) => {
	var res = {};
	res.data = [];
	res.data[0] = MapData(JSON.parse(localStorage.cities));
	var features = new GeoJSON().readFeatures(res.data[0], 
		{featureProjection: map.getView().getProjection()}
	);
	return features;		
}

function CityData(id) {
	let cities = JSON.parse(localStorage.cities);
	if (cities === undefined) return;    
	let filteredCity = cities.filter(city => city.cityId === id)
	//console.log("Filtered City=", filteredCity)
	const cityFeature = new Feature({
		geometry: new Point([filteredCity[0].cityGeom.x, filteredCity[0].cityGeom.y])
	});     
	return cityFeature;
}

const SetScores = (score) => {
	return ({
	    /*retailFloorAreaRatioScore: score.iid1,
		landUseMixScore: score.iid2,
		streetConnectivityScore: score.iid3,
		dwellingResidentialDensityScore: score.iid4,	
		walkabilityCyclabilityCombinedScore: score.iid5,
		servicesScore: score.iid6,
		environmentScore: score.iid7,
		safetyScore: score.iid8,
		livabilityCombinedScore: score.iid9,
		generalScore: score.iid10*/
		iid1: score.iid1,
		iid2: score.iid2,
		iid3: score.iid3,
		iid4: score.iid4,
		iid5: score.iid5,
		iid6: score.iid6,
		iid7: score.iid7,
		iid8: score.iid8,
		iid9: score.iid9,
		iid10: score.iid10,
		iid11: score.iid11,
		iid12: score.iid12,		
	})
}

function MapData(cities) {
	if (cities === undefined) return;
	
	const features = [];
	cities.forEach(city => {
		features.push(
		{
			type: "Feature",
			id: city.cityId,
			geometry: { 
				type: "Point", 
				coordinates: [city.geom.x, city.geom.y]
			},
				properties: {
					score: "",
					cityId: city.cityId,
					city: city.originalCityName,
					name: city.originalCityName,
					country: city.countryName,
					scores: SetScores(city)				
			}
		})
	});

	var json = { type: "FeatureCollection", features: features};

	//Set Cities in Storage in JSON Format
	//console.log('Cities=', json.features);
	localStorage.setItem("cityFeatures", JSON.stringify(json));
	return json;
}

const sortCitiesByName = (features) => {
	let sorted = features.sort(function(a, b) {
	if (a.values_.city < b.values_.city)
		return -1;
	});		
	return sorted;
}

const sortCitiesByOriginalCityName = (features) => {
	let sorted = features.sort(function(a, b) {
	if (a.originalCityName < b.originalCityName)
		return -1;
	});		
	return sorted;
}

const sortFeaturesByScore = (features) => {
	let sorted = features.sort(function(a, b) {
	if (a.values_.score > b.values_.score)
		return -1;
	});
	return sorted;
}

function GetMaxMin(array, propName) {
	var minValue = 0;
	var maxValue = 0;
	if (array.length > 0) 
		minValue = array[0].values_[propName];
	for(var i=0; i<array.length; i++) {
		var item = array[i].values_;
		if(item[propName] > maxValue)
			maxValue = item[propName];
		if (item[propName] < minValue)
			minValue = item[propName];
	}

	return {max: maxValue, min: minValue};
}

const getFeaturePolygon = (coordinates) => {
	//console.log("coordinates: ", coordinates)
	var convertedCoordinates = [];
	coordinates.forEach(element => {
		convertedCoordinates.push([element.x, element.y])
	});

	const polygonFeature = new Feature({
			type: "Polygon",          
			geometry: new Polygon( [convertedCoordinates]).transform('EPSG:4326','EPSG:3857')
	});

	polygonFeature.setStyle(Styles.polygonStyle);
	return polygonFeature;
}

/*function vectorLoader() {
	console.log("Cities=", localStorage.cities.length)
	var res = {};
	res.data = [];
	res.data[0] = mapData(JSON.parse(localStorage.cities));      

	var geoJsonFormat = new GeoJSON();
	var features = geoJsonFormat.readFeatures(res.data[0], {
		featureProjection: map.getView().getProjection()
	});
	vectorSource.addFeatures(features);
}*/

const LoadTooltipOverlay = (map) => {
    //Overlay
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');

    /* Create an overlay to anchor the popup to the map. */
    const overlay = new Overlay({
		element: container,
		/*autoPan: { animation: { duration: 250,}, },*/
    });

    map.on('pointermove', function(evt) {
		evt.preventDefault();
			
		var feature_onHover;
      	feature_onHover = map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
			//console.log("Feature=", feature);
			return feature;
		});

		if (feature_onHover) {				
        	const coordinate = evt.coordinate;			
			if (feature_onHover.values_.type === 'Polygon') {
				content.innerHTML = '<span>'+feature_onHover.getProperties().name+"</span>";
				//if (feature_onHover.values_.score != "") 
				//	content.innerHTML += "<br/><span>Score: "+feature_onHover.values_.score+"</span>"
			} else
				content.innerHTML = '<span>'+feature_onHover.values_.city+",<br/>"+feature_onHover.values_.country+"</span>";
				overlay.setPosition(coordinate);
				container.style.display = 'block';
				map.addOverlay(overlay);
      	} else {
			container.style.display = 'none';
      	}
    });
}

const MapUtils = {
	ColorLevel, ColorRange, Styles, Strokes, SetScores, ColorScale, GetColorForScore, GetStyleForScore, GetCityCustomStyle,
	GetCities, MapData, LoadMapCities, CityData, GetIndicators, TranslatedScoreForIndicator, LoadTooltipOverlay,
	getFeaturePolygon, GetStyleForSelectedNeighbohood, sortCitiesByName, sortCitiesByOriginalCityName, sortFeaturesByScore, GetMaxMin
}
  
export default MapUtils;