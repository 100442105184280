import React, { useState } from "react";

import { FormControl, TextField, Grid, Typography } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";

import "../../css/databasePage.css";

import StyledTooltip from "../../utils/styledToolTip";

import { CAPEX, OPEX, TIME_FRAME } from "../../utils/tooltipConstants";


export default function Cost({newAdditionalInputs,setNewAdditionalInputs, userInputs, setUserInputs, advanceErrors, setAdvanceErrors}) {

  const [cost, setCost] = useState([
    { id: "1", label: "CAPEX", helperText: "USD $", 
    value: newAdditionalInputs.project_costs_estimates.capex},
    { id: "2", label: "OPEX", helperText: "USD $/year", 
    value: newAdditionalInputs.project_costs_estimates.opex},
  ]);

  const handleCostChange = (dataLabel,textValue) =>{
    textValue = validateEmptyFields(textValue)
    let temp = newAdditionalInputs;
    let tempCost = [...cost]
    switch (dataLabel) {
      case "CAPEX":
        temp.project_costs_estimates.capex=textValue
        tempCost[0].value = textValue;
        break;
      case "OPEX":
        temp.project_costs_estimates.opex=textValue
        tempCost[1].value = textValue;
        break;
      default:
        break;
    }
    setCost(tempCost)
    setNewAdditionalInputs(temp)
  }

  const validateEmptyFields = (textValue) =>{
    if(textValue=='' || textValue==undefined){
      return textValue = 0;
    }
    return parseFloat(textValue);
  }

  const validateErrors = (value, errorType) => {
    let tempAdvanceErrors = [...advanceErrors]
    if(value!="" && value!=0){
      let tempList = tempAdvanceErrors.filter(error=>error.errorType!=errorType);
      setAdvanceErrors(tempList)
    }
  }

  const handleAssessmentTime = (value) =>{
    validateErrors(value, "assessment_time_error")
    setUserInputs((userInputs) => ({ ...userInputs, assessmentTime: value }));
  }

  return (
    <TabPanel value="3/3" className="p-0 my-4">
      <Typography
        className="component_first_subtitle"
        variant="caption"
        gutterBottom
      >
       Fill in the information below to evaluate the economic benefits of your actions including Value of a Statistical Life (VSL), Value of a Life Year (VOLY), discounted implementation costs 
       and benefit to cost ratio. Assessment time is required to project the timeframe over which health benefits and operating costs will be considered in the estimation of economic benefits. 
       Cost data (CAPEX and OPEX) are optional input fields if users have data available and wish to see results for the discounted implementation costs and benefit to cost ratio. 
      </Typography>
      <h6 className="mt-5">Cost</h6>
      <Grid container spacing={3} className="mt-0">
      <Grid item xs={12} md={6}>
          <FormControl fullWidth>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}><StyledTooltip title={TIME_FRAME} arrow>
            <span className="icon"></span>
          </StyledTooltip></div>
            <TextField
              id="outlined-helperText"
              label="Assessment timeframe"
              //defaultValue="Assessment Time"
              helperText="# of Years"
              error={advanceErrors.findIndex(error=>error.errorType=="assessment_time_error")>=0}
              defaultValue={userInputs.assessmentTime}
              onChange={(e)=> handleAssessmentTime(e.target.value)}
              type="number"
            />
          </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
        {cost.map((data, index) => (
          <Grid item xs={12} md={6} key={index}>
            {data.id==="1" ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={CAPEX}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div> : 
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '5px' }}>
                  <StyledTooltip 
                    title={OPEX}
                    arrow
                  >
                    <span className="icon"></span>
                  </StyledTooltip>
                  </div>}
            <FormControl fullWidth>
              <TextField
                id="outlined-number"
                label={data.label}
                error={advanceErrors.findIndex(error=>error.errorType=="capex_opex_missing")>=0}
                helperText={data.helperText}
                value={data.value}
                type="number"
                variant="outlined"
                onChange={(e)=>handleCostChange(data.label,e.target.value)}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );
}
