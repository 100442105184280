import { Bar } from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/*ChartJS.register({
  id: 'custom_canvas_background_color',
  afterUpdate: (chart, args, options) => {
    const {ctx} = chart;
    //console.log("CTX=", ctx)
    //const currentCity = document.getElementById("select-city").innerHTML;
    //console.log("Datasets", chart.data.datasets[0]);
    //chart.data.datasets[0].borderColor[2] = 'rgba(54, 162, 235, 0.2)';
    
    chart.config.data.labels.forEach((label, i) => {
      if (i == 0) {
        console.log("Index=",i + " Label=", label);
        label = "xx";
      }
      //if (label === currentCity)
      //  console.log("Change label for ", label, " ctx=", ctx.dataset)
    });
    /*ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
});*/

// Chart variables

var chartTooltip = {
  borderWidth: 0.5,
  padding: 10
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: { display: true, grid: { display: false},
      title: { display: false, text: "City score" }, 
      ticks: {
        display: false
      }
    },
    x: { grid: { display: false, text: "City score" /*,  min: 0, max: 10*/ }, 
      ticks: {
        font: function(label) {
          if (label.tick.label.toString().charAt(0) === " ")
              return { fontColor: 'blue', size: 11, weight: 'bold' }
            else
              return {size: 10}          
        },
        color: function(label) {        
          if (label.tick.label.toString().charAt(0) === " ")
              return '#23bced'
        },
        /*callback: function(val, index) {                 
          let newVal = this.getLabelForValue(val);
          console.log("Chart Label=", newVal)
          if (index == 0) 
            return [newVal,"(highest)"];
          else if (index == this.max) 
            return [newVal,"(lowest)"];
          else return newVal
        }*/
      }
    },
    secondX: {
      position: 'bottom',
      labels: ['Highest', '', '', 'Lowest'],
      grid: {
        display: false,
        borderWidth: 0,
        tickLength: 0
      }
    }
  },  
  plugins: { 
    datalabels: null,
    legend: { display: false}, 
    title: { display: false, text: 'City Scores'},
    tooltip: {titleMarginBottom: 0, callbacks: {
      label: (value) => {
        //console.log("Chart Value=",value)
        return null        
      }
    }}
  },
  layout: {
    padding: { left: 0, right: 0}
  }
};


const BarChart = ({ chartData }) => {
  return (
    <div className="chart-container" style={{position: 'relative', height:'280px',width:'388px'}}>      
      <Bar
        data={chartData}
        options={options}
      />
    </div>
  );
};

export default BarChart;