import React, {useState, useEffect} from 'react';
import {Icon, Typography} from "@mui/material";
import GeoJSON from "ol/format/GeoJSON.js";
import OSM from 'ol/source/OSM';
import {Vector as VectorSource} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import { Map, View } from 'ol';
import {Button, FormControl, InputLabel, MenuItem, Select as SelectCity} from '@mui/material';
import DataService from '../../services/getData'
import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MapUtils from '../../utils/mapUtils';
import 'ol/ol.css';
import { Style } from 'ol/style';
import Utils from '../../utils/miscUtils';
import AuthService from '../../services/auth.service';

export default function LocationStep({setCurrencyList, setSelectedCurrency, setNewAdditionalInputs, setYLDTable, setMortalityTable, 
    setPopulationTable, setSingleNationalCompositionTable, setCitySpecificCompositionTable, setActionList, setListOfActions, 
    setHasResult, setSimulationResults, cityDetails, setCityDetails, setListOfProjects, setIncludePreProject, setIsSumulationOption}) {
    var [map, setMap] = useState();
    const [selectedCity, setSelectedCity] = useState("");
    const [cities, setCities] = useState([]); // ""  //MapUtils.sortCitiesByOriginalCityName(JSON.parse(localStorage.cities));
    const [openDialog, setOpenDialog] = React.useState(false);

    // Close MuiAlert
    const handleClose = (event, reason) => {    
        setOpenDialog(false);
    };

    //Just in case 
    const ResetStyleForFeatures = (features, style) => {
        features.map(feature => {
            //fx = contextMap.getAllLayers()[1].getSource().getFeatures()[x];
            feature.setStyle(style); // styles.cityStyle);
            features._state = null;
        });
    }

    const handleChange = (event) => {
        ResetStyleForFeatures(map.getAllLayers()[1].getSource().getFeatures(), MapUtils.Styles.cityStyle);
        if (event.target.value == "") {
            setSelectedCity("");
            if (map.getAllLayers()[2] != undefined) map.removeLayer(map.getAllLayers()[2]);
            if (map.getAllLayers()[2] != undefined) map.removeLayer(map.getAllLayers()[2]);
            const vectorSource = map.getLayers().array_[1].getSource();
            map.getView().fit(vectorSource.getExtent());
            map.getView().setZoom(map.getView().getZoom() - 0.2);           
            return;
        }
        var id = event.target == undefined ? event: event.target.value;
        map.getAllLayers()[1].getSource().getFeatureById(id).setStyle(MapUtils.Styles.selectedCity);
        setSelectedCity(id);        
        localStorage.setItem("selectedCity", id);
        DataService.GetCity(id).then(city => {
            //console.log("City=",city.data.originalCityName);
            drawPolygonOnMap(city);
            //getCurrency(city.data.countryName)
            addCityGrid(city.data.cityId);
            setCityDetails(city.data);
        });
    }

    

    // const getCurrency = async (countryName) =>{
    //       const dataTemp = await DataService.getCurrency()
    //       setCurrencyList(dataTemp.data)
    //       let currency = dataTemp.data.find(currency => currency.nation == countryName)
    //       if(currency==undefined){
    //         currency = dataTemp.data.find(currency => currency.nation == 'European Union')
    //         console.log(currency)
    //       }
    //       setSelectedCurrency(currency)
    //   }

    const getAdditionInputs = async (regionName, countryName) => {
        const dataTemp = await DataService.getAdditionalInputs(regionName, countryName)
        .catch(error => {
            if (error.response.status == 401) {
                AuthService.logout();
                window.location.reload(true);
              }
        });
        let currency = dataTemp.data.currency_list.find(currency => currency.nation == 'United States of America')
        //   if(currency==undefined){
        //     currency = dataTemp.data.currency_list.find(currency => currency.nation == 'United States Dollar')
        //   }else if(dataTemp.data.currency_list.filter(cur => cur.currency_type == currency.currency_type).length>1){
        //     let tempIndex = dataTemp.data.currency_list.indexOf(cur => cur.currency_type == currency.currency_type && cur.id!=currency.id);
        //     dataTemp.data.currency_list.splice(tempIndex,1)
        //   }
        setCurrencyList(dataTemp.data.currency_list)
        dataTemp.data = {...dataTemp.data, selectedCurrency: currency.id}
        setSelectedCurrency(currency);
        setNewAdditionalInputs(dataTemp.data);
        cleanFields();
        getAllProjects();
        getYLDTable(countryName, dataTemp.data)
        getAllActions();
        setListOfActions([]);
        setHasResult(false);
        setIncludePreProject(true)
        setSimulationResults({});
    }

    const countryNameCorrector = (countryName) => {
        switch (countryName) {
          case "the Netherlands":
            return "Netherlands";
          case "New Zeland":
            return "New Zealand";
          case "the USA":
            return "United States of America";
          case "Tanzania":
            return "United Republic of Tanzania";
          case "Vietnam":
            return "Viet Nam";
          case "Russia":
            return "Russian Federation";
          case "the Philippines":
            return "Philippines";
          case "South Korea":
            return "Republic of Korea";
          case "United Kingdom":
            return "United Kingdom of Great Britain and Northern Ireland";
          default:
            return countryName;
        }
      };

    const cleanFields = () => {
        setYLDTable("")
        setMortalityTable("")
        setPopulationTable("")
        setSingleNationalCompositionTable("")
        setCitySpecificCompositionTable("")
        setIsSumulationOption("")
    }

    const getAllProjects = async () => {
        const dataTemp = await DataService.GetUserProjects();
        setListOfProjects(dataTemp.data.reverse((a, b) => a.processNumber - b.processNumber));
    }

    const getAllActions = async () =>{
        const dataTemp2 = await DataService.GetActions();
        setActionList(dataTemp2.data)
    }

    const getYLDTable = async (countryName, inputs)=>{
        const dataTemp = await DataService.getYLD(countryName);
        console.log("DATA: ", dataTemp.data)
        buildYLDTable(dataTemp.data.yld);
        buildMortalityTable(dataTemp.data.mortality_by_age);
        buildPopulationTable(dataTemp.data.population_by_age_group, inputs);
        buildCityCompositionTable();
    }

    const buildYLDTable = (data) => {
        let temp = [];
        let i;
        for (i=0; i<96; i++) 
        {
          temp.push({Age: i, mBreastCancer: 0, fBreastCancer: 0, mColonAndRectumCancer: 0, fColonAndRectumCancer: 0,
        mIschemicHeartDisease:0, fIschemicHeartDisease:0, mIschemicStroke:0, fIschemicStroke:0, mAlzheimerDiseaseAndOtherDementias:0,
        fAlzheimerDiseaseAndOtherDementias:0, mDepressiveDisorders:0, fDepressiveDisorders:0, mDiabetesMellitus:0, fDiabetesMellitus:0})
        };

        data.map(data =>
            {if(data.sex_id == "Male")
            {
              temp[data.age].mBreastCancer = data.breast_cancer;
              temp[data.age].mColonAndRectumCancer = data.colon_and_rectum_cancer;
              temp[data.age].mIschemicHeartDisease = data.ischemic_heart_disease;
              temp[data.age].mIschemicStroke = data.ischemic_stroke;
              temp[data.age].mAlzheimerDiseaseAndOtherDementias = data.alzheimer_disease_and_other_dementias;
              temp[data.age].mDepressiveDisorders = data.depressive_disorders;
              temp[data.age].mDiabetesMellitus = data.diabetes_mellitus;
            } else {
                temp[data.age].fBreastCancer = data.breast_cancer;
                temp[data.age].fColonAndRectumCancer = data.colon_and_rectum_cancer;
                temp[data.age].fIschemicHeartDisease = data.ischemic_heart_disease;
                temp[data.age].fIschemicStroke = data.ischemic_stroke;
                temp[data.age].fAlzheimerDiseaseAndOtherDementias = data.alzheimer_disease_and_other_dementias;
                temp[data.age].fDepressiveDisorders = data.depressive_disorders;
                temp[data.age].fDiabetesMellitus = data.diabetes_mellitus;
            }})
            setYLDTable(temp);
        }
    
        const buildMortalityTable = (data) => {
            let temp = [];
            let i;
            for (i=0; i<96; i++) 
            {
              temp.push({ageM: i, ageF: i, mSexId:"", fSexId:"", mTotal: 0, fTotal: 0})
            };
            
            data.map(data =>
                {if(data.sex_id == "Male")
                {
                  temp[data.age].mSexId = data.sex_id;
                  temp[data.age].mTotal = data.total;
                } else {
                    temp[data.age].fSexId = data.sex_id;
                    temp[data.age].fTotal = data.total;
                }})
                setMortalityTable(temp)
            }

        const buildPopulationTable = (data, inputs) => {
            let temp = [];
            let i;
            for (i=0; i<96; i++) 
            {
                temp.push({ageM: i, ageF: i, mSexId:"", fSexId:"", mProxy: 0, fProxy: 0})
            };
        
            data.map(data =>
                {if(data.sex_id == "Male")
                {
                    temp[data.age].mSexId = data.sex_id;
                    temp[data.age].mProxy = data.population_by_age;
                } else {
                    temp[data.age].fSexId = data.sex_id;
                    temp[data.age].fProxy = data.population_by_age;
                }})
                setPopulationTable(temp)
                buildNationalCompositionTable(temp, inputs)
            }

        const buildNationalCompositionTable = (table, inputs) => {
            let tempTable = [];
            tempTable = Utils.CreateNationalCompositionTable(table,inputs.age_range_of_cyclists.min_age, inputs.age_range_of_cyclists.max_age)
            setSingleNationalCompositionTable(tempTable)
        }

        const buildCityCompositionTable = () => {
            let temp = [];
            let i;
            for (i=0; i<96; i++) 
            {
                temp.push({ageM: i, ageF: i, fSexId:"Female", mSexId:"Male", mValue: 0, fValue: 0})
            };
            setCitySpecificCompositionTable(temp)
        }
    

    useEffect(() => {
        console.log('Location set validationsFalse');
        localStorage.setItem("locationIsValid", false);
    }, []);

    const drawPolygonOnMap = (city, locationMap) => {
        if (locationMap != undefined) {
            map = locationMap;
        }

        const coordinates = city.data.geom
        var convertedCoordinates = [];
        coordinates.forEach(element => {
            convertedCoordinates.push([element.x, element.y])
        });

        const polygonFeature = new Feature({
            type: "Polygon",          
            geometry: new Polygon( [convertedCoordinates]).transform('EPSG:4326','EPSG:3857'),
            name: city.data.originalCityName
        });

        polygonFeature.setId(city.data.cityId);
    
        let source = new VectorSource({
          features: [polygonFeature]
          //features: new GeoJSON().readFeatures(polygonFeature),
        });
    
        var layer = new VectorLayer({
          source: source,
          style: MapUtils.Styles.polygonStyle // styles.polygonStyle
        });
        layer.setZIndex(1);

        // Remove Previous Layer with polygon
        if (map.getLayers().array_[3] != undefined) map.removeLayer(map.getLayers().array_[3]);
        if (map.getLayers().array_[2] != undefined) map.removeLayer(map.getLayers().array_[2]);        
        map.addLayer(layer);
        //map.zoomToExtent(polygonFeature.geometry.getBounds());

        const extent = source.getExtent();
        map.getView().fit(extent);
        //AddMapClick(map);

        localStorage.setItem("locationIsValid", false);
        //setSelectedCity(city.id);
    }

    const addCityGrid = (id) => {
		DataService.GetCityGrid(id).then(cityGrid => {
			let gridFeature;
			let gridFeatures = [];
			let x = 0;
			cityGrid.data.forEach(grid => {             
				gridFeature = MapUtils.getFeaturePolygon(grid.geom); //.neighborhoodGeom.shell.points.coordinates);
				gridFeature.setId("largeGrid-" + x++); //(grid.neighborhoodId);
				gridFeature.set("neighborhoodGridId", grid.neighborhoodGridId);
				gridFeature.set("neighborhoodId", grid.i);
				gridFeature.set("name", grid.name);
				//gridFeature.set("scores", MapUtils.SetScores(grid));
				gridFeature.setStyle(MapUtils.Styles.neighborhoodForLocationStep)
				gridFeatures.push(gridFeature)
			})

			const gridLayer = new VectorLayer({
				source: new VectorSource({features: gridFeatures}),
				minZoom: 8,
				opacity: 0.7
			});
			if (map.getAllLayers()[3] != undefined) map.removeLayer(map.getAllLayers()[3]);
				gridLayer.setZIndex(200);
			map.addLayer(gridLayer);
		});
	}

    function LoadCities(locationMap) {
        //const json = JSON.parse(localStorage.getItem("cityFeatures"))
        var res = {};
        res.data = [];
        res.data[0] = MapUtils.MapData(JSON.parse(localStorage.cities));
        var geoJsonFormat = new GeoJSON();
        var features = geoJsonFormat.readFeatures(res.data[0], 
            {featureProjection: locationMap.getView().getProjection()}
        );

        var vectorSource = new VectorSource({
            format: new GeoJSON(),
            features: features,
            projection: "EPSG:4326"
        });      

        var vectorLayer = new VectorLayer({
            source: vectorSource,
            style: MapUtils.Styles.cityStyle
        });

        locationMap.addLayer(vectorLayer);
        locationMap.getView().fit(vectorSource.getExtent());
    }

    useEffect( () => {;
        const locationMap = new Map({
            target: "mapLocation",
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                //vectorLayer
            ],
            view: new View({
                center: [0, 0], //fromLonLat([12, 44]),
                zoom: 0
            })
        });
        if (localStorage.cities !== undefined) {
            setCities(MapUtils.sortCitiesByOriginalCityName(JSON.parse(localStorage.cities)));
            LoadCities(locationMap);
        } else {
            DataService.GetCities().then(e => {
                var data = e.sort((a, b) => {
                    if (a.originalCityName < b.originalCityName) {
                    return -1;
                    }
                });
                setCities(data);
                LoadCities(locationMap);
            })
        }
        AddMapClick(locationMap);
        setMap(locationMap);
    },[]);

    useEffect(() => {
        if (cityDetails.regionName === undefined || cityDetails.countryName === undefined){
        } else {
            getAdditionInputs(cityDetails.regionName,cityDetails.countryName)
        }
    }, [cityDetails]);
    
    const AddMapClick = (locationMap) => {
        //useEffect( () => {;
        locationMap.on("click", function(event) {
            console.log("map Click")            

            if (locationMap.getAllLayers().length > 2)
                ResetStyleForFeatures(locationMap.getAllLayers()[3].getSource().getFeatures(), MapUtils.Styles.neighborhoodForLocationStep);
            
            localStorage.setItem("locationIsValid", false);
            localStorage.setItem("clickedCoordinates", "");           
            const layer = locationMap.getAllLayers()[1];
            let hasSelectedFeature = false;

            this.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                //console.log("Feature=", feature);
                if (hasSelectedFeature) return;
                if (feature.values_.type != "Polygon") {
                    const previousCityId = document.getElementById("simple-city-select").nextSibling.value;
                    console.log("Previous City ID =",previousCityId + " new City Id=", feature.id_);
                    if (previousCityId != "")
                        locationMap.getAllLayers()[1].getSource().getFeatureById(previousCityId).setStyle(MapUtils.Styles.cityStyle);
                    setSelectedCity(feature.id_);
                    localStorage.setItem("selectedCity", feature.id_);
                    hasSelectedFeature = true;
                    layer.getSource().getFeatureById(feature.id_).setStyle(MapUtils.Styles.selectedCity);            
                    DataService.GetCity(feature.id_).then(city => {
                        console.log("City=",city.data.originalCityName);
                        drawPolygonOnMap(city, locationMap);
                        addCityGrid(city.data.cityId);
                        setCityDetails(city.data);
                    });
                } else {
                    if (feature.id_.indexOf("Grid")>0) {
                        hasSelectedFeature = true;
                        console.log("Clicked on neighborhood Name="+ feature.values_.name + "\nNeighborhoodId=", feature.values_.neighborhoodId);
                        feature.setStyle(MapUtils.Styles.selectedNeighborhoodForLocationStep);                        
                        localStorage.setItem("locationIsValid", true);            
                        localStorage.setItem("ProjectIsValid", true);
                        localStorage.setItem("clickedCoordinates", JSON.stringify(event.coordinate));
                        localStorage.setItem("neighborhoodGridId", feature.values_.neighborhoodGridId);
                        localStorage.setItem("neighborhoodId", feature.values_.neighborhoodId);
                    }
                }
            });
        });
            
        //There is no use to continue because another city has been selected
        //if (hasSelectedCity) return;
    }    

    return (
			<div> 
				<div className="row">
					<div className="col p-0">
                    <Typography className="component_first_subtitle" variant="caption" gutterBottom>
                    <span>On this page, you need to define the project location. The tool is designed to assess the impact of a project on a single grid within the city. 
                        Each grid represents a 1km<sup>2</sup> area</span>.
                    </Typography>
					</div>

					<Typography className="c-40__Title-aboutOne" variant="h6">
						Location
					</Typography>

					<Typography className="c-40__Paragraph-about" variant="body2">
                    Use the drop-down list to select a city, or zoom in on the interactive map to the city and neighbourhood. 
                    You will then need to select the urban area for the project by clicking on a grid within the map.
					</Typography>
				</div>

				<div style={{height:'50vh',width:'100%'}} id="mapLocation" className="map-container-location">
						<FormControl className="select-location">                
							<SelectCity displayEmpty inputProps={{ 'aria-label': 'Without label' }}
								labelId="select-location"
								id="simple-city-select"
								value={selectedCity}             
								onChange={handleChange}                
								defaultValue="">
								<MenuItem key={""} value="">Select a city</MenuItem> 
								{cities.map((city, index) => <MenuItem key={index} value={city.cityId}>{city.originalCityName}</MenuItem>)}
							</SelectCity>
						</FormControl>
				</div>
					
				<Snackbar open={openDialog} autoHideDuration={1500} onClose={handleClose}>
					<MuiAlert  severity="warning">Please put your marker inside de City limits</MuiAlert >
				</Snackbar>
			</div>
    );

}